import React from 'react';
import Table from '../../components/Table/Table';
import Row from '../../components/Table/Row/Row';
import Cell from '../../components/Table/Row/Cell/Cell';
import withStyles from "react-jss"

const styles = {
    root:{
        display: "grid",
        gridGap: "5px",
    }
};

const Metadata = ({classes, data})=> {
    if (!data){
        throw Error("no data parameter set")
    }
    return (
        <div className={classes.root}>
            <Table>
                {Object.keys(data).sort((a, b) => {
                    return a.length - b.length
                }).map((k) =>
                        <Row key={k}>
                            <Cell header>{k}</Cell>
                            <Cell>{data[k] !== null && data[k] !== undefined && typeof data[k] === "object" && !Array.isArray(data[k]) ? (<Metadata classes={classes} data={data[k]}/>) : JSON.stringify(data[k])}</Cell>
                        </Row>
                )}
            </Table>
        </div>
    )
};

export default withStyles(styles)(Metadata);