import React from 'react';
import ProductImage from "./ProductImage";
import withStyles from 'react-jss'
import {Link} from "react-router-dom";
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import AuthContext,{Admin} from "../../services/AuthContext/AuthContext";

const styles = {
    root: {
        display: "grid",
        gridTemplateColumns: "160px auto",
    },
    topBar: {
        gridColumn: "1/3",
        display: "grid",
        gridTemplateColumns: "auto 100px ",
    },
    productValuesView: {
        display: "grid",
        gridTemplateColumns: "160px auto",
    },
    title: {
        fontWeight: "600",
    },
    buttons: {
        textAlign: "right",
        "& > *": {
            marginLeft: 5,
        }
    }
};

const Product = ({classes,product}) => {
    if (!product) return "";

    return <div className={classes.root}>
        <div className={classes.topBar}>
            {product.deleted ?
                <h1 style={{textDecoration: "line-through"}}>{product.name} - this asset is deleted</h1>
                : <h1>{product.name}</h1>
            }
            <div className={classes.buttons}>
                <Link to={"/products/" + product.id + "/edit"} className={classes.add}>
                    <Button circle title="edit">
                        <Icon>edit</Icon>
                    </Button>
                </Link>
                <AuthContext.Consumer>{currentUser => currentUser.hasRole(Admin) &&
                    <>
                        {!product.deleted ?
                            <Link to={"/products/" + product.id + "/delete"} className={classes.add}>
                                <Button circle title="delete">
                                    <Icon>delete</Icon>
                                </Button>
                            </Link> :
                            <Link to={"/products/" + product.id + "/restore"} className={classes.add}>
                                <Button circle title="Restore">
                                    <Icon>restore</Icon>
                                </Button>
                            </Link>
                        }
                    </>
                }</AuthContext.Consumer>
            </div>
        </div>
        <div style={{justifySelf: "center"}}>
            {<ProductImage
                fileId={product.image}
                editable={true}
                productId={product.id} />}
        </div>
        <div className={classes.productValuesView}>
            <div className={classes.title}>Model: </div><div>{product.name}</div>
            <div className={classes.title}>Categories: </div><div>{product.category}</div>
            <div className={classes.title}>Price: </div><div>{product.price}</div>
            <div className={classes.title}>Weight:</div><div> {product.weight + " "}kg</div>
            <div className={classes.title}>Dimensions: </div><div>{product.dimensions}</div>
            <div className={classes.title}>Producer:</div><div> {product.producer}</div>
            <div className={classes.title}>Calibration interval:</div><div> {product.calibrationInterval !== "" ? product.calibrationInterval + " months" : "N/A"}</div>
            {product.container && <>
                <div className={classes.title}>Is container:</div><div>YES</div> 
            </>}
            <div className={classes.title}>Description:</div><div> {product.description}</div>
        </div>
    </div>
};

export default withStyles(styles)(Product)