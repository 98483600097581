import React, {useState} from 'react';
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import Api from '../../services/Api/Api'
import withStyles from "react-jss"
import Form from "../../components/Form/Form";
import DelayedRedirect from "../DelayedRedirect/DelayedRedirect";
import {useErrorHandler} from "../../services/Utils/useErrorHandler";
import {useLoading} from "../../services/LoadingContext/LoadingContext";

const styles = {
    root:{
        margin: 'auto',
        maxWidth: 500,
        maxHeight: 500
    }
};

const CreateAccount = ({classes}) => {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [redirect, setRedirect] = useState(false);
    const errorHandler = useErrorHandler();

    const handleSubmit = useLoading(async (e) => {
        e.preventDefault();
        try{
            await Api.CreateUser({email,password,name});
            setRedirect(true);
        }catch (e) {
            await errorHandler(e)
        }
    });

    if (redirect){
        return <DelayedRedirect to={"/"} duration={10000}>{millisLeft =>
            <>
                <h1>Thank you for registering</h1>
                <p>Please check you e-mail in order to activate your account.</p>
                <p>Redirecting you to login page in {millisLeft / 1000} second{millisLeft > 1000 && "s"}.</p>
            </>
        }</DelayedRedirect>;
    }

    return (
        <div className={classes.root}>
            <h1>Create account.</h1>
            <Form onSubmit={handleSubmit}>
                <Input required title="Name" onChange={e => setName(e.target.value)}/>
                <Input required title="Email" onChange={e => setEmail(e.target.value)}/>
                <Input required title="Password" type="password" onChange={e => setPassword(e.target.value)}/>
                <Button type="submit">Create account</Button>
            </Form>
        </div>
    );
};

export default withStyles(styles)(CreateAccount);
