import React from 'react';
import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import withStyles from "react-jss";

const styles = {
    root: {

    },
    buttons: {
        display: "grid",
        gridTemplateColumns: "auto auto",
        justifyContent: "space-between",
    }
};

const Confirmation = ({
    classes,
    open,
    ok = "Ok",
    cancel = "Cancel",
    onConfirm,
    onCancel,
    children
}) => {

    const handleOk = ()=>{
        onConfirm && onConfirm()
    };

    const handleCancel = ()=>{
        onCancel && onCancel()
    };

    return (
        <Modal open={open} onClose={handleCancel} className={classes.root}>
            {children ? children : <p>Are you sure?</p>}
            <div className={classes.buttons}>
                <Button onClick={handleOk}>{ok}</Button>
                <Button onClick={handleCancel}>{cancel}</Button>
            </div>
        </Modal>
    );
};

export default withStyles(styles)(Confirmation);
