import React,{useState} from 'react';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import StorageSelect from "../Select/StorageSelect";
import {DateToYMD} from "../../services/Utils/Date";
import Form from "../../components/Form/Form";
import withStyles from "react-jss";
import Api from '../../services/Api/Api';
import ProductSelect from "../Select/ProductSelect";
import {ProductsCollection, useResource} from "../../services/Firestore/Resources";
import Checkbox from "../../components/Checkbox/Checkbox";
import {useErrorHandler} from "../../services/Utils/useErrorHandler";
import {useLoading} from "../../services/LoadingContext/LoadingContext";

const styles = {
    root: {
        display: "grid",
        gridTemplateColumns: "auto auto"
    },
    fullWidth: {
        gridColumn: "1/3"
    }
};

const AssetAddForm = ({classes,onSubmit,productId,storageId}) => {
    const [selectedStorageId,setSelectedStorageId] = useState(storageId);
    const [selectedProductId,setSelectedProductId] = useState(productId);
    const [serialNumber,setSerialNumber] = useState(null);
    const [tagNumber,setTagNumber] = useState(null);
    const [rental,setRental] = useState(false);
    const [owner,setOwner] = useState(null);
    const [calibrationDate,setCalibrationDate] = useState(null);
    const errorHandler = useErrorHandler();
    const products = useResource(ProductsCollection);
    let calibrationInterval = 0;
    if(selectedProductId && products){
        calibrationInterval = products.get(selectedProductId).calibrationInterval;
    }

    const handleSubmit = useLoading(async (e) => {
        e.preventDefault();
        try {
            const data = await Api.CreateAsset({
                productId: selectedProductId,
                storageId: selectedStorageId,
                serialNumber,
                tagNumber,
                rental,
                owner,
                calibrationDate: calibrationDate && calibrationDate.toJSON(),
            });

            if(onSubmit){
                onSubmit(data)
            }
        }catch (e) {
            await errorHandler(e)
        }

    });

    return (
        <Form onSubmit={handleSubmit} className={classes.root}>
            {!productId && <ProductSelect title="Select model" value={selectedProductId} required onChange={({value}) => setSelectedProductId(value)} />}
            <StorageSelect title="Current location" value={selectedStorageId} required onChange={({value}) => setSelectedStorageId(value)} />
            <Input title="Serial number" required onChange={e => setSerialNumber(e.target.value)} />
            <Input title="Tag number" onChange={e => setTagNumber(e.target.value)} />
            <Input title="Owner (Deepocean, DO Toolpool, External)" onChange={e => setOwner(e.target.value)} />
            {calibrationInterval > 0 && 
                <Input title="Last calibration date" type="date" defaultValue={DateToYMD(calibrationDate)} onChange={e => setCalibrationDate(new Date(e.target.value))} />
            }
            <Checkbox title="Is it a rental from an external?" checked={rental} onChange={e => setRental(e.target.checked)} />
            <Button className={classes.fullWidth} style={{justifySelf: "center"}} type="submit">Add</Button>
        </Form>

    );
};

export default withStyles(styles)(AssetAddForm)