import {Link} from "react-router-dom";
import React from "react";
import {StorageTitle} from "../../Title/StorageTitle/StorageTitle";

export const StorageLink = ({id,...other}) => {
    if(!id){
        return "In transit"
    }
    
    return <Link to={"../storages/" + id}>
    <StorageTitle {...{id,...other}}/>
</Link>
};