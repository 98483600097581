export default class Observable {
    observers = [];
    subscribe(f){
        this.observers.push(f);
        return ()=>this.unsubscribe(f)
    }

    subscribeOnce(f){
        let unsubscribe;
        unsubscribe = this.subscribe((a)=>{
            f(a);
            unsubscribe();
        });
        return unsubscribe;
    }

    unsubscribe(f){
        this.observers = this.observers.filter(subscriber => subscriber !== f);
    }

    notify(a) {
        this.observers.forEach(observer => observer(a));
    }
}