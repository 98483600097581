import React,{useState} from 'react';
import withStyles from 'react-jss'
import Modal from "../Modal/Modal";

const styles = {
    root: {

    },
};

const Form = ({classes, className, children, onSubmit,...other})=>{
    const [validationModalOpen, setValidationModalOpen] = useState(false);

    const handleCloseModal = ()=>{
        setValidationModalOpen(false)
    };

    const handleSubmit =(e)=>{
        e.preventDefault();

        const valid = e.target.checkValidity();
        setValidationModalOpen(!valid);

        if(!valid){
            return;
        }
        if(onSubmit){
            onSubmit(e);
        }
    };

    return (
        <React.Fragment>
            <form className={[classes.root, className].join(" ")} autoComplete={"off"} onSubmit={handleSubmit} noValidate={true} {...other}>
                {children}
            </form>
            <Modal open={validationModalOpen} onClose={handleCloseModal}>
                <p>Form contains errors, please review and correct any errors.</p>
            </Modal>
        </React.Fragment>
    );
};

export default withStyles(styles)(Form);
