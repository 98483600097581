import React from 'react';
import withStyles from "react-jss"
import Table from '../../components/Table/Table';
import Row from '../../components/Table/Row/Row';
import Cell from '../../components/Table/Row/Cell/Cell';
import {Link, withRouter} from "react-router-dom";
import Entity from '../../components/Entity/Entity';
import {queryObject} from "../../services/Utils/Query";

const styles = {
    root: {
        display: "grid",
        gridGap: "5px",
    },
};

const OfficeList = ({classes, match, offices, filter}) => {
    return (
        <div className={classes.root}>
            <Table>
                <Row>
                    <Cell header>Name</Cell>
                    <Cell header>Address</Cell>
                </Row>
                {offices && Object.values(offices).length > 0
                    ? Object.values(queryObject(offices, filter)).map((row) => (
                        <Row key={row.id}>
                            <Cell>
                                <Link to={match.url + "/" + row.id}>
                                    <Entity title={row.name} deleted={row.deleted} />
                                </Link>
                            </Cell>
                            <Cell>
                                <Entity title={row.address} deleted={row.deleted} />
                            </Cell>
                        </Row>
                    ))
                    : <Row><Cell>No rows</Cell></Row>
                }
            </Table>
        </div>
    );
};

export default withRouter(withStyles(styles)(OfficeList));