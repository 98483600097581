import React from 'react';
import Api from "../../services/Api/Api";
import Modal from "../../components/Modal/Modal";
import withStyles from "react-jss";
import {Route, withRouter} from "react-router-dom";
import DepartmentEditForm from "../../views/Department/DepartmentEditForm";
import Department from "../../views/Department/Department";
import {Delete,Restore} from "../../components/Crud/Crud";
import {useErrorHandler} from "../../services/Utils/useErrorHandler";
import {useLoading} from "../../services/LoadingContext/LoadingContext";

const styles = {
    topBar: {
        display: "grid",
        gridTemplateColumns: "auto auto",
    },
    buttons: {
        justifySelf: 'self-end',
        margin: 10,
    },
};

const DepartmentsPage = ({match, history, department = {}}) => {

    const errorHandler = useErrorHandler();

    const returnToDepartment = () => {
        history.push(match.url);
    };

    const handleDelete = useLoading(async (data) => {
        try {
            await Api.DeleteDepartment(data);
            history.push("/departments/" + data.id);
        } catch (e) {
            await errorHandler(e)
        }
    });

    const handleRestore = useLoading(async (data) => {
        try {
            await Api.RestoreDepartment(data);
            history.push("/departments/" + data.id);
        } catch (e) {
            await errorHandler(e)
        }
    });

    return (
        <>
            <Department department={department} />
            <Route path="/departments/:id/edit" render={() =>
                <Modal open={true} onClose={returnToDepartment}>
                    <DepartmentEditForm department={department} onUpdate={returnToDepartment} />
                </Modal>
            } />
            <Route path="/departments/:id/delete" render={() =>
                <Modal open={true} onClose={returnToDepartment}>
                    <Delete data={department} onDelete={handleDelete} onAbort={returnToDepartment} />
                </Modal>
            } />
            <Route path="/departments/:id/restore" render={() =>
                <Modal open={true} onClose={returnToDepartment}>
                    <Restore data={department} onRestore={handleRestore} onAbort={returnToDepartment} />
                </Modal>
            } />
            
        </>
    );
};

export default withRouter(withStyles(styles)(DepartmentsPage));
