export const StorageTitle = ({id,name,lineageNames,full = false}) => {
    if(lineageNames && lineageNames.length > 1) {
        if (!full){
            if(lineageNames.length >2){
                return lineageNames[0] + " /... " + lineageNames[lineageNames.length-1]
            }else{
                return lineageNames[0] + " / " + lineageNames[lineageNames.length-1]
            }
        }
        return lineageNames.reduce((a, b) => {
            return a + " / " + b
        })
    }

    if(name){
        return name
    }

    if (id){
        return id
    }

    return ""
};