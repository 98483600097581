import React from "react";
import {useFlash} from "../../components/Flash/Flash";

export const useErrorHandler = ()=>{
    const flash = useFlash();

    return async (e)=>{
        if (e instanceof Response){
            let fn;
            const text = await e.text();
            if (e.status >= 500) {
                fn = flash.error.bind(flash);
            }else if(e.status >= 400){
                fn = flash.alert.bind(flash)
            } else {
                fn = flash.notification.bind(flash);
            }
            fn(<FriendlyErrorMessage status={e.status} message={text} response={e}/>);
        }
    }
};

const FriendlyErrorMessage = ({status,message,response})=>{
    switch (status) {
        case 400:
            return <>
                <p>The backend system returned a 400 Bad request response, which usually means that the request has invalid data.</p>
                <p>The message was: '{message}'</p>
            </>;
        case 401:
            return <>
                <p>The backend system returned a 401 Unauthorized response, which generally means that you have either provided incorrect credentials or your credentials have been invalidated.</p>
                <p>Try logging in again and make sure to use the correct email and password.</p>
            </>;
        case 503:
            return <>
                <p>The backend system returned a 503 Service Unavailable response.</p>
                <p>This usually means that the service is undergoing maintenance, please try again later.</p>
            </>;
        case 500:
        default:
            return <>
                <p>The backend system returned an unknown runtime error.</p>
                <pre style={{fontWeight: "normal"}}>
                    {JSON.stringify({status,message,response},null,2)}
                </pre>
            </>
    }
};