import React, {useState, useEffect, useCallback} from "react"
import * as firebase from "firebase";
import Observable from "../Observable/Observable";
import {setLoading} from "../LoadingContext/LoadingContext";
import {User} from "../AuthContext/AuthContext";
import {DepartmentType, ExternalType, OfficeType, ShipType} from "../../constants";

export class FirestoreQuery extends Observable {
    static cache = [];

    data;
    dataReady;
    query;
    queryUnsubscribe;
    teardownTimeout;

    constructor(query) {
        super();
        if (!query instanceof firebase.firestore.Query) {
            throw Error("invalid firestore query");
        }
        this.query = query;
        if(!FirestoreQuery.cache.find(v=>v.query.constructor === query.constructor && v.query.isEqual(query))){
            FirestoreQuery.cache.push(this);
        }
    }

    static get(query) {
        if (!query instanceof firebase.firestore.Query) {
            throw Error("invalid firestore query");
        }

        let fq = FirestoreQuery.cache.find(v=>v.query.constructor === query.constructor && v.query.isEqual(query));
        if(!fq){
            fq = new FirestoreQuery(query);
        }
        return fq
    }

    init(){
        if (!this.queryUnsubscribe) {
            this.queryUnsubscribe = this.query.onSnapshot(snapshot => {
                this.dataReady = true;
                this.read(snapshot, this.notify.bind(this))
            }, err => {
                throw err
            });
        }
    }

    read(snapshot,f){
        if(snapshot instanceof firebase.firestore.QuerySnapshot) {
            if (!this.data){
                this.data = Resource.from([]);
            }
            this.data.splice(0,this.data.length);
            this.data.push(...snapshot.docs.map(doc => ({...doc.data(), id: doc.id})))
        }else if(snapshot instanceof firebase.firestore.DocumentSnapshot){
            this.data = {...snapshot.data(), id: snapshot.id}
        }else {
            throw Error("unknown snapshot type");
        }
        f(this.data);
    }

    subscribe(f){
        clearTimeout(this.teardownTimeout);
        this.init();
        if(this.dataReady) {
            this.query.get({source: 'cache'}).then(snapshot => {
                this.read(snapshot, f)
            });
        }
        return super.subscribe(f);
    }

    subscribeOnce(f) {
        return super.subscribeOnce(f);
    }

    unsubscribe(f) {
        super.unsubscribe(f);
        if(this.observers.length === 0){
            clearTimeout(this.teardownTimeout);
            this.teardownTimeout = setTimeout(this.teardown.bind(this),60000)
        }
    }

    teardown(){
        if (this.queryUnsubscribe) {
            this.queryUnsubscribe();
            this.queryUnsubscribe = undefined;
        }
        this.dataReady = false;
    }

    isEqual(other){
       return this.constructor === other.constructor &&  this.query.isEqual(other.query)
    }
}

export class FirestoreResource extends React.Component {
    constructor(props) {
        super(props);
        this.state = {data:null};

        if (!(this.props.query instanceof FirestoreQuery)) {
            throw Error("expected FirestoreQuery instance");
        }
    }

    fetchData(query){
        const load$ = query.subscribeOnce(setLoading());
        const data$ = query.subscribe(data=>{
            this.setState({data})
        });
        this.unsubscribe = ()=>{
            load$();
            data$();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(this.props.query.isEqual(nextProps.query)){
            return;
        }

        const {query} = nextProps;
        this.unsubscribe();
        this.fetchData(query);
    }

    componentDidMount() {
        const {query} = this.props;
        this.fetchData(query)
    }

    componentWillUnmount(){
       this.unsubscribe();
    }

    render() {
        const {children} = this.props;
        const {data} = this.state;
        if (!data){
            return <></>
        }
        return children(data)
    }
}


export class Resource extends Array{

    constructor(...args) {
        super(...args);
        if(!args) return;

        const index = {};
        let touched = false;

        this.indexAdd = (...items) => {
            touched = true;
            for(let i = items.length-1; i >= 0; i--){
                index[items[i].id] = items[i];
            }
        };

        this.indexRemove = (...items) => {
            for(let i = items.length-1; i >= 0; i--){
                delete(index,items[i].id);
            }
        };

        this.get = (id)=>{
            if(this.length > 0 && !touched){
                this.indexAdd(...this)
            }
            return index[id];
        };

        this.hash = ()=>{
            return btoa(JSON.stringify(Object.keys(index)))
        };

        if (args.length === 1 && typeof args[0] === "number") return;
        for(let i = args.length-1; i >= 0; i--){
            this.indexAdd(args[i])
        }
    }

    push(...items) {
        const v = super.push(...items);
        this.indexAdd(...items);
        return v;
    }

    splice(start, deleteCount) {
        const v = super.splice(start, deleteCount);
        this.indexRemove(...v);
        return v;
    }

    pop() {
        const v = super.pop();
        this.indexRemove(v);
        return v;
    }

    shift() {
        const v = super.shift();
        this.indexRemove(v);
        return v;
    }

    slice(start, end) {
        return new this.constructor(...super.slice(start, end));
    }

    unshift(...items) {
        const v = super.unshift(...items);
        this.indexAdd(...items);
        return v
    }

    fill(value, start, end) {
        this.index(value);
        return super.fill(value, start, end);
    }
}

export const useResource = (query,...args)=>{
    const [data, setData] = useState(null);
    if (query instanceof Function){
        query = query(...args);
    }
    if (!query instanceof FirestoreQuery) {
        throw Error("expected FirestoreQuery instance");
    }

    useEffect(() => {
        const load$ = query.subscribeOnce(setLoading());
        const data$ = query.subscribe((data)=>{
            setData(data)
        });
        return ()=>{
            load$();
            data$();
        }
        // eslint-disable-next-line
    }, [query,...args]);

    return data
};


/**
 * Custom hook to interact with a Firestore collection using query configurations.
 *
 * @param {string} collection - The name of the Firestore collection.
 * @param {Object} [queryConfig={}] - Configuration for the query. Properties can include:
 *   - {number} [limit] - Limit on number of documents to retrieve.
 *   - {firebase.firestore.DocumentSnapshot} [startAt] - Starting value for a range query.
 *   - {Array<Object>} [where] - Array of query conditions in form of {field, op, value}.
 *   - {Object} [orderBy] - Array of order directives in form of {field: direction}.
 * @returns {Array} An array with the first element being the data and the second being the loading state.
 *
 * @example
 * const queryConfig = {
 *   limit: 10,
 *   startAt: {},
 *   where: [{field: "status", op: "==", value: "active"}],
 *   orderBy: {field: "desc"}
 * };
 * const [data, isLoading] = useCollection('users', queryConfig);
 */
export const useCollection = (collection, {limit, startAt, where, orderBy} = {}) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const memoizedQueryFn = useCallback(() => {
        setIsLoading(true);
        let query = firebase.firestore().collection(collection);

        if(!!limit){
            query = query.limit(limit)
        }

        if(!!where){
            [...where].forEach(queryConfig =>{
                const {field, op, value} = queryConfig.where
                query = query.where(field, op, value)
            })
        }

        if(!!orderBy){
            Object.keys(orderBy).forEach(field =>{
                let direction = orderBy[field];
                query = query.orderBy(field, direction)
            })
        }

        if (!!startAt){
            query = query.startAt(startAt)
        }

        return query;
    }, [collection,limit,where,startAt,orderBy]);

    useEffect(() => {
        const unsubscribe = memoizedQueryFn()
            .onSnapshot(snapshot => {
                const newData = [...snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id, _ref: doc }))];
                setData(newData);
                setIsLoading(false);
            }, err=>{
                console.log(err);
                setIsLoading(false);
            });

        return () => unsubscribe();
    }, [memoizedQueryFn]);

    return [data, isLoading];
};

/**
 * Custom hook to fetch a document from a Firestore collection.
 *
 * @param {string} collection - The name of the collection.
 * @param {string} id - The ID of the document.
 * @returns {[Object, boolean]} - Returns the document data and loading state
 * @example
 * const [data, isLoading, error] = useDocument('users', '12345');
 */
export const useDocument = (collection, id) => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const trimSlashes = (str) => {
        return str
            .split("/")
            .filter((c) => c)
            .join("/");
    }

    useEffect(() => {
        const docRef = firebase.firestore().doc(trimSlashes(collection) + "/" + id);

        return docRef.onSnapshot(snapshot => {
            if (snapshot.exists) {
                setData({...snapshot.data(), id: snapshot.id, _ref: snapshot.ref});
            }
            setIsLoading(false);
        }, err => {
            console.log(err);
            setIsLoading(false);
        });
    }, [collection, id]);

    return [data, isLoading];
};

/**
 * Custom hook to interact with Firestore's "manifests" collection.
 *
 * @param {function(firebase.firestore.Query | firebase.firestore.CollectionReference): firebase.firestore.Query} fn - A function that takes a Firestore CollectionReference as argument and returns a resource.
 * @returns {any} - Returns the resource obtained from useCollection.
 *
 * @example
 * const resource = useManifests((collection) => collection.where("field", "==", "value"));
 */
export const useManifests = (queryConfig) => {
    return useCollection("manifests", queryConfig)
}


export const useManifest = (id) => {
    return useDocument("manifests", id)
}

export const useDecoratedManifest = (id) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [manifest,manifestLoading] = useDocument("manifests", id)
    const [addressables, addressablesLoading] = useAddressables()


    useEffect(()=>{
        if(manifestLoading || addressablesLoading){
            setIsLoading(true)
            return;
        }

        setData({
                ...manifest,
                recipient: addressables[manifest.recipientId],
                sender:addressables[manifest.senderId]
            })
        setIsLoading(false)
    },[manifest, addressables, manifestLoading, addressablesLoading])

    return [data, isLoading]
}


/**
 * Custom hook to fetch and decorate manifests with additional data.
 *
 * @function useDecoratedManifests
 * @param {Function} queryFn - The function that determines how the manifests are queried.
 * @param {Array} deps - Dependency array for effect hooks, typically a list of variables that can affect the outcome of the query function.
 *
 * @returns {Array} An array containing:
 * - {Array} data: The decorated manifests list.
 * - {boolean} isLoading: Indicates if the manifests and addressables are still being fetched.
 *
 * @example
 * const [manifests, loading] = useDecoratedManifests(myQueryFunction, [someDependencies]);
 */
export const useDecoratedManifests = (queryConfig) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [manifests, manifestsLoading] = useManifests(queryConfig)
    const [addressables, addressablesLoading] = useAddressables()

    useEffect(()=>{
        if(manifestsLoading || addressablesLoading){
            setIsLoading(true)
            return;
        }

        setData(manifests.map(m => {
            return {
                ...m,
                recipient: addressables[m.recipientId],
                sender:addressables[m.senderId]
            }
        }))
        setIsLoading(false)
    },[manifests, addressables, manifestsLoading, addressablesLoading])

    return [data, isLoading]
}

export const useShips = (queryConfig) => {
    return useCollection("ships", queryConfig)
}

export const useOffices = (queryConfig) => {
    return useCollection("offices", queryConfig)
}

export const useDepartments = (queryConfig) => {
    return useCollection("departments", queryConfig)
}

export const useExternals = (queryConfig) => {
    return useCollection("externals", queryConfig)
}

export const useAddressables = ()=>{
    const [offices, officesLoading] = useOffices()
    const [ships, shipsLoading] = useShips();
    const [departments, departmentsLoading] = useDepartments();
    const [externals, externalsLoading] = useExternals();
    const [addressables, setAddressables] = useState([]);
    const [loading, setLoading] = useState(true);
    const withType = (v,type) => Object.fromEntries(v.map(v => ({_type: type,...v})).map(v => [v.id,v]));

    useEffect(()=>{
        if(!officesLoading && !shipsLoading && !departmentsLoading && !externalsLoading) {
            setAddressables({
                ...withType(ships, ShipType),
                ...withType(offices, OfficeType),
                ...withType(externals, ExternalType),
                ...withType(departments.map((department) => {
                    const parent = [...ships, ...offices].find(v => v.id === department.parent);
                    return {...department, name: [parent.name, department.name].filter(v => v).join(" ")}
                }), DepartmentType)
            })
            setLoading(false)
        }
    },[
        offices, officesLoading,
        ships, shipsLoading,
        departments, departmentsLoading,
        externals, externalsLoading
    ]);

    return [addressables,loading]
};

export const FilesCollection = ()=> FirestoreQuery.get(firebase.firestore().collection("files"));
export const TasksCollection = ()=> FirestoreQuery.get(firebase.firestore().collection("tasks"));
export const AssetsCollection = ()=> FirestoreQuery.get(firebase.firestore().collection("assets"));
export const StoragesCollection = ()=> FirestoreQuery.get(firebase.firestore().collection("storages"));
export const ProductsCollection = ()=> FirestoreQuery.get(firebase.firestore().collection("products"));
export const UsersCollection = ()=> FirestoreQuery.get(firebase.firestore().collection("users"));
export const ManifestsCollection = ()=> FirestoreQuery.get(firebase.firestore().collection("manifests"));
export const ShipsCollection = ()=> FirestoreQuery.get(firebase.firestore().collection("ships"));
export const OfficesCollection = ()=> FirestoreQuery.get(firebase.firestore().collection("offices"));
export const DepartmentsCollection = ()=> FirestoreQuery.get(firebase.firestore().collection("departments"));
export const ExternalsCollection = ()=> FirestoreQuery.get(firebase.firestore().collection("externals"));

export const ProductDocument = id => FirestoreQuery.get(firebase.firestore().doc("products/"+id));
export const UserDocument = id => FirestoreQuery.get(firebase.firestore().doc("users/"+id));
export const AssetDocument = id => FirestoreQuery.get(firebase.firestore().doc("assets/"+id));
export const StorageDocument = id => FirestoreQuery.get(firebase.firestore().doc("storages/"+id));
export const ManifestDocument = id => FirestoreQuery.get(firebase.firestore().doc("manifests/"+id));

export const FilesResource = ({children})=> <FirestoreResource query={FilesCollection()}>{children}</FirestoreResource>;
export const TasksResource = ({children})=> <FirestoreResource query={TasksCollection()}>{children}</FirestoreResource>;
export const AssetsResource = ({children})=> <FirestoreResource query={AssetsCollection()}>{children}</FirestoreResource>;
export const ProductsResource = ({children})=> <FirestoreResource query={ProductsCollection()}>{children}</FirestoreResource>;
export const ManifestsResource =  ({children})=> <FirestoreResource query={ManifestsCollection()}>{children}</FirestoreResource>;
export const ShipsResource = ({children})=> <FirestoreResource query={ShipsCollection()}>{children}</FirestoreResource>;
export const OfficesResource = ({children})=> <FirestoreResource query={OfficesCollection()}>{children}</FirestoreResource>;
export const DepartmentsResource = ({children})=> <FirestoreResource query={DepartmentsCollection()}>{children}</FirestoreResource>;
export const ExternalsResource = ({children})=> <FirestoreResource query={ExternalsCollection()}>{children}</FirestoreResource>;
export const UsersResource = ({children,decorate = true})=> {
    return <FirestoreResource query={UsersCollection()}>{users =>
        children(Resource.from(decorate ? users.map(v=>new User(v)) : users))
    }</FirestoreResource>;
};
export const StoragesResource = ({children,decorate = true})=> <FirestoreResource query={StoragesCollection()}>{storages=>{
    if(!storages || !decorate){
        return children(storages);
    }
    return children(storages.map(storage=>{
        return {...storage,lineageNames:storage.lineage.map((v)=>storages.get(v).name)}
    }))
}}</FirestoreResource>;

export const ProductResource = ({id,children})=> <FirestoreResource query={ProductDocument(id)}>{children}</FirestoreResource>;
export const UserResource = ({id,children})=> <FirestoreResource query={UserDocument(id)}>{children}</FirestoreResource>;
export const AssetResource = ({id,children})=> <FirestoreResource query={AssetDocument(id)}>{children}</FirestoreResource>;
export const StorageResource = ({id,children})=> <FirestoreResource query={StorageDocument(id)}>{children}</FirestoreResource>;
export const ManifestResource = ({id,children})=> <FirestoreResource query={ManifestDocument(id)}>{children}</FirestoreResource>;

