import React from 'react';
import {Route, Switch, withRouter} from "react-router-dom";
import {DepartmentsResource} from "../../services/Firestore/Resources";
import Paper from "../../components/Paper/Paper";
import withStyles from "react-jss";
import DepartmentList from "../../views/Department/DepartmentList";
import DepartmentPage from "./DepartmentPage";

const styles = {
    list: {
        gridArea: 'list',
    }
};

const DepartmentListPage = ({classes}) => {

    return (
        <DepartmentsResource>{departments => departments &&
            <Switch>
                <Route exact path="/departments">
                    <Paper>
                        <DepartmentList className={classes.list} departments={departments} />
                    </Paper>
                </Route>
                <Route path="/departments/:id" render={({match}) =>
                    <DepartmentPage department={departments.get(match.params.id)} />
                } />
            </Switch>
        }</DepartmentsResource>
    );
};

export default withRouter(withStyles(styles)(DepartmentListPage));
