import React from 'react';
import {Link} from "react-router-dom";
import withStyles from "react-jss";

const styles = {
    root: {
        padding: 10,
    },
    crumb: {
        textTransform: "capitalize",
        '&:before': {
            padding: 10,
            content: '"/"',
            color: "var(--second-color)",
            fontWeight: "bold",
            fontSize: "20pt"
        }
    }
};

const Breadcrumb = ({classes, path, limit=4}) => {
    let pathParts;
    if (path.trim() === "/"){
        pathParts = [""]
    }else{
        pathParts = path.split("/");
    }

    const crumbs = pathParts.map((p,i) =>
        <span key={i} className={classes.crumb}>
            <Link to={"/"+pathParts.slice(1,i+1).join("/")}>{p.length === 0 ? "home" : p}</Link>
        </span>);


    return (
        <div className={classes.root}>
            {crumbs.length>limit && <span className={classes.crumb}>...</span>}{crumbs.slice(crumbs.length > limit && crumbs.length-limit,crumbs.length)}
        </div>
    );
};

export default withStyles(styles)(Breadcrumb);
