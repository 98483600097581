// Types
export const StorageType = 0;
export const ShipType = 1;
export const OfficeType = 2;
export const ExternalType = 3;
export const DepartmentType = 4;

// Manifest states
export const ManifestDraft = 0;
export const ManifestSent = 1;
export const ManifestReceived = 2;

// Asset condition states
export const ConditionOK = 0;
export const ConditionDefect = 1;

// Owner types
export const NoneOwnerType = 0;
export const ShipOwnerType = 1;
export const ExternalOwnerType = 2;
export const OfficeOwnerType = 3;
export const DepartmentOwnerType = 4;
export const AssetOwnerType = 5;
