import React,{useState} from 'react';
import {createUseStyles} from 'react-jss'
import {StoragesResource} from "../../services/Firestore/Resources";
import AssetListSelect from "../../views/Asset/AssetListSelect";
import {useCurrentUser} from "../../services/AuthContext/AuthContext";
import {useAddressables} from "../../services/Context/Addressable";
import Icon from "../../components/Icon/Icon";
import Input from "../../components/Input/Input";
import {
    DepartmentsCollection,
    StoragesCollection,
    useResource
} from "../../services/Firestore/Resources";

const useStyles = createUseStyles({
    root: {},
    listContainer: {
        display: "grid",
        gridTemplateColumns: "length:30% length:70%",
        gridTemplateRows: "auto auto",
        gridTemplateAreas: '"header search" "list list"',
    },
    list: {
        gridArea: 'list',
    },
    header: {
        gridArea: 'header',
        justifySelf: 'self-start',
        marginTop: 10,
        marginLeft: 10,
    },
    searchInput: {
        margin: 0,
        width: "300px",
    },
    columnFilter: {
        cursor: 'pointer',
        margin: 10,
    },
    search: {
        gridArea: 'search',
        justifySelf: 'self-end',
        marginTop: 10,
        marginLeft: 10,
        display: "grid",
        gridTemplateColumns: "auto auto",
    },
});

const ManifestFindAssetForm = ({products,assets,onAddToManifest}) => {
    const classes = useStyles();
    const [filter,setFilter] = useState("");
    const [columnFilter,setColumnFilter] = useState(false);
    const addressables = useAddressables();
    const departments = useResource(DepartmentsCollection);
    const storages = useResource(StoragesCollection);
    const user = useCurrentUser();

    const DepartmentSelection = 0;
    const DepartmentParentSelection = 1;
    const AllSelection = 2;

    const handleFilterChange = (e) => {
        setFilter(e.target.value)
    };

    const [assetSelection,setAssetSelection] = useState(DepartmentParentSelection);
    let userHasDepartment = !(user.home === "" || !user.home);
    const filterAssetOnLineage = (storageId) => {
        if (!userHasDepartment) {
            return true
        }
        if(assetSelection === AllSelection){
            return true
        }
        if(storageId === null){ 
            return false
        }
        let lineage = storages.get(storageId).lineage;
        switch (assetSelection) {
            case DepartmentSelection:
                return lineage.includes(user.home);
            case DepartmentParentSelection:
                return lineage.includes(departments.get(user.home).parent);
            default:
                return true;
        }
    };
    if (!assets || !storages || !products || !departments) {
        return false
    }

    let searchLocation = "Search all assets";
    if (userHasDepartment) {
        if (assetSelection === DepartmentSelection) {
            searchLocation = "Search only " + departments.get(user.home).name
        } else if (assetSelection === DepartmentParentSelection) {
            searchLocation = "Search on " + addressables[departments.get(user.home).parent].name
        }
    }
   

    assets = assets.filter(v => filterAssetOnLineage(v.storageId))
    return <div className={classes.root}>

        <div className={classes.listContainer}>
            <div className={classes.search}>
                <Input className={classes.searchInput} placeholder={searchLocation} onChange={handleFilterChange} />
                <div>{
                    !columnFilter ?
                        <Icon className={classes.columnFilter} title="Show custom filtering" onClick={() => setColumnFilter(true)}>search</Icon> :
                        <Icon className={classes.columnFilter} title="Hide custom filtering" onClick={() => setColumnFilter(false)}>search_off</Icon>
                }
                </div>
            </div>
        </div>
        <div ><br></br>
            {userHasDepartment && <span>Filter by: </span> }
            {user.home && <>
                <label>
                    <input
                        type="radio"
                        defaultChecked
                        name="assetSelection"
                        onChange={() => {setAssetSelection(DepartmentParentSelection)}}
                        value={DepartmentParentSelection} /> {addressables[departments.get(user.home).parent].name}
                </label>
                <label>
                    <input
                        id="departmentSelection"
                        type="radio"
                        name="assetSelection"
                        onChange={() => {setAssetSelection(DepartmentSelection)}}
                        value={DepartmentSelection} /> {departments.get(user.home).name}
                </label>
                <label>
                    <input
                        type="radio"
                        name="assetSelection"
                        onChange={() => {setAssetSelection(AllSelection)}}
                        value={AllSelection}
                    /> All
                </label>
            </>}
        </div>
        <StoragesResource>{storages => storages &&
            <AssetListSelect filter={filter} columnFilter={columnFilter} addAssetToManifest={onAddToManifest}  {...{assets,products,storages}} />
        }</StoragesResource>
    </div>
};

export default ManifestFindAssetForm;
