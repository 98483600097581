import React,{useState} from 'react';
import Storage from './Storage';
import {useAddressables} from '../../services/Context/Addressable';
import {DepartmentsResource} from '../../services/Firestore/Resources';
import {ExternalType, NoneOwnerType} from "../../constants";
import withStyles from "react-jss";
import StorageEditForm from './StorageEditForm';
import Modal from '../../components/Modal/Modal';
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import Confirmation from "../../components/Confirmation/Confirmation";
import Api from "../../services/Api/Api";
import {useLoading} from "../../services/LoadingContext/LoadingContext";
import {withRouter} from "react-router-dom";
import Entity from '../../components/Entity/Entity';
import {useErrorHandler} from "../../services/Utils/useErrorHandler";

const styles = {
    address: {
        whiteSpace: "pre-wrap",
    },
};

const StoragePage = ({classes,history,storage,storages}) => {

    const [showEdit,setShowEdit] = useState(false);
    const [showDelete,setShowDelete] = useState(false);
    const addressables = useAddressables();
    const errorHandler = useErrorHandler();

    const handleDelete = useLoading(async ()=>{
        try{
            await Api.DeleteStorage(storage);
            history.push('/storages/'+storage.parent)
        }catch (e) {
            await errorHandler(e)
        }finally {
           setShowDelete(false)
        }
    });

    if(!storage) return("Storage not found");
    let root;
    let isStorageInTransit= false
    if(addressables) {
        const id = storages.get(storage.lineage[0]).id;
        root = addressables[id]
        
        if(!root){
        isStorageInTransit = true
        root = storages.get(storage.lineage[0])
        }
    }

    if (!root){
        return ""
    }
    return (
        <>
            <div>
                <h1><Entity title={root.name} deleted={root.deleted} /></h1>
                {isStorageInTransit ?
                    <p>This storage is in transit</p>:
                <div className={classes.address}>{root.address}</div>
                }
            </div>
            {!isStorageInTransit && root._type !== ExternalType &&
                <DepartmentsResource>{departments => departments &&
                    <div><br/>Departments:
                        {Object.values(departments)
                            .filter(d => d.parent === root && !d.deleted)
                            .map(d => (<span key={d.id} >{d.name}{", "}</span>))
                        }
                    </div>
                }
                </DepartmentsResource>
            }
            <div>
                <div style={{float:"right"}}>{storage.ownerType === NoneOwnerType && 
                    <>
                        <Button onClick={()=>setShowEdit(true)} circle title={"Edit "+storage.name+" storage"}>
                            <Icon>edit</Icon>
                        </Button>
                        <Button onClick={()=>setShowDelete(true)} circle title={"Delete "+storage.name+" storage"}>
                            <Icon>delete</Icon>
                        </Button>
                    </>
                }
                </div>
                <h2>Storages:</h2>
            </div>
            <Storage storage={storage} storages={storages} />
            <Modal open={showEdit} onClose={() => setShowEdit(false)}>
                <StorageEditForm storage={storage} onSubmit={() => setShowEdit(false)}/>
            </Modal>
            <Confirmation open={showDelete} onConfirm={handleDelete} onCancel={()=>setShowDelete(false)}>
                <p>Do you really want to delete the storage: {storage.name}</p>
            </Confirmation>
        </>
    );
};

export default withRouter(withStyles(styles)(StoragePage))
