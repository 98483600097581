import React from 'react';
import Button from "../../components/Button/Button";
import Api from "../../services/Api/Api";
import {useHistory} from "react-router-dom"
import {useErrorHandler} from "../../services/Utils/useErrorHandler";
import {useLoading} from "../../services/LoadingContext/LoadingContext";

const Export = () => {

    const errorHandler = useErrorHandler();
    const history = useHistory();

    const handleExportAll = useLoading(async ()=>{
        try {
            await Api.Export();
            history.push("/admin/tasks")
        } catch (e) {
            await errorHandler(e)
        }
    });

    return (
        <div>
            <Button onClick={handleExportAll}>Export all data</Button>
        </div>
    );
};

export default Export;
