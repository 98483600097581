import React from 'react';
import {download} from "./Download";

const DownloadCSV = ({children, data, fileName = "untitled.csv", separator = ","}) => {

    const handleClick = ()=>{
       download({
           content: data.map(v=>v.map(v=>v.toString()).map(v=>{
               if(v.indexOf("\n") !== -1 || v.indexOf(separator) !== -1 || v.indexOf("\"") !== -1){
                   return `"${v.replace("\"","\"\"")}"`
               }
               return v;
           }).join(separator)).join("\n"),
           fileName,
           mimeType: "text/csv;encoding:UTF-8"
       })
    }

    return (
        <div onClick={handleClick}>
            {children}
        </div>
    );
};

export default DownloadCSV;
