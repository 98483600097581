import React,{useEffect} from 'react';
import firebase from 'firebase/app';

const Logout = ()=>{
    useEffect(() => {
        setTimeout(()=>{
            firebase.auth().signOut().then(()=>{
                window.location.href="/";
            })
        },3000);
    }, []);

    return (
        <div>
            <p>Signing you out, please wait...</p>
        </div>
    )
};

export default Logout;