import React, {useState} from 'react';
import {Link, Route, Switch, withRouter} from "react-router-dom";
import {ExternalsResource} from "../../services/Firestore/Resources";
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import Paper from "../../components/Paper/Paper";
import Modal from "../../components/Modal/Modal";
import withStyles from "react-jss";
import ExternalList from "../../views/External/ExternalList";
import ExternalAddForm from "../../views/External/ExternalAddForm";
import ExternalPage from "./ExternalPage";
import Input from "../../components/Input/Input";

const styles = {
    topBar: {
        display: "grid",
        gridTemplateColumns: "auto auto",
    },
    add: {
        justifySelf: 'self-start',
        margin: 10,
    },
    search: {
        justifySelf: 'self-end',
        margin: 10,
    },
    list: {
        gridArea: 'list',
    }
};

const ExternalListPage = ({history, classes}) => {

    const [filter, setFilter] = useState("");

    const handleFilterChange = (e) => {
        setFilter(e.target.value)
    };

    const handleAdd = () => {
        history.push("/externals");
    };

    const handleAbort = () => {
        history.push("/externals");
    };

    return (
        <ExternalsResource>{externals => externals &&
            <Switch>
                <Route exact path="/externals(|/add)">
                    <Route path="/externals/add">
                        <Modal open={true} onClose={handleAbort}>
                            <ExternalAddForm onSubmit={handleAdd} />
                        </Modal>
                    </Route>
                    <Paper>
                        <div className={classes.topBar}>
                            <Link to="/externals/add" className={classes.add}>
                                <Button circle title="Add new external">
                                    <Icon>add</Icon>
                                </Button>
                            </Link>
                            <Input className={classes.search} placeholder={"Filter"} onChange={handleFilterChange} />
                        </div>
                        <ExternalList className={classes.list} filter={filter}  externals={externals} />
                    </Paper>
                </Route>
                <Route path="/externals/:id" render={({match}) =>
                    <ExternalPage external={externals.get(match.params.id)} />
                } />
            </Switch>
        }</ExternalsResource>
    );
};

export default withRouter(withStyles(styles)(ExternalListPage));
