import React,{useState} from 'react';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import ProductSelect from "../Select/ProductSelect";
import Form from "../../components/Form/Form";
import withStyles from "react-jss";
import Api from "../../services/Api/Api";
import {DateToYMD} from "../../services/Utils/Date";
import Checkbox from "../../components/Checkbox/Checkbox";
import {useLoading} from "../../services/LoadingContext/LoadingContext";
import {useErrorHandler} from "../../services/Utils/useErrorHandler";

const styles = {
    root: {
        display: "grid",
        gridTemplateColumns: "auto auto"
    },
    fullWidth: {
        gridColumn: "1/3"
    }
};

const AssetEditForm = ({classes,onUpdate,asset,product}) => {
    const {id} = asset;
    const [productId,setProductId] = useState(asset && asset.productId);
    const [serialNumber,setSerialNumber] = useState(asset && asset.serialNumber);
    const [tagNumber,setTagNumber] = useState(asset && asset.tagNumber);
    const [rental,setRental] = useState(asset && asset.rental);
    const [owner,setOwner] = useState(asset && asset.owner);
    const [calibrationDate,setCalibrationDate] = useState(asset && asset.calibrationDate && asset.calibrationDate.toDate());
    const errorHandler = useErrorHandler();

    const handleCalibrationDate = (value) => {
        setCalibrationDate(new Date(value))
    };
    const handleSubmit = useLoading(async (e) => {
        e.preventDefault();
        try {
            await Api.UpdateAsset({
                id,
                productId,
                serialNumber,
                tagNumber,
                rental,
                owner,
                calibrationDate: calibrationDate && calibrationDate.toJSON(),
            });
            onUpdate && onUpdate(id)
        } catch (e) {
            await errorHandler(e)
        }

    });

    if (!asset || !product) {
        return ""
    }

    return (
        <div>
            <h1>Edit asset details</h1>
            <Form onSubmit={handleSubmit} className={classes.root}>
                <ProductSelect
                    required
                    filter={p=>!p.container || p.id === product.id}
                    disabled={product.container}
                    value={productId}
                    onChange={({value}) => setProductId(value)}
                />
                <Input title="Serial number" required defaultValue={serialNumber} onChange={e => setSerialNumber(e.target.value)} />
                <Input title="Tag number" defaultValue={tagNumber} onChange={e => setTagNumber(e.target.value)} />
                <Input title="Owner" defaultValue={owner} onChange={e => setOwner(e.target.value)} />
                <Input title="Calibration date" type="date" defaultValue={DateToYMD(calibrationDate)} onChange={e => handleCalibrationDate(e.target.value)} />
                <Checkbox title="Rental" checked={rental} onChange={e => setRental(e.target.checked)} />
                <Button className={classes.fullWidth} style={{justifySelf: "center"}} type="submit">Save</Button>
            </Form>
        </div>
    );
};

export default withStyles(styles)(AssetEditForm)