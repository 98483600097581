import React from 'react';
import Api from "../../services/Api/Api";
import Modal from "../../components/Modal/Modal";
import withStyles from "react-jss";
import {Route, withRouter} from "react-router-dom";
import OfficeEditForm from "../../views/Office/OfficeEditForm";
import Office from "../../views/Office/Office";
import {Delete,Restore} from "../../components/Crud/Crud";
import {useErrorHandler} from "../../services/Utils/useErrorHandler";
import {useLoading} from "../../services/LoadingContext/LoadingContext";

const styles = {
    topBar: {
        display: "grid",
        gridTemplateColumns: "auto auto",
    },
    buttons: {
        justifySelf: 'self-end',
        margin: 10,
    },
};

const OfficesPage = ({match, history, office}) => {

    const errorHandler = useErrorHandler();

    const returnToOffice = () => {
        history.push(match.url);
    };

    const handleDelete = useLoading(async (data) => {
        try {
            await Api.DeleteOffice(data);
            history.push("/offices/" + data.id);
        } catch (e) {
            await errorHandler(e)
        }
    });

    const handleRestore = useLoading(async (data) => {
        try {
            await Api.RestoreOffice(data);
            history.push("/offices/" + data.id);
        } catch (e) {
            await errorHandler(e)
        }
    });

    return (
        <>
            <Office office={office}/>
            <Route path="/offices/:id/edit" render={() =>
                <Modal open={true} onClose={returnToOffice}>
                    <OfficeEditForm office={office} onUpdate={returnToOffice} />
                </Modal>
            } />
            <Route path="/offices/:id/delete" render={() =>
                <Modal open={true} onClose={returnToOffice}>
                    <Delete data={office} onDelete={handleDelete} onAbort={returnToOffice} />
                </Modal>
            } />
            <Route path="/offices/:id/restore" render={() =>
                <Modal open={true} onClose={returnToOffice}>
                    <Restore data={office} onRestore={handleRestore} onAbort={returnToOffice} />
                </Modal>
            } />
        </>
    );
};

export default withRouter(withStyles(styles)(OfficesPage));
