import React from 'react';
import Api from "../../services/Api/Api";
import Modal from "../../components/Modal/Modal";
import {Route,useRouteMatch, useHistory} from "react-router-dom";
import ShipEditForm from "../../views/Ship/ShipEditForm";
import Ship from "../../views/Ship/Ship";
import {Delete,Restore} from "../../components/Crud/Crud";
import {useErrorHandler} from "../../services/Utils/useErrorHandler";
import {useLoading} from "../../services/LoadingContext/LoadingContext";

const ShipsPage = ({ship}) => {

    const history = useHistory();
    const match = useRouteMatch();
    const errorHandler = useErrorHandler();

    const returnToShip = () => {
        history.push(match.url);
    };

    const handleDelete = useLoading(async (data) => {
        try {
            await Api.DeleteShip(data);
            history.push("/ships/" + data.id);
        } catch (e) {
            await errorHandler(e)
        }
    });

    const handleRestore = useLoading(async (data) => {
        try {
            await Api.RestoreShip(data);
            history.push("/ships/" + data.id);
        } catch (e) {
            await errorHandler(e)
        }
    });

    return (
        <>
            <Ship ship={ship} />
            <Route path="/ships/:id/edit" render={() =>
                <Modal open={true} onClose={returnToShip}>
                    <ShipEditForm ship={ship} onUpdate={returnToShip} />
                </Modal>
            } />
            <Route path="/ships/:id/delete" render={() =>
                <Modal open={true} onClose={returnToShip}>
                    <Delete data={ship} onDelete={handleDelete} onAbort={returnToShip} />
                </Modal>
            } />
            <Route path="/ships/:id/restore" render={() =>
                <Modal open={true} onClose={returnToShip}>
                    <Restore data={ship} onRestore={handleRestore} onAbort={returnToShip} />
                </Modal>
            } />
        </>
    );
};

export default ShipsPage;
