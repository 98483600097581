import React,{useRef,useState,useEffect,useContext} from 'react';
import withStyles from "react-jss"
import Edit from "../../components/Icon/Edit";
import Api from "../../services/Api/Api";
import LoadingContext from "../../services/LoadingContext/LoadingContext";
import {useLoading} from "../../services/LoadingContext/LoadingContext";
import {useErrorHandler} from "../../services/Utils/useErrorHandler";

const styles = {
    root: {
        position: "relative",
        display: "inline-block",
        "&:hover $edit":{
            display: "block",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 0.5,
            backgroundColor: "white",
            cursor: "pointer"
        }
    },
    img:{
        maxHeight: "150px",
        maxWidth: "150px",
        height: "auto",
        width: "auto",
    },
    input: {
        display: "none",
    },
    edit: {
        display: "none"
    }
};

//TODO move into proper location
export const useObjectURL = (src)=>{
    const [state, setState] = useState("");
    const errorHandler = useErrorHandler();

    useEffect(useLoading(async ()=>{
            try {
                const r = await fetch(src);
                const blob = await r.blob();
                setState(URL.createObjectURL(blob))
            } catch (e) {
                await errorHandler(e)
            }
        }
    ),[src]);

    return state
};

//TODO move into proper location
export const useFileUrl = (id)=>{
    const [state, setState] = useState("");
    const errorHandler = useErrorHandler();

    useEffect(useLoading(async ()=>{
        if (!id){
            return;
        }
        try {
            const r = await Api.InlineFile({fileId: id});
            const blob = await r.blob();
            setState(URL.createObjectURL(blob))
        } catch (e) {
            await errorHandler(e)
        }
    }), [id]);
    return state
};

const ProductImage = ({classes,fileId,editable,productId,...other})=>{

    const fileRef = useRef(null);
    const imgRef = useRef(null);
    const [image, setImage] = useState("https://via.placeholder.com/150");
    const loading = useContext(LoadingContext);
    const errorHandler = useErrorHandler();

    const handleUpdateImage = useLoading(async ({file}) => {
        try {
            await Api.UpdateProductImage({id: productId, file})
        } catch (e) {
            await errorHandler(e)
        }
    });

    const handleClick = ()=>{
        fileRef.current.click();
    };

    const loadImage = ()=>{
        loading.add((async()=>{
            try {
                const r = await Api.InlineFile({fileId});
                const blob = await r.blob();
                setImage(URL.createObjectURL(blob))
            } catch (e) {
                await errorHandler(e)
            }
        })())
    };

    useEffect(() => {
        if (!imgRef || !imgRef.current) {
            return;
        }

        if (!fileId){
            setImage("https://via.placeholder.com/150");
        }else {
            loadImage();
        }
        // if i include all deps, it goes into an infinite loop here. not sure why.
        // eslint-disable-next-line
    }, [fileId,imgRef]);

    useEffect(()=>{
        if(!image) return;
        imgRef.current.src = image;
    },[imgRef,image]);

    if (!productId){
        throw Error("no product id set")
    }

    return <div className={classes.root}>
        <input
            className={classes.input}
            ref={fileRef}
            type="file"
            accept="image/*"
            onChange={(e)=>handleUpdateImage({file: e.target.files[0]})}
        />
        <img alt="model" className={classes.img} ref={imgRef} {...other}/>
        {editable && <div className={classes.edit} onClick={handleClick}><Edit/></div>}
    </div>
};

export default withStyles(styles)(ProductImage);