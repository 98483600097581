import React,{useRef} from 'react';
import {createUseStyles} from 'react-jss'

const useFormElementStyles = createUseStyles({
    root: {
        display: "grid",
        gridGap: "5px",
        padding: "10px",
        font: "400 16px arial",
        textAlign: "left",
        color: "var(--fourth-text-color)",
        border: "1px solid var(--first-color)",
        WebkitBoxShadow:
            "inset 0 0 2px  rgba(0,0,0,0.1), 0 0 5px rgba(0,0,0,0.1)",
        MozBoxShadow:
            "inset 0 0 2px  rgba(0,0,0,0.1), 0 0 5px rgba(0,0,0,0.1)",
        boxShadow:
            "inset 0 0 2px  rgba(0,0,0,0.1), 0 0 5px rgba(0,0,0,0.1)",
        margin: "0 5px 10px 0",
        "& *": {
            resize: "none",
            fontWeight: "bold",
            border: "0",
            outline: "0",
            lineHeight: "20px",
            background: "transparent"
        },
    },
    disabled: {
        "& > *": {
            opacity: "0.6"
        }
    },
    title: {
        userSelect: 'none',
        font: "400 10pt sans-serif"
    },
    tooltip: {
        font: "400 12px sans-serif"
    },
    invalid: {
        color: "var(--error-color)",
    }
});

export const withFormElementStyle = (WrappedElement) => {
    return React.forwardRef((props,ref)=>{
        const rootRef = useRef(null);
        const classes = useFormElementStyles();
        const {disabled,validation,className,style,title,required} = props;
        const {valid,message} = validation;

        const handleClick = (e)=>{
            for (let target of rootRef.current.getElementsByTagName("*")) {
                if (target.form !== null && target.form instanceof HTMLFormElement){
                    target.focus();
                    break;
                }
            }
        };

        return <div ref={rootRef}
             className={[...[
                 classes.root,
                 disabled && classes.disabled,
                 !valid && classes.invalid
             ].filter(i => !!i),className].join(' ')} style={style} onClick={handleClick}>
            {(title||required) && <div className={classes.title}>{required && <span className={classes.required}>* </span>}{title}</div>}
            <WrappedElement ref={ref} {...props}/>
            {message && <div className={classes.title}>{message}</div>}
        </div>
    });
};
