import React, {useState} from 'react';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import Api from "../../services/Api/Api";
import Form from "../../components/Form/Form";
import {useErrorHandler} from "../../services/Utils/useErrorHandler";
import {useLoading} from "../../services/LoadingContext/LoadingContext";

const DepartmentAddForm = ({parent, parentType, onSubmit}) => {

    const errorHandler = useErrorHandler();
    const [name, setName] = useState("");

    const handleSubmit = useLoading(async (e) => {
        e.preventDefault();
        try {
            await Api.CreateDepartment({
                name, parent: parent,
                parentType: parentType
            });
            setName("");
            onSubmit && onSubmit()
        } catch (e) {
            await errorHandler(e);
        }
    });

    return (
        <Form onSubmit={handleSubmit}>
            <h2>Add new department</h2>
            <Input title="Name" required minLength="2" value={name} onChange={e => setName(e.target.value)} />
            <Button type="submit">Add</Button>
        </Form>
    );
};

export default DepartmentAddForm