import React,{useState} from 'react';
import withStyles from "react-jss"
import Table from '../../components/Table/Table';
import Row from '../../components/Table/Row/Row';
import Cell from '../../components/Table/Row/Cell/Cell';
import Button from '../../components/Button/Button';
import Icon from "../../components/Icon/Icon";
import MimeIcon from "../../components/Icon/MimeIcon";
import Confirmation from "../../components/Confirmation/Confirmation";
import {useLoading} from "../../services/LoadingContext/LoadingContext";
import Api from '../../services/Api/Api'
import FileSize from "../../components/FileSize/FileSize";
import {useErrorHandler} from "../../services/Utils/useErrorHandler";


const styles = {
    root:{
        display: "grid",
        gridGap: "5px",
    },
    actions: {
        display: "grid",
        justifyContent: 'end',
    },
    link: {
        cursor: "pointer",
    },
};

const FilesList = ({classes,files}) => {

    const [fileToDelete, setFileToDelete] = useState(null);
    const errorHandler = useErrorHandler();

    const handleDownload = useLoading(async ({fileId}) => {
        try {
            await Api.DownloadFile({fileId})
        } catch (e) {
            await errorHandler(e)
        }
    });

    const handleDelete = useLoading(async (data)=>{
        try {
            await Api.DeleteFile(data);
            setFileToDelete(null);
        } catch (e) {
            await errorHandler(e)
        }
    });

    return (
        <div className={classes.root}>
            <Table>
                <Row>
                    <Cell header>Type</Cell>
                    <Cell header>Name</Cell>
                    <Cell header>Size</Cell>
                    <Cell header></Cell>
                </Row>
                {files && files.length > 0
                    ? files.map((row)=>(
                        <Row key={row.id}>
                            <Cell style={{width:24}}>
                                <MimeIcon>{row.contentType}</MimeIcon>
                            </Cell>
                            <Cell>
                                <span className={classes.link} onClick={()=>handleDownload(row)} title={`Download ${row.filename}`}>
                                    {row.filename}
                                </span>
                            </Cell>
                            <Cell>
                                <span>
                                    <FileSize>{row.size}</FileSize>
                                </span>
                            </Cell>
                            <Cell className={classes.actions}>
                                <Button circle onClick={()=>setFileToDelete(row)} title={`Delete ${row.filename}`}>
                                    <Icon>delete</Icon>
                                </Button>
                            </Cell>
                        </Row>
                    ))
                    : <Row>
                        <Cell colSpan="4">No rows</Cell>
                    </Row>
                }
            </Table>
           <Confirmation open={fileToDelete} onConfirm={()=>handleDelete(fileToDelete)} onCancel={()=>setFileToDelete(null)}>
                <p>Are you sure you want to delete {fileToDelete && fileToDelete.filename}</p>
            </Confirmation>
        </div>
    );
};

export default withStyles(styles)(FilesList);