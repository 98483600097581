import React,{useState} from 'react';
import firebase from "firebase";
import Input from "../../components/Input/Input";
import {FirestoreQuery, FirestoreResource} from "../../services/Firestore/Resources";
import EventList from "../../views/Events/EventList";
import {setLoading} from "../../services/LoadingContext/LoadingContext";
import withStyles from "react-jss";

const styles = {
    root: {
        display: "grid",
        gridTemplateColumns: "auto auto",
        gridTemplateRows: "auto auto",
        gridTemplateAreas: '". ." "list list"',
    },
    list: {
        gridArea: 'list'
    }
};

const EventsPage = ({classes}) => {
    const [fromDate,setFromDate] = useState(new Date(Date.now() - 1000 * 60 * 60 * 24 * 7));
    const [toDate,setToDate] = useState(new Date(Date.now() + 1000 * 60 * 60 * 24));

    const trySetValidDate = (value,setter) => {
        const date = new Date(value);
        const isValidDate = Date.parse(date);
        isValidDate && setter(date);
    };

    return (
        <div className={classes.root}>
            <Input
                max={toDate.toISOString().substr(0,10)}
                title="From"
                type="date"
                defaultValue={fromDate.toISOString().substr(0,10)}
                onChange={(e) => trySetValidDate(e.target.value,setFromDate)}
            />
            <Input
                min={fromDate.toISOString().substr(0,10)}
                title="To"
                type="date"
                defaultValue={toDate.toISOString().substr(0,10)}
                onChange={(e) => trySetValidDate(e.target.value,setToDate)}
            />
            <FirestoreResource className={classes.list} onLoad={setLoading} query={FirestoreQuery.get(
                firebase.firestore()
                    .collection("events")
                    .orderBy("Timestamp","desc")
                    .where("Timestamp",">=",firebase.firestore.Timestamp.fromDate(fromDate))
                    .where("Timestamp","<=",firebase.firestore.Timestamp.fromDate(toDate))
                    .limit(500)
            )}>{events =>
                <EventList className={classes.list} events={events}/>
            }</FirestoreResource>
        </div>
    )
};

export default withStyles(styles)(EventsPage)