import {FairDate} from "./Date";
const day = 86400000;

export const getExpiringDate = (calibrationDate,calibrationInterval) => {
    if(!calibrationDate){
        return null
    }
    calibrationDate.setMonth(calibrationDate.getMonth() + Number(calibrationInterval))
    return calibrationDate;
}

export const calibrationText = (calibrationDate,calibrationInterval) => {
    if (calibrationInterval !== 0) {
        if (!calibrationDate) {
            return "Not calibrated";
        }
        let calDate = calibrationDate.toDate()
        return FairDate(calDate)
    }
    return "N/A";
};

export const calibrationTextWithExpire = (calibrationDate,calibrationInterval) => {
    if (calibrationInterval !== 0) {
        if (!calibrationDate) {
            return "Not calibrated"
        }
        let timeNow = new Date()
        let calDate = calibrationDate.toDate()
        let expiringDate = getExpiringDate(calibrationDate.toDate(),calibrationInterval)

        if (expiringDate.getTime() < timeNow.getTime()) {
            return FairDate(calDate) + " expired " + FairDate(expiringDate)
        }
        else if (expiringDate.getTime() < timeNow.getTime() + (day * 90)) {
            var days = Math.trunc((expiringDate.getTime() - timeNow.getTime()) / day);
            return FairDate(calDate) + " expires in " + days + " days at " + FairDate(expiringDate)
        } else {
            return FairDate(calDate) + " expires " + FairDate(expiringDate)
        }
    }
    return "N/A";
};