import React,{useState} from 'react';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import Textarea from '../../components/Textarea/Textarea';
import Form from "../../components/Form/Form";
import Api from "../../services/Api/Api";
import {useErrorHandler} from "../../services/Utils/useErrorHandler";
import {useLoading} from "../../services/LoadingContext/LoadingContext";

const ExternalAddForm = ({onSubmit}) => {

    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const errorHandler = useErrorHandler();

    const handleSubmit = useLoading(async (e) => {
        e.preventDefault();
        try {
            await Api.CreateExternal({name, address});
            onSubmit && onSubmit();
        } catch (e) {
            await errorHandler(e)
        }
    });

    return (
        <Form onSubmit={handleSubmit}>
            <Input title="Name" required minLength="3" onChange={e => setName(e.target.value)}/>
            <Textarea title="Address" rows="5" required onChange={e => setAddress(e.target.value)}/>
            <Button type="submit">Add</Button>
        </Form>
    );
};

export default ExternalAddForm