import React from 'react';
import withStyles from 'react-jss'
import {Route,withRouter,Link,Switch} from "react-router-dom";
import Modal from '../../components/Modal/Modal';
import Button from '../../components/Button/Button';
import Metadata from "../Metadata/Metadata";
import Icon from "../Icon/Icon";

const styles = {
  buttons:{
    textAlign: "right",
    "& > *": {
        marginLeft: 5,
    }
  }
};

const Crud = ({classes,match,history,data,show,add,addPath="/add",list,edit,editPath,del,delPath,})=>{
    const filter = (data,id)=>{
        if(Array.isArray(data)){
            return data.filter(el=>el.id === id).pop();
        }
        return data[id];
    };

    const render = (o,data)=>{
        switch (typeof o) {
            case "object":
                console.log("warning: deprecated, replace with function");
                return renderComponent(o,data);
            case "function":
                return renderFunction(o,data);
            default:
                throw new Error("unknown type for crud rendering, should be function")
        }
    };

    const renderComponent = (o,data)=>{
        return React.cloneElement(o,{data});
    };

    const renderFunction = (fn,data)=>{
        return fn(data);
    };

    return (
        <Switch>
          <Route exact={true} path={match.url+"(|"+addPath+")"} render={()=>
            <div>
                <div className={classes.buttons}>
                    {add && <Link to={match.url+addPath}><Button circle title="add"><Icon>add</Icon></Button></Link>}
                </div>
                {render(list,data)}

                <Route exact={true} path={match.url+addPath} render={()=>
                    <Modal open={true} onClose={() => history.push(match.url)}>{add}</Modal>
                }/>
            </div>
          }/>
          <Route path={match.url+"/:id"} render={({match})=>{
            let id = match.params.id;
            let el = filter(data,id);
            if (!el){
              return <p>no data</p>
            }
            return <RUD {...{
                show:show && render(show,el),
                edit:edit && render(edit,el),
                del:del && render(del,el),
                editPath,
                delPath
            }}/>
          }}/>
        </Switch>
    );
};

const Rud = ({classes,match,history,show,edit,editPath="/edit",del,delPath="/delete"})=>{
            const url = match.url.endsWith("/") ? match.url.substr(0,match.url.length-1) : match.url;
            return (
                <div>
                    {(edit || del) &&
                    <div className={classes.buttons}>
                        {edit && <Link to={url+editPath}><Button circle title="edit"><Icon>edit</Icon></Button></Link>}
                        {del && <Link to={url+delPath}><Button circle title="delete"><Icon>delete</Icon></Button></Link>}
                    </div>
                    }
                    {show}
                    {edit && <Route path={url+editPath} render={()=>
                        <Modal open={true} onClose={()=>history.push(url)}>
                            {edit}
                        </Modal>
                    }/>}
                    {del && <Route path={url+delPath} render={()=>
                        <Modal open={true} onClose={()=>history.push(url)}>
                            {del}
                        </Modal>
                    }/>}
                </div>
            )
};


export const Delete = (({data,onDelete,onAbort})=>
    data && <div>
        <p>Are you sure you wish to delete the following resource:</p>
        <Metadata data={data}/>
        <Button onClick={()=>onDelete(data)}>Yes, I am sure</Button>
        &nbsp;
        <Button onClick={()=>onAbort(data)}>No</Button>
    </div>
);

export const Restore = (({data,onRestore,onAbort})=>
    data && <div>
        <p>Are you sure you wish to restore the following resource:</p>
        <Metadata data={data}/>
        <Button onClick={()=>onRestore(data)}>Yes, I am sure</Button>
        &nbsp;
        <Button onClick={()=>onAbort(data)}>No</Button>
    </div>
);

export const CRUD = withStyles(styles)(withRouter(Crud));
export const RUD = withStyles(styles)(withRouter(Rud));
