import React from 'react';
import Product from "../../views/Product/Product";
import Api from "../../services/Api/Api";
import {Link,Route,withRouter} from "react-router-dom";
import {useLoading} from "../../services/LoadingContext/LoadingContext";
import Tab from "../../components/Tab/Tab";
import Pane from "../../components/Tab/Pane/Pane";
import {AssetsResource,FilesResource,StoragesResource,ProductsResource} from "../../services/Firestore/Resources";
import AssetList from "../../views/Asset/AssetList";
import Modal from "../../components/Modal/Modal";
import AuthContext from "../../services/AuthContext/AuthContext";
import AssetAddForm from "../../views/Asset/AssetAddForm";
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import {Delete,Restore} from "../../components/Crud/Crud";
import ProductEditForm from "../../views/Product/ProductEditForm"
import FilesPage from "../../views/Files/FilesPage";
import withStyles from "react-jss";
import {useErrorHandler} from "../../services/Utils/useErrorHandler";

const styles = {
    listContainer: {
        display: "grid",
        gridTemplateColumns: "auto auto",
        gridTemplateRows: "auto auto",
        gridTemplateAreas: '"add search" "list list"',
    },
    add: {
        gridArea: 'add',
        justifySelf: 'self-start',
        margin: 10,
    },
    search: {
        gridArea: 'search',
        justifySelf: 'self-end',
        margin: 10,
    },
    list: {
        gridArea: 'list',
    }
};

const ProductPage = ({match,history,product}) => {

    const errorHandler = useErrorHandler();

    const handleUpdateImage = useLoading(async ({id,file}) => {
        try {
            await Api.UpdateProductImage({id, file});
        } catch (e) {
            await errorHandler(e)
        }
    });


    const handleAddFile = useLoading(async ({id,files}) => {
        try {
            await Api.AddProductFile({id, files});
            history.push("/products/" + id);
        } catch (e) {
            await errorHandler(e)
        }
    });

    const handleSubmitAsset = (assetId) => {
        history.push('/assets/' + assetId)
    };

    const handleDelete = useLoading( async (data) => {
        try {
            await Api.DeleteProduct(data);
            history.push(match.url);
        } catch (e) {
            await errorHandler(e)
        }
    });

    const handleRestore = useLoading( async (data) => {
        try {
            await Api.RestoreProduct(data);
            history.push(match.url);
        } catch (e) {
            await errorHandler(e)
        }
    });

    const returnToProduct = () => {
        history.push(match.url);
    };

    if(!product) return("Model not found");

    return (
        <AssetsResource>{assets =>
            <FilesResource>{files =>
                <>
                    <Tab name="model-tab">
                        <Pane title="Model">
                            <Product product={product} onAddFile={handleAddFile} onUpdateImage={handleUpdateImage} />
                        </Pane>
                        <Pane title={"Assets (" + assets.filter(f => f.productId === product.id).length + ")"}>
                            {!product.deleted &&
                                <div style={{textAlign: "left"}}>
                                    <Link to={`/products/${product.id}/add-asset`}>
                                        <Button circle title={"Create new " + product.name}>
                                            <Icon>add</Icon>
                                        </Button>
                                    </Link>
                                </div>
                            }
                            <AssetsResource>{assets =>
                                <StoragesResource>{storages =>
                                    <ProductsResource>{products =>
                                        <AssetList assets={assets.filter(v => v.productId === product.id)} products={products} storages={storages} />
                                    }</ProductsResource>
                                }</StoragesResource>
                            }</AssetsResource>
                        </Pane>
                        <Pane title={"Files (" + files.filter(f => f.parent === product.id).length + ")"}>
                            <FilesPage parent={product.id} />
                        </Pane>
                    </Tab>
                    <Route path="/products/:id/add-asset" render={({match}) =>
                        <Modal open={true} onClose={() => history.push('/products/' + match.params.id)}>
                            <AuthContext.Consumer>{user =>
                                <AssetAddForm storageId={user.home} productId={product.id} onSubmit={handleSubmitAsset} />
                            }</AuthContext.Consumer>
                        </Modal>
                    } />
                    <Route path="/products/:id/edit" render={() =>
                        <Modal open={true} onClose={returnToProduct}>
                            <ProductEditForm product={product} onUpdate={returnToProduct} />
                        </Modal>
                    } />
                    <Route path="/products/:id/delete" render={() =>
                        <Modal open={true} onClose={returnToProduct}>
                            <Delete data={product} onDelete={handleDelete} onAbort={returnToProduct} />
                        </Modal>
                    } />
                    <Route path="/products/:id/restore" render={() =>
                        <Modal open={true} onClose={returnToProduct}>
                            <Restore data={product} onRestore={handleRestore} onAbort={returnToProduct} />
                        </Modal>
                    } />
                </>
            }</FilesResource>
        }</AssetsResource>
    );
};

export default withRouter(withStyles(styles)(ProductPage));