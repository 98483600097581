const filter = (query = "") => {
    return (obj) => {
        switch (typeof obj) {
            case "object":
                if (Array.isArray(obj)) {
                    return obj.some(filter(query))
                }
                if (obj === null){
                    return false
                }
                return Object.values(obj).some(filter(query));
            case "boolean":
            case "bigint":
            case "number":
            case "string":
                return String(obj).toLowerCase().indexOf(query.toLowerCase()) !== -1;
            case "function":
            case "symbol":
            case "undefined":
            default:
                return false;
        }
    }
};

export const queryObject = (obj,query) => {

    const f = filter(query);
    if (obj instanceof Array) {
        return obj.filter(f)
    }

    const m = {...obj};
    for (let k of Object.keys(m)) {
        if (!f(m[k])) {
            delete m[k]
        }
    }
    return m;
};

export const queryColumns = (obj,filterList) => {
    return  obj.filter(function(item) {
        for (var key in filterList) {
            if(Number.isInteger(item[key])){
                if (item[key] === undefined || (""+item[key]).toLowerCase().indexOf((""+filterList[key]).toLowerCase()) === -1 )
                return false;
            }
            else if (item[key] === undefined || (item[key].toLowerCase()).indexOf(filterList[key].toLowerCase()) === -1 )
            return false;
        }
        return true;
      });
};