import React,{useState} from 'react';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import Api from '../../services/Api/Api';
import {StoragesResource} from '../../services/Firestore/Resources';
import Form from "../../components/Form/Form";
import {StorageTitle} from "../../components/Title/StorageTitle/StorageTitle";
import {useErrorHandler} from "../../services/Utils/useErrorHandler";
import {useLoading} from "../../services/LoadingContext/LoadingContext";

const StorageAddForm = ({parent,onSubmit}) => {
    
    const [name,setName] = useState("");
    const errorHandler = useErrorHandler();

    const handleSubmit = useLoading(async (e) => {
        e.preventDefault();
        try {
            await Api.CreateStorage({name,parent});
            setName("");
            onSubmit && onSubmit();
        }catch (e) {
            await errorHandler(e)
        }
    });

    return (
        <StoragesResource>{storages => storages && parent &&
            <Form onSubmit={handleSubmit}>
                <div>Create a storage in <StorageTitle full={true} {...storages.get(parent)} /></div>
                <Input required minLength={3} title="Name" value={name} onChange={e => setName(e.target.value)} />
                <Button type="submit">Add</Button>
            </Form>
        }</StoragesResource>
    );
};

export default StorageAddForm;