
import React,{useState} from 'react';
import withStyles from 'react-jss'

const styles = {
    sortBox: {
        cursor: "pointer",
        display: "inlineBlock",
        width: 24,
        height: 24,
        maxWidth: 24,
        maxHeight: 24,
        overflow: "hidden",
        position: "relative",
        verticalAlign: "top",
    },
    sortAsc: {
        position: "absolute",
        top: -6,
    },
    sortDesc: {
        position: "absolute",
        top: 0,
    }
};

function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        let result
        if (typeof a[property] === "string") {
            result = order(a[property].toLowerCase(),b[property].toLowerCase());
        } else {//numbers and others
            result = order(a[property],b[property]);
        }
        return result * sortOrder;
    }
}

function order(a,b) {
    if (a < b) {
        return -1
    } else {
        if (a > b) {
            return 1
        } else {
            return 0
        }
    }
}

const Asc = 0;
const Desc = 1;

const SortArrows = ({classes,tag,handleSort,selected}) => {

    const [sortDirection,setSortDirection] = useState("");

    const handleSortColumn = (tag) => {
        if (sortDirection !== Desc) {
            setSortDirection(Desc)
            handleSort(dynamicSort(tag),tag)
        } else {
            setSortDirection(Asc)
            handleSort(dynamicSort("-" + tag),tag)
        }
    };

    return (
        <span className={classes.sortBox} onClick={() => handleSortColumn(tag)}>
            {(sortDirection !== Asc || !selected) && <i className={classes.sortAsc + " material-icons"}>arrow_drop_up</i>}
            {(sortDirection !== Desc || !selected) && <i className={classes.sortDesc + " material-icons"}>arrow_drop_down</i>}
        </span>
    );
};

export default withStyles(styles)(SortArrows);