import React from 'react';
import {createUseStyles} from "react-jss"
import Table from '../../components/Table/Table';
import Row from '../../components/Table/Row/Row';
import Cell from '../../components/Table/Row/Cell/Cell';
import {Link} from "react-router-dom";
import Entity from '../../components/Entity/Entity';
import {queryObject} from "../../services/Utils/Query";
import Button from '../../components/Button/Button';
import Icon from '../../components/Icon/Icon';
import DownloadCSV from "../../components/DownloadCSV/DownloadCSV";

const useStyles = createUseStyles({
    root: {
        display: "grid",
        gridGap: "5px",
    },
});

const toCSV = (externals) =>{
    externals = externals.map(row => [
        row.name,
        row.address,
    ])
    externals.unshift(['External name','Address (multiline)'])
    return externals;
}

const ExternalList = ({externals, filter}) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Table>
                <Row>
                    <Cell header>Name</Cell>
                    <Cell header>Address</Cell>
                </Row>
                {externals && externals.length > 0
                    ? queryObject(externals, filter).sort((a, b) => (a.name > b.name) ? 1 : -1).map((row) => (
                        <Row key={row.id}>
                            <Cell>
                                <Link to={"/externals/" + row.id}>
                                    <Entity title={row.name} deleted={row.deleted} />
                                </Link>
                            </Cell>
                            <Cell>
                                <Entity title={row.address} deleted={row.deleted} />
                            </Cell>
                        </Row>
                    ))
                    : <Row><Cell>No rows</Cell></Row>
                }{externals.length > 0 &&
                    <Row>
                        <Cell align="center" colSpan="8">
                            <DownloadCSV data={toCSV(externals)} fileName={"externals-"+filter+".csv"}>
                                <Button circle title="Download result as file">
                                    <Icon>save_alt</Icon>
                                </Button>
                            </DownloadCSV>
                        </Cell>
                    </Row>
                }
            </Table>
        </div>
    );
};

export default ExternalList;