import React from 'react';
import withStyles from 'react-jss'

const styles = {
    root: {
        padding: "10px",
        cursor: "pointer",
    },
    selected: {
        fontWeight: "bold"
    }
};


const Option = ({classes,value,selected,disabled,onSelect,children})=>{
    if(value === undefined){
        value = children;
    }
    return (
        <div className={[
            classes.root, 
            selected && classes.selected
        ].filter(i => !!i).join(' ')} onMouseDown={()=>onSelect && onSelect(value)}>
            {children}
        </div>
    );
};

export default withStyles(styles)(Option);
