import React,{useState} from 'react';
import Api from '../../services/Api/Api';
import {UserLink} from "../../components/Link/UserLink/UserLink";
import {AssetsResource,UsersResource} from '../../services/Firestore/Resources';
import {FairDate} from '../../services/Utils/Date';
import Confirmation from '../../components/Confirmation/Confirmation';
import {useErrorHandler} from "../../services/Utils/useErrorHandler";
import {useLoading} from "../../services/LoadingContext/LoadingContext";

const AssetCommentList = ({assetId,user}) => {
    const [commentToBeDeleted,setCommentToBeDeleted] = useState(null);
    const errorHandler = useErrorHandler();

    if (!assetId) {
        throw Error("missing asset parameter")
    }

    const handleDeleteComment = useLoading(async ()=>{
        if (!commentToBeDeleted) return;
        try {
            await Api.DeleteAssetComment({
                id: assetId,
                commentId: commentToBeDeleted,
            });
            setCommentToBeDeleted(null)
        } catch (e) {
            await errorHandler(e)
        }
    });

    const confirmDeleteComment = function (commentId) {
        setCommentToBeDeleted(commentId)
    };

    return (

        <div>
            <UsersResource>{users =>
                <AssetsResource>{assets =>
                    Object.values(assets.get(assetId).comments)
                        .sort((a,b) => (a.timestamp < b.timestamp) ? 1 : -1)
                        .map((c,index) =>
                            <div key={index}>
                                <div><i> {FairDate(c.timestamp.toDate(),true) + " "}
                                    :  </i><UserLink {...users.get(c.userId)} />{c.userId === user.id &&
                                        <i style={{cursor: "pointer"}} onClick={() => confirmDeleteComment(c.id)} className="material-icons" title={"Delete comment"}>delete_outline</i>
                                    }</div>
                                <pre>{c.comment}</pre>
                            </div>
                        )

                }
                </AssetsResource>
            }</UsersResource>
            <Confirmation open={commentToBeDeleted}
                onConfirm={handleDeleteComment}
                onCancel={() => setCommentToBeDeleted(null)}>
                <p>Are you certain you want to delete this comment?</p>
            </Confirmation>
        </div>
    )
};

export default AssetCommentList