import React from 'react';
import {withFormElementStyle} from "../Form/withFormElementStyle";
import {withValidation} from "../Form/withValidation";

const Input = React.forwardRef(({type,className,title,style, inputStyle,...props},ref)=>{
    return (
        <input ref={ref} type={type} style={inputStyle} {...props}/>
    );
});

export default withValidation(withFormElementStyle(Input))
