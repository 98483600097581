import React, {useState} from 'react';
import {useDecoratedManifests} from "../../services/Firestore/Resources";
import {FairDate} from "../../services/Utils/Date";
import {ManifestDraft, ManifestReceived, ManifestSent} from "../../constants";
import Icon from "../../components/Icon/Icon";
import {useHistory} from "react-router-dom";
import {DataTable} from "../../components/DataTable/DataTable";

const ManifestList = ({...props}) => {
    const [startAt, setStartAt] = useState([])
    const [order, setOrder] = useState({date: 'desc'})
    const [limit, setLimit] = useState(25)
    const history = useHistory()
    const [manifests, loading] = useDecoratedManifests({limit, orderBy: order, startAt:startAt[0]})

    const columns = [
        {
            key: 'date',
            render: (value, row) => FairDate(value.toDate()),
            sortable: true,
            minWidth: true,
        },
        {
            key: 'manifestNumber',
            label: 'DO-MF NO',
            sortable: true,
            minWidth: true,
        },
        {
            key: 'name',
            sortable: true,
        },
        {
            key: 'project',
            sortable: true,
        },
        {
            key: 'reference',
            sortable: true,
        },
        {
            key: 'sender',
            render: (v) => v.name,
        },
        {
            key: 'recipient',
            render: (v) => v.name
        },
        {
            key: 'state',
            label: 'Status',
            render: (v, row) => renderState(v, row.stateUpdated),
            sortable: true,
            minWidth: true,
        }
    ];
    return <>
        <DataTable data={manifests}
                   order={order}
                   columns={columns}
                   loading={loading}
                   paginatePrevDisabled={startAt.length === 0 || loading}
                   pageinateNextDisabled={loading}
                   onRowClick={row => {
                       history.push("/manifests/" + row.id);
                   }}
                   onPaginate={({page}) => {
                       if (page < 0) {
                           setStartAt(data => {
                               const newData = [...data]
                               newData.shift()
                               return newData;
                           })
                       } else {
                           setStartAt(data => {
                               const newData = [...data]
                               newData.unshift(manifests[manifests.length - 1]._ref)
                               return newData;
                           })
                       }
                   }}
                   onSort={(key, dir) => {
                       setOrder(order => {
                           const newOrder = {}
                           if (dir !== 'none') {
                               newOrder[key] = dir
                           }
                           return newOrder
                       })
                   }}
                   onLimitChange={e => {
                       setLimit(e.limit)
                       setStartAt([])
                   }}
                   {...props}
        />
    </>
}
export default ManifestList

const renderStateTitle = (state, stateUpdated) => {
    switch (state) {
        case ManifestDraft:
            return "Draft created " + FairDate(stateUpdated.toDate());
        case ManifestSent:
            return "Sent " + FairDate(stateUpdated.toDate());
        case ManifestReceived:
            return "Received " + FairDate(stateUpdated.toDate());
        default:
            return "unknown state";
    }
};

const renderState = (state, stateUpdated) => {
    switch (state) {
        case ManifestDraft:
            return <Icon title={renderStateTitle(state, stateUpdated)}>short_text</Icon>;
        case ManifestSent:
            return <Icon title={renderStateTitle(state, stateUpdated)}>local_shipping</Icon>;
        case ManifestReceived:
            return <Icon title={renderStateTitle(state, stateUpdated)}>done</Icon>;
        default:
            return <Icon>error</Icon>;
    }
};