import React from 'react';
import withStyles from "react-jss";
import {withRouter} from "react-router-dom";
import User from "../../views/User/User";

const styles = {
    topBar: {
        display: "grid",
        gridTemplateColumns: "auto auto",
    },
    buttons: {
        justifySelf: 'self-end',
        margin: 10,
    },

};

const UsersPage = ({user = {}}) => {

    return (
        <>
            <User user={user} />
            <div>Events....</div>
        </>
    );
};

export default withRouter(withStyles(styles)(UsersPage));
