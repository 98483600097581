import React,{useState} from 'react';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import Textarea from '../../components/Textarea/Textarea';
import Form from "../../components/Form/Form";


const OfficeAddForm = ({onSubmit}) => {

    const [name, setName] = useState("");
    const [address, setAddress] = useState("");

    const handleSubmit = function (e) {
        e.preventDefault();
        onSubmit({name,address})
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Input title="Name" required minLength="3" onChange={e => setName(e.target.value)}/>
            <Textarea title="Address" rows="5" required onChange={e => setAddress(e.target.value)}/>
            <Button type="submit">Add</Button>
        </Form>
    );
};

export default OfficeAddForm