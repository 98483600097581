import React from "react"
import Paper from "../../components/Paper/Paper";
import {TasksResource} from "../../services/Firestore/Resources";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import withStyles from "react-jss";
import {FairDate} from "../../services/Utils/Date";
import Api from "../../services/Api/Api"
import {useLoading} from "../../services/LoadingContext/LoadingContext";
import Popover from "../../components/Popover/Popover";
import {useErrorHandler} from "../../services/Utils/useErrorHandler";

const TaskStarted = 0;
const TaskCompleted = 1;
const TaskFailed = 2;

const styles = {
    root: {
        padding: 10
    },
    thead: {
        "& > th": {
            textAlign: "left"
        }
    },
};

const TasksPage = ({classes})=>{

    const errorHandler = useErrorHandler();

    const progressValue = (status)=>{
        switch (status) {
            case TaskStarted:
                return undefined;
            case TaskCompleted:
                return 100;
            case TaskFailed:
            default:
                return 0;
        }
    };

    const resultValue = ({type,status,data,error}) =>{
        switch (type) {
            case "export":
                if (status === TaskCompleted) {
                    return (
                        <a
                            href={"/"}
                            style={{cursor:"pointer"}}
                            onClick={(event) => {
                                event.preventDefault(); // Prevents the default action (navigation).
                                download(data);
                            }}
                        >
                            Download
                        </a>
                    );
                }
            // eslint-disable-next-line
            default:
                switch (status) {
                    case TaskCompleted:
                        return "Success";
                    case TaskStarted:
                        return "Queued";
                    case TaskFailed:
                        return <Popover position="right">
                            <span>Failed with error</span>
                            <pre>{error}</pre>
                        </Popover>;
                    default:
                        return "N/A"
                }
        }
    };

    const download = useLoading(async (fileId)=>{
        try {
            await Api.DownloadFile({fileId})
        } catch (e) {
            await errorHandler(e)
        }
    });

    return <Paper className={classes.root}>
        <h1>Tasks</h1>
        <TasksResource>{tasks =>
            <table border={1} style={{width: "100%"}}>
                <tr className={classes.thead}>
                    <th>Timestamp</th>
                    <th>Type</th>
                    <th>Status</th>
                    <th>Result</th>
                </tr>
                {tasks.slice(0,25).sort((t1,t2)=>t2.timestamp.toMillis() - t1.timestamp.toMillis()).map(t=><tr>
                    <td>{FairDate(t.timestamp.toDate())}</td>
                    <td>{t.type}</td>
                    <td><ProgressBar value={progressValue(t.status)}/></td>
                    <td>{resultValue({...t})}</td>
                </tr>)}
            </table>
        }</TasksResource>
    </Paper>
};

export default withStyles(styles)(TasksPage)