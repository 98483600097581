import React, {useState} from 'react';
import withStyles from 'react-jss'
import AssetList from '../Asset/AssetList';
import StorageAddForm from './StorageAddForm';
import Modal from '../../components/Modal/Modal';
import {Link} from "react-router-dom";
import {AssetsResource, ProductsResource} from '../../services/Firestore/Resources';
import Input from "../../components/Input/Input";
import {StorageTitle} from "../../components/Title/StorageTitle/StorageTitle";
import Entity from '../../components/Entity/Entity';
import {AssetOwnerType} from "../../constants";
import Icon from "../../components/Icon/Icon";

const styles = {
    root: {
        display: "grid",
    },
    link: {
        cursor: "pointer",
        fontStyle: "italic",
        color: "var(--third-color)",
    },
    storageLink: {
        cursor: "pointer",
    },
    assetTitle: {
        display: "grid",
        gridTemplateColumns: "auto auto",
    },
    search: {
        justifySelf: 'self-end',
        margin: 10,
        display: "grid",
        gridTemplateColumns: "auto auto",
    },
    columnFilter: {
        cursor: 'pointer',
        margin: 10,
    },    
};

const Storage = ({classes, storage, storages}) => {
    const [showAdd, setShowAdd] = useState(false);
    const [q, setQ] = useState("");
    const [columnFilter,setColumnFilter] = useState(false);
    let space = 0;

    function renderSpace(increment) {
        let spaces = "";
        let i = 0;
        while (i < space) {
            spaces += "\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0"
            i++;
        }
        if (increment) {
            space += 1;
        }
        return spaces;
    }
    let lineages = "";
    let children = "";
    function renderStorages(sid) {
        //Shows lineage from rootstorage down to position of storage youre in.
        if (storages.get(sid).lineage) {
            lineages = Object.values(storages.get(sid).lineage).map((id) =>
                <div key={id}><span>{renderSpace(true)}</span>
                    {id !== sid ?
                        <Link to={"/storages/" + id}><Entity title={storages.get(id).name} deleted={storages.get(id).deleted}/></Link>
                        : <span>{storages.get(id).name}</span>
                    }
                </div>
            );
        }//shows all children to storage youre in.
        if (storages.get(sid).lineage) {
            children = Object.values(storages)
                .filter(s => sid === s.parent && !s.deleted)
                .map((s) =>
                    <div key={s.id}><span>{renderSpace(false)}</span>
                        <Link to={"/storages/" + s.id}>{storages.get(s.id).name}</Link>
                    </div>
                );
        }
    }

    function limitAssets(assets) {
        return Object.values(assets).filter(v => v.storageId && storages.get(v.storageId).lineage.includes(storage.id))
    }
    
    return (
        <div className={classes.root}>
            <div>
                {renderStorages(storage.id)}
                {lineages}
                {children}

                <div>
                    <span>{renderSpace(false)}</span>
                    {!storage.deleted && storage.ownerType !== AssetOwnerType && <span className={classes.link} onClick={() => {setShowAdd(true)}}>Add new storage here</span>}
                    <Modal open={showAdd} onClose={() => setShowAdd(false)}>
                        <StorageAddForm parent={storage.id} onSubmit={() => setShowAdd(false)} />
                    </Modal>
                    </div>
            </div>
            <div className={classes.assetTitle}>
                <h2>Assets within <StorageTitle full={true} {...storage}/></h2>
                <div className={classes.search}>
                    <Input className={classes.search} placeholder="Filter" defaultValue={q} onChange={(e) => setQ(e.target.value)} />
                    <div>{
                        !columnFilter ?
                            <Icon className={classes.columnFilter} title="Show custom filtering" onClick={() => setColumnFilter(true)}>search</Icon> :
                            <Icon className={classes.columnFilter} title="Hide custom filtering" onClick={() => setColumnFilter(false)}>search_off</Icon>
                    }
                    </div>
                </div>
            </div>
            <AssetsResource>{assets => assets &&
                <ProductsResource>{products => products &&
                    <AssetList assets={limitAssets(assets)} products={products} storages={storages} filter={q} columnFilter={columnFilter} />
                }</ProductsResource>
            }</AssetsResource>
        </div>
    );
};

export default withStyles(styles)(Storage)
