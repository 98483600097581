import React,{useState} from 'react';
import {Link,Route,Switch,useHistory} from "react-router-dom";
import Api from "../../services/Api/Api";
import {ShipsResource} from "../../services/Firestore/Resources";
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import Paper from "../../components/Paper/Paper";
import Modal from "../../components/Modal/Modal";
import {createUseStyles} from "react-jss";
import ShipList from "../../views/Ship/ShipList";
import ShipAddForm from "../../views/Ship/ShipAddForm";
import ShipPage from "./ShipPage";
import Input from "../../components/Input/Input";
import AuthContext, {Admin} from "../../services/AuthContext/AuthContext";
import {useErrorHandler} from "../../services/Utils/useErrorHandler";
import {useLoading} from "../../services/LoadingContext/LoadingContext";

const useStyles = createUseStyles({
    topBar: {
        display: "grid",
        gridTemplateColumns: "auto auto",
    },
    add: {
        justifySelf: 'self-start',
        margin: 10,
    },
    search: {
        justifySelf: 'self-end',
        margin: 10,
    },
    list: {
        gridArea: 'list',
    }
});

const ShipListPage = () => {
    const classes = useStyles();
    const history = useHistory();
    const errorHandler = useErrorHandler();
    const [filter,setFilter] = useState("");

    const handleFilterChange = (e) => {
        setFilter(e.target.value)
    };

    const handleAdd = useLoading(async (data) => {
        try {
            await Api.CreateShip(data)
            history.push("/ships");
        } catch (e) {
            await errorHandler(e)
        }
    });

    const handleAbort = () => {
        history.push("/ships");
    };

    return (
        <AuthContext.Consumer>{user => user &&
            <ShipsResource>{ships => ships &&
                <Switch>
                    <Route exact path="/ships(|/add)">
                        <Route path="/ships/add">
                            <Modal open={true} onClose={handleAbort}>
                                <ShipAddForm onSubmit={handleAdd} />
                            </Modal>
                        </Route>
                        <Paper>
                            <div className={classes.topBar}>
                                <div>
                                    {user.hasRole(Admin) &&
                                        <Link to="/ships/add" className={classes.add}>
                                            <Button circle title="Add new Ship">
                                                <Icon>add</Icon>
                                            </Button>
                                        </Link>}
                                </div>
                                <Input className={classes.search} placeholder={"Filter"} onChange={handleFilterChange} />
                            </div>
                            <ShipList className={classes.list} filter={filter} ships={ships} />
                        </Paper>
                    </Route>
                    <Route path="/ships/:id" render={({match}) =>
                        <ShipPage ship={ships.get(match.params.id)}/>
                    } />
                </Switch>
            }</ShipsResource>
        }</AuthContext.Consumer>
    );
};

export default ShipListPage;
