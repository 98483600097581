import React from "react"
import withStyles from 'react-jss'

const styles = {
    root: {
        position: 'relative',
        width:'100%',
        height: 5,
        overflow:'hidden'
    },
    bar:{
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundImage: 'linear-gradient(to right, var(--second-color),var(--third-color))',
    },
    indeterminate:{
        animationName: '$slide',
        animationDuration: '1.5s',
        animationIterationCount: 'infinite',
        animationDirection: 'alternate',
        animationTimingFunction: 'ease-in-out'
    },
    '@keyframes slide':{
        from:{
            left: '-100%',
        },
        to:{
            left: '100%',
        }
    }
};

const ProgressBar = ({classes, value, ...other})=>{
    const percent = Number(value);
    const determinate = !isNaN(percent);

    return (
        <div className={classes.root} {...other}>
            <div className={[classes.bar,!determinate && classes.indeterminate].filter(v=>v).join(" ")} style={{left:`${percent-100}%`}}/>
        </div>
    )
};

export default withStyles(styles)(ProgressBar);