import React,{useState} from 'react';
import {Link,Route,Switch,withRouter} from "react-router-dom";
import Api from "../../services/Api/Api";
import {OfficesResource} from "../../services/Firestore/Resources";
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import Paper from "../../components/Paper/Paper";
import Modal from "../../components/Modal/Modal";
import withStyles from "react-jss";
import OfficeList from "../../views/Office/OfficeList";
import OfficeAddForm from "../../views/Office/OfficeAddForm";
import OfficePage from "./OfficePage";
import Input from "../../components/Input/Input";
import AuthContext, {Admin} from "../../services/AuthContext/AuthContext";
import {useErrorHandler} from "../../services/Utils/useErrorHandler";
import {useLoading} from "../../services/LoadingContext/LoadingContext";

const styles = {
    topBar: {
        display: "grid",
        gridTemplateColumns: "auto auto",
    },
    add: {
        justifySelf: 'self-start',
        margin: 10,
    },
    search: {
        justifySelf: 'self-end',
        margin: 10,
    },
    list: {
        gridArea: 'list',
    }
};

const OfficeListPage = ({history,classes}) => {

    const errorHandler = useErrorHandler();
    const [filter,setFilter] = useState("");

    const handleFilterChange = (e) => {
        setFilter(e.target.value)
    };

    const handleAdd = useLoading(async (data) => {
        try {
            await Api.CreateOffice(data);
            history.push("/offices");
        } catch (e) {
            await errorHandler(e)
        }
    });

    const handleAbort = () => {
        history.push("/offices");
    };

    return (
        <AuthContext.Consumer>{user => user &&
            <OfficesResource>{offices => offices &&
                <Switch>
                    <Route exact path="/offices(|/add)">
                        <Route path="/offices/add">
                            <Modal open={true} onClose={handleAbort}>
                                <OfficeAddForm onSubmit={handleAdd} />
                            </Modal>
                        </Route>
                        <Paper>
                            <div className={classes.topBar}>
                                <div>
                                    {user.hasRole(Admin) &&
                                        <Link to="/offices/add" className={classes.add}>
                                            <Button circle title="Add new Office">
                                                <Icon>add</Icon>
                                            </Button>
                                        </Link>}
                                </div>
                                <Input className={classes.search} placeholder={"Filter"} onChange={handleFilterChange} />
                            </div>
                            <OfficeList className={classes.list} filter={filter} offices={offices} />
                        </Paper>
                    </Route>
                    <Route path="/offices/:id" render={({match}) =>
                        <OfficePage office={offices.get(match.params.id)}/>
                    } />
                </Switch>
            }</OfficesResource>
        }</AuthContext.Consumer>
    );
};

export default withRouter(withStyles(styles)(OfficeListPage));
