import React, {useState} from 'react';
import {FilesResource} from "../../services/Firestore/Resources";
import FilesList from "./FilesList";
import FilesAddForm from './FilesAddForm';
import Modal from "../../components/Modal/Modal";
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";

const FilesPage = ({parent}) => {

    const [openUploadFile, setOpenUploadFile] = useState(null);
    return (
        <div>
            <FilesResource>{files =>
                <>
                    <div style={{textAlign: "left"}}>
                        <Button circle title={"Upload file"} onClick={() => setOpenUploadFile(true)}>
                            <Icon>add</Icon>
                        </Button>
                    </div>
                    <FilesList files={files.filter(f => f.parent === parent)} />
                    <Modal open={openUploadFile} onClose={() => setOpenUploadFile(false)}>
                        <FilesAddForm parent={parent} onSubmit={() => setOpenUploadFile(false)} />
                    </Modal>
                </>
            }</FilesResource>
        </div>
    )
};

export default FilesPage