const pad = (n,width,z) => {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

export const DateToYMD = (date) => { 
    if (!date){
        return ""
    }
    if (!date instanceof Date) {
        return ""
    }
    return date.getFullYear() + "-" + pad(date.getMonth() + 1,2) + "-" + pad(date.getDate(),2)
};

export const FairDate = (date,time) => {
    if (!date instanceof Date) {
        return ""
    }
    var dateString = pad(date.getDate(),2) + "." + pad(date.getMonth() + 1,2) + "." + date.getFullYear()
    if (time) {
        return dateString +" " + pad(date.getHours(),2) + ":" + pad(date.getMinutes(),2) + ":" + pad(date.getSeconds(),2)
    } else {
        return dateString
    }
}