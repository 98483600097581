import React from 'react';
import {Link} from "react-router-dom";

const AdminPage = () => {
    return (
        <div>
            <div><Link to="/offices">Offices</Link></div>
            <div><Link to="/ships">Ships</Link></div>
            <div><Link to="/users">Users</Link></div>
            <div><Link to="/admin/importexport">Import & Export</Link></div>
            <div><Link to="/admin/tasks">Tasks</Link></div>
        </div>
    );
};

export default AdminPage;
