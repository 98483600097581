import withStyles from "react-jss"
import React from "react"

const styles = {
    deleted:{
        textDecoration: "line-through"
    },
    disabled:{
        opacity: 0.6
    }
};

const Entity = ({classes, title, deleted, disabled})=> <span 
    className={[
            deleted && classes.deleted, 
            disabled && classes.disabled
        ].filter(i => !!i).join(' ')}
>{title}</span>


export default withStyles(styles)(Entity)