import React from 'react';
import {DepartmentsResource, ShipsResource, OfficesResource} from '../../services/Firestore/Resources';
import AuthContext from "../../services/AuthContext/AuthContext";
import MyAccount from '../../views/MyAccount/MyAccount'

const MyAccountPage = () => {
    return (
        <AuthContext.Consumer>{currentUser=> currentUser &&
            <DepartmentsResource>{departments => departments &&
                <ShipsResource>{ships => ships &&
                    <OfficesResource>{offices => offices &&
                        <MyAccount {...{currentUser, departments,ships,offices}}/>
                    }</OfficesResource>
                }</ShipsResource>
            }</DepartmentsResource>
        }</AuthContext.Consumer>
    );
};

export default MyAccountPage;
