import React,{useState} from 'react';
import {Link,Route,Switch,withRouter} from "react-router-dom";
import {AssetsResource,ProductsResource,StoragesResource} from "../../services/Firestore/Resources";
import AssetList from "../../views/Asset/AssetList";
import AssetPage from "./AssetPage";
import AssetAddForm from "../../views/Asset/AssetAddForm";
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import Paper from "../../components/Paper/Paper";
import Input from "../../components/Input/Input";
import Modal from "../../components/Modal/Modal";
import withStyles from "react-jss";
import AuthContext from "../../services/AuthContext/AuthContext";

const styles = {
    listContainer: {
        display: "grid",
        gridTemplateColumns: "auto auto",
        gridTemplateRows: "auto auto",
        gridTemplateAreas: '"add search" "list list"',
    },
    add: {
        gridArea: 'add',
        justifySelf: 'self-start',
        marginTop: 10,
        marginLeft: 10,
    },
    search: {
        gridArea: 'search',
        justifySelf: 'self-end',
        marginTop: 10,
        marginLeft: 10,
        display: "grid",
        gridTemplateColumns: "auto auto",
    },
    searchInput: {
        margin: 0,
    },
    list: {
        gridArea: 'list',
    },
    columnFilter: {
        cursor: 'pointer',
        margin: 10,
    }
};

const AssetListPage = ({history,classes}) => {

    const [filter,setFilter] = useState("");
    const [columnFilter,setColumnFilter] = useState(false);

    const handleFilterChange = (e) => {
        setFilter(e.target.value)
    };

    const handleAdd = () => {
        history.push("/assets/");
    };

    const handleAbort = () => {
        history.push("/assets");
    };

    return (
        <AssetsResource>{assets =>
            <ProductsResource>{products => products &&
                <StoragesResource>{storages => storages &&
                    <Switch>
                        <Route exact path="/assets(|/add)">
                            <Route path="/assets/add">
                                <Modal open={true} onClose={handleAbort}>
                                    <AuthContext.Consumer>{user =>
                                        <AssetAddForm onSubmit={handleAdd} storageId={user.home}/>
                                    }</AuthContext.Consumer>
                                </Modal>
                            </Route>
                            <Paper>
                                <div className={classes.listContainer}>
                                    <Link to="/assets/add" className={classes.add}>
                                        <Button circle title="Create asset">
                                            <Icon>add</Icon>
                                        </Button>
                                    </Link>
                                    <div className={classes.search}>
                                        <Input className={classes.searchInput} placeholder={"Search all assets"} onChange={handleFilterChange} />
                                        <div>{
                                            !columnFilter ?
                                                <Icon className={classes.columnFilter} title="Show custom filtering" onClick={() => setColumnFilter(true)}>search</Icon> :
                                                <Icon className={classes.columnFilter} title="Hide custom filtering" onClick={() => setColumnFilter(false)}>search_off</Icon>
                                        }
                                        </div>
                                    </div>
                                </div>
                                <AssetList filter={filter} columnFilter={columnFilter}  {...{assets,products,storages}} />
                            </Paper>
                        </Route>
                        <Route path="/assets/:id" render={({match}) =>
                            <AssetPage asset={assets.get(match.params.id)} />
                        } />
                    </Switch>
                }</StoragesResource>
            }</ProductsResource>
        }</AssetsResource>
    );
};

export default withRouter(withStyles(styles)(AssetListPage));
