import React from 'react';
import withStyles from "react-jss"
import Table from '../../components/Table/Table';
import Row from '../../components/Table/Row/Row';
import Cell from '../../components/Table/Row/Cell/Cell';
import {Link,withRouter} from "react-router-dom";
import Entity from '../../components/Entity/Entity';

const styles = {
    root:{
        display: "grid",
        gridGap: "5px",
    },
};

const DepartmentList = ({classes,departments,parentId}) => {
    departments = parentId ? Object.fromEntries(Object.values(departments).filter(s=>s.parent === parentId).map(v => [v.id, v])) : departments
    return (
        <div className={classes.root}>
            <Table>
                <Row>
                    <Cell header>Name</Cell>
                </Row>
                {departments ? Object.values(departments).map((row)=>(
                        <Row key={row.id}>
                            <Cell>
                                <Link to={"/departments/"+row.id}>
                                    <Entity title={row.name} deleted={row.deleted}/>
                                </Link>
                            </Cell>
                        </Row>
                    ))
                    : <Row><Cell>No rows</Cell></Row>
                }
            </Table>
        </div>
    );
};

export default withRouter(withStyles(styles)(DepartmentList));