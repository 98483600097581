import React,{useEffect,useState} from 'react';
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    root: {},
    content: {
        padding: "10px",
        border: "1px solid var(--first-color)",
        WebkitBoxShadow:
            "inset 0 0 8px  rgba(0,0,0,0.1),0 0 16px rgba(0,0,0,0.1)",
        MozBoxShadow:
            "inset 0 0 8px  rgba(0,0,0,0.1), 0 0 16px rgba(0,0,0,0.1)",
        boxShadow:
            "inset 0 0 8px  rgba(0,0,0,0.1),0 0 16px rgba(0,0,0,0.1)",
        margin: 0,
    },
    tabs: {},
    tabsFull: {
        display: "grid",
        gridTemplateRows: "auto",
        gridAutoFlow: "column"
    }
});

const Tab = React.forwardRef(({stretchTabs,children},ref) => {
    const classes = useStyles();
    const [panes,setPanes] = useState([]);
    const [selectedTab,setSelectedTab] = useState(0);

    useEffect(()=>{
        if(!ref || !ref.current) return;
        ref.current.setSelected = setSelectedTab;
        ref.current.getSelected = ()=>setSelectedTab;
    },[ref]);

    useEffect(() => {
        let c = children;
        if (!c) return;
        if (!Array.isArray(c)) {
            c = [c]
        }
        setPanes(c.map((child,i) => React.cloneElement(child,{
            onClick: ()=>setSelectedTab(i),
            key: i,
            selected: i === selectedTab
        })));
    },[selectedTab,children]);


    return (
        <div ref={ref} className={classes.root}>
            <div className={[classes.tabs,stretchTabs && classes.tabsFull].join(" ")}>{panes}</div>
            <div className={classes.content}>
                {Array.isArray(children) ? children[selectedTab].props.children : children.props.children}
            </div>
        </div>
    );
});

export default Tab;
