import React from 'react';
import {Link} from "react-router-dom";
import withStyles from 'react-jss'
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";

const styles = {
    topBar: {
        gridColumn: "1/3",
        display: "grid",
        gridTemplateColumns: "auto 100px ",
    },
    buttons: {
        textAlign: "right",
        "& > *": {
            marginLeft: 5,
        }
    },
    address: {
        whiteSpace: "pre-wrap",
    }
};

const User = ({classes,user}) => {
    return (
        <div>
            <div className={classes.topBar}>
                <h1>User: {user.name}</h1>
                <div className={classes.buttons}>
                    <Link to={"/users/" + user.id + "/edit"} className={classes.add}>
                        <Button circle title={"Edit " + user.name}>
                            <Icon>edit</Icon>
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default withStyles(styles)(User)