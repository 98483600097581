import React, { Suspense, useState } from 'react';
import Api from "../../services/Api/Api"
import {createUseStyles} from "react-jss";
import Button from "../../components/Button/Button";
import {useParams,useHistory} from "react-router-dom";
import {AssetsCollection, ProductsCollection, useResource} from "../../services/Firestore/Resources";
import {useFlash} from "../../components/Flash/Flash";
import {useLoading} from "../../services/LoadingContext/LoadingContext";

const useQrStyles = createUseStyles({
    main:{
        margin: 0,
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        textAlign: "center",
        width: "100%",
    },
    back:{
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "var(--background-color)"
    }
});

const QRRreaderPage = () => {
    const classes = useQrStyles();
    const { manifestId } = useParams();
    const history = useHistory();
    const flash = useFlash();
    const [scan, setScan] = useState(true);

    const assets = useResource(AssetsCollection);
    const products = useResource(ProductsCollection);

    const QRScanner = React.lazy(() => import("../../components/QRScanner/QRScanner"));

    const handleScan = useLoading(async (code)=>{
        try {
            const prefix = "urn:asset:";
            if (!code.startsWith(prefix)) {
                flash.alert("Invalid QR code");
                return;
            }
            const assetId = code.replace(prefix, "");
            const asset = assets.get(assetId);
            if (!asset) {
                flash.error(`QR code is valid, but no asset found with id = ${assetId}`);
                return;
            }
            const {rental, serialNumber, tagNumber, owner, productId} = assets.get(assetId);
            const {weight, dimensions, name, price} = products.get(productId);
            try {
                await Api.AddManifestItem({
                    manifestId,
                    assetId,
                    quantity: 1,
                    boxNo: 1,
                    rental,
                    serialNumber: (tagNumber ? serialNumber ? "SN:" + serialNumber + " Tag:" + tagNumber : tagNumber : serialNumber),
                    owner,
                    weight,
                    dimensions,
                    description: name,
                    value: price,
                })
            } catch (e) {
                flash.notification(`Scanned asset: ${name} SN: ${serialNumber} ${tagNumber && `TN: ${tagNumber}`}`)
            }
        }finally {
            setScan(false)
        }
    });

    return (assets && products &&
        <>
            <div className={classes.main}>
                {scan ?
                    <Suspense fallback={<progress/>}>
                        <QRScanner onScan={handleScan}/>
                    </Suspense>
                    :
                    <Button style={{width: 200, height: 200}} onClick={()=>setScan(true)}>Scan again</Button>
                }
            </div>
            <div className={classes.back}>
                <Button style={{height: 100, width: "100%"}} onClick={()=>history.push("/manifests/"+manifestId)}>Back to manifest</Button>
            </div>
        </>
    );
};

export default QRRreaderPage;
