import React from 'react';
import Api from "../../services/Api/Api";
import Modal from "../../components/Modal/Modal";
import withStyles from "react-jss";
import {Route, withRouter} from "react-router-dom";
import ExternalEditForm from "../../views/External/ExternalEditForm";
import External from "../../views/External/External";
import {Delete,Restore} from "../../components/Crud/Crud";
import {useErrorHandler} from "../../services/Utils/useErrorHandler";
import {useLoading} from "../../services/LoadingContext/LoadingContext";


const styles = {
    topBar: {
        display: "grid",
        gridTemplateColumns: "auto auto",
    },
    buttons: {
        justifySelf: 'self-end',
        margin: 10,
    },

};

const ExternalsPage = ({match, history, external = {}}) => {

    const errorHandler = useErrorHandler();

    const returnToExternal = () => {
        history.push(match.url);
    };

    const handleDelete = useLoading(async (data) => {
        try {
            await Api.DeleteExternal(data);
            history.push("/externals/" + data.id);
        } catch (e) {
            await errorHandler(e)
        }
    });

    const handleRestore = useLoading(async (data) => {
        try {
            await Api.RestoreExternal(data);
            history.push("/externals/" + data.id);
        } catch (e) {
            await errorHandler(e)
        }
    });

    return (
        <>
            <External external={external} />
            <Route path="/externals/:id/edit" render={() =>
                <Modal open={true} onClose={returnToExternal}>
                    <ExternalEditForm external={external} onUpdate={returnToExternal} />
                </Modal>
            } />
            <Route path="/externals/:id/delete" render={() =>
                <Modal open={true} onClose={returnToExternal}>
                    <Delete data={external} onDelete={handleDelete} onAbort={returnToExternal} />
                </Modal>
            } />
            <Route path="/externals/:id/restore" render={() =>
                <Modal open={true} onClose={returnToExternal}>
                    <Restore data={external} onRestore={handleRestore} onAbort={returnToExternal} />
                </Modal>
            } />
        </>
    );
};

export default withRouter(withStyles(styles)(ExternalsPage));
