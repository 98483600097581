import React from 'react';
import Tab from "../../components/Tab/Tab";
import Pane from "../../components/Tab/Pane/Pane";
import AuthContext,{Admin} from '../../services/AuthContext/AuthContext';
import Export from '../../views/Utils/Export';
import Import from '../../views/Utils/Import';

const ImportExportPage = () => {

    return (
        <AuthContext.Consumer>{currentUser => currentUser.hasRole(Admin) && <>
                <Tab name="util-tab">
                    <Pane title="Import">
                        <Import />
                    </Pane>
                    <Pane title="Export">
                        <Export />
                    </Pane>
                </Tab>
            </>
        }</AuthContext.Consumer>
    );
};

export default ImportExportPage;