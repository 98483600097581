import React,{useState,useRef} from 'react';
import {createUseStyles} from "react-jss";
import Icon from "../Icon/Icon";
import {withFormElementStyle} from "../Form/withFormElementStyle";
import {withValidation} from "../Form/withValidation";

const useCheckboxStyles = createUseStyles({
    root: {
        display: "inline-block",
        cursor: "pointer",
        userSelect: "none",
        font: "400 16px arial",
        color: "var(--first-text-color)",
        padding: 5,
    },
    knob: {
        textAlign: "center",
        width: 20,
        height: 20,
        borderRadius: "20%"
    },
    on: {
        backgroundColor: "var(--second-color)"
    },
    off:{
        backgroundColor: "var(--first-color)"
    },
    icon: {
        fontSize: "unset",
        color: "var(--background-color)"
    },
});

const Checkbox = React.forwardRef(({checked,validation,...props},forwardRef)=>{
    const classes = useCheckboxStyles();
    const [state, setState] = useState(checked);
    const ref = forwardRef || useRef(null);

    const handleClick = ()=>{
        ref.current.click();
        setState(ref.current.checked)
    };

    return <div className={classes.root} onClick={handleClick}>
        <div className={[classes.knob, state ? classes.on : classes.off].join(" ")}>
            <Icon className={classes.icon}>{state ? "check" : "close"}</Icon>
            <input defaultChecked={checked} style={{display: "none"}} ref={ref} type="checkbox" {...props}/>
        </div>
    </div>
});

export default withValidation(withFormElementStyle(Checkbox))