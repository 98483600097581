import React from 'react';
import withStyles from "react-jss"
import Table from '../../components/Table/Table';
import Row from '../../components/Table/Row/Row';
import Cell from '../../components/Table/Row/Cell/Cell';
import {Link,withRouter} from "react-router-dom";
import Entity from '../../components/Entity/Entity';
import {queryObject} from "../../services/Utils/Query";
import AuthContext, {Admin} from "../../services/AuthContext/AuthContext";
import {AssetOwnerType} from '../../constants';

const styles = {
    root:{
        display: "grid",
        gridGap: "5px",
    }
};

const StorageList = ({classes,match,storages,filter}) => {
    return (
        <div className={classes.root}>
            <AuthContext.Consumer>{currentUser => 
            <Table>
                <Row>
                    <Cell header>Name</Cell>
                </Row>
                {storages && Object.values(storages).length > 0
                    ? Object.values(queryObject(storages, filter))
                    .filter(f=> f.parent==="" && (!f.deleted || currentUser.hasRole(Admin)) && f.ownerType !== AssetOwnerType)
                    .map((row)=>(
                        <Row key={row.id}>
                            <Cell>
                                <Link to={"/storages/"+row.id}>
                                    <Entity title={row.name} deleted={row.deleted}/>
                                </Link>
                            </Cell>
                        </Row>
                    ))
                    : <Row><Cell>No rows</Cell></Row>
                }
            </Table>
            }</AuthContext.Consumer>
        </div>
    );
};

export default withRouter(withStyles(styles)(StorageList));
