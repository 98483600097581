import React,{useState} from 'react';
import Asset from "../../views/Asset/Asset";
import {FirestoreResource,ProductResource,FilesResource,FirestoreQuery} from "../../services/Firestore/Resources";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import Api from "../../services/Api/Api";
import Form from "../../components/Form/Form";
import {Route,withRouter} from "react-router-dom";
import Tab from "../../components/Tab/Tab";
import Pane from "../../components/Tab/Pane/Pane";
import StorageSelect from "../../views/Select/StorageSelect";
import {Delete,Restore} from "../../components/Crud/Crud";
import AssetEditForm from "../../views/Asset/AssetEditForm";
import FilesPage from "../../views/Files/FilesPage";
import Modal from "../../components/Modal/Modal";
import EventList from "../../views/Events/EventList";
import * as firebase from "firebase";
import {ConditionOK,ConditionDefect} from "../../constants";
import {DateToYMD} from '../../services/Utils/Date';
import {useFlash} from "../../components/Flash/Flash";
import {useLoading} from "../../services/LoadingContext/LoadingContext";
import {useErrorHandler} from "../../services/Utils/useErrorHandler";

const AssetPage = ({history,match,asset}) => {
    const [,forceUpdate] = useState();
    const [calibrationDate,setCalibrationDate] = useState(asset && asset.calibrationDate && asset.calibrationDate.toDate());
    const [storageId,setStorageId] = useState(asset && asset.storageId);
    const [condition,setCondition] = useState(asset && asset.condition);
    const flash = useFlash();
    const errorHandler = useErrorHandler();

    const handleCalibrateAsset = useLoading(async (e) => {
        e.preventDefault();
        if (asset.calibrationDate === null || asset.calibrationDate.toMillis() !== calibrationDate.getTime()) {
            try {
                await Api.CalibrateAsset({
                    id: asset.id,
                    calibrationDate: calibrationDate.toJSON(),
                });
                forceUpdate(); //TODO this really necessary?
                flash.notification(<p>Calibration updated</p>)
            } catch (e) {
                await errorHandler(e)
            }
        } else {
            flash.alert(<p>Please change the date first</p>)
        }
    });

    const handleMove = useLoading(async (e) => {
        e.preventDefault();
        if (asset.storageId !== storageId) {
            try {
               await Api.MoveAsset({
                    id: asset.id,
                    storageId: storageId,
                });
                history.push(match.url);
                flash.notification(<p>Asset moved</p>)
            } catch (e) {
                await errorHandler(e)
            }
        } else {
            flash.alert(<p>Please select a new storage location before clicking Move</p>)
        }
    });

    const handleSetCondition = useLoading(async (e) => {
        const op = condition === ConditionOK ? ConditionDefect : ConditionOK;
        try {
            await Api.SetAssetCondition({
                id: asset.id,
                condition: op,
            });
            setCondition(op);
            history.push(match.url);
            flash.notification(<p>condition changed</p>)
        } catch (e) {
            await errorHandler(e)
        }
    });

    const handleDelete = useLoading(async (data) => {
        try {
            await Api.DeleteAsset(data);
            history.push(match.url);
        } catch (e) {
            await errorHandler(e)
        }
    });

    const handleRestore = useLoading(async (data) => {
        try {
            await Api.RestoreAsset(data);
            history.push(match.url);
        } catch (e) {
            await errorHandler(e)
        }
    });

    const returnToAsset = () => {
        history.push(match.url);
    };

    if(!asset) return("Asset not found");

    return (
        <ProductResource id={asset.productId}>{product =>
            <FilesResource>{files =>
                <>
                    <Tab name="asset-tab">
                        <Pane title="Asset">
                            <Asset asset={asset} product={product} />
                        </Pane>
                        <Pane title="Actions">
                            <h1>Set new calibration</h1>
                            <Form onSubmit={handleCalibrateAsset}>
                                <Input required title="Calibration date" type="date" defaultValue={DateToYMD(calibrationDate)} onChange={e => setCalibrationDate(new Date(e.target.value))} />
                                <Button type="submit">Set new calibration date</Button>
                            </Form>
                            <h1>Move asset</h1>
                            <Form autoComplete="off" onSubmit={handleMove}>
                                <StorageSelect value={storageId} filter={(s)=>s.id !== asset.id} onChange={({value}) => setStorageId(value)} />
                                <Button type="submit">Move</Button>
                            </Form>
                            <h1>Condition</h1>
                            <Button type="button" onClick={handleSetCondition}>Change to {condition ? "operational": "needs service"}</Button>
                        </Pane>
                        <Pane title={"Files (" + files.filter(f => f.parent === asset.id).length + ")"}>
                            <FilesPage parent={asset.id} />
                        </Pane>
                        <Pane title="Events">
                            <FirestoreResource query={FirestoreQuery.get(
                                firebase.firestore()
                                    .collection("events")
                                    .where('AggregateID','==',asset.id)
                                    .limit(250)
                            )}>{events =>
                                <EventList events={events} />
                                }</FirestoreResource>
                        </Pane>
                    </Tab>
                    <Route path="/assets/:id/edit" render={() =>
                        <Modal open={true} onClose={returnToAsset}>
                            <AssetEditForm asset={asset} product={product} onUpdate={returnToAsset} />
                        </Modal>
                    } />
                    <Route path="/assets/:id/delete" render={() =>
                        <Modal open={true} onClose={returnToAsset}>
                            <Delete data={asset} onDelete={handleDelete} onAbort={returnToAsset} />
                        </Modal>
                    } />
                    <Route path="/assets/:id/restore" render={() =>
                        <Modal open={true} onClose={returnToAsset}>
                            <Restore data={asset} onRestore={handleRestore} onAbort={returnToAsset} />
                        </Modal>
                    } />
                </>
            }</FilesResource>
        }</ProductResource>
    );
};

export default withRouter(AssetPage);
