import React from 'react';
import {withFormElementStyle} from "../Form/withFormElementStyle";
import {withValidation} from "../Form/withValidation";

const TextArea = React.forwardRef(({children,...props},ref)=>{
    return (
            <textarea ref={ref} {...props}>{children}</textarea>
    );
});

export default withValidation(withFormElementStyle(TextArea));
