import React, {useState} from 'react';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import Api from '../../services/Api/Api';
import Form from "../../components/Form/Form";
import {useLoading} from "../../services/LoadingContext/LoadingContext";
import {useErrorHandler} from "../../services/Utils/useErrorHandler";


const FilesAddForm = ({onSubmit, parent}) => {

    if (!parent) throw Error("no parent file owner defined");

    const [files, setFiles] = useState([]);
    const errorHandler = useErrorHandler();
    
    const handleSubmit = useLoading(async (e) => {
        e.preventDefault();
        for (let i = 0; i < files.length; i++) {
            try {
                await Api.AddAssetFile({id: parent, file: files[i]})
            } catch (e) {
                await errorHandler(e)
            }
        }
        onSubmit && onSubmit();
    });

    return (
        <Form autoComplete="off" onSubmit={handleSubmit}>
            <Input type="file" onChange={(e) => setFiles(e.target.files)} multiple />
            <Button type="submit">Upload files</Button>
        </Form>
    );
};

export default FilesAddForm;