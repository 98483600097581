import React,{useEffect,useState,useContext} from "react"
import Icon from "../Icon/Icon";
import {createUseStyles} from "react-jss";
import Observable from "../../services/Observable/Observable";

class Flasher extends Observable {
    flashes = {};
    custom(component,{...props}){
        const key = Date.now().toString(36);
        let f;
        f = <Flash {...props} onClose={()=>{
            delete(this.flashes[key]);
            this.notify();
        }}>{component}</Flash>;
        this.flashes[key] = {id: key, component: f};
        this.notify();
    }
    notification(component){
        this.custom(component,{type:Notification, duration: 5000});
    }
    alert(component){
        this.custom(component,{type:Alert, duration: 10000});
    }
    error(component){
        this.custom(component,{type:Error, duration: 0});
    }
    notify() {
        super.notify(Object.values(this.flashes));
    }
}

const FlashContext = React.createContext(new Flasher());

export const useFlash = ()=>{
    return useContext(FlashContext);
};

const useFlashStyles = createUseStyles({
    root: {
        display: "none",
        padding: 5,
        opacity: 0,
        top: 0,
        left: 0,
        right: 0,
        gridTemplateColumns: "auto min-content",
    },
    notification:{
        backgroundColor: "var(--alert-notification-backgroundColor)",
        border: "1px solid var(--alert-notification-borderColor)",
        color: "1px solid var(--alert-notification-color)"
    },
    alert: {
        backgroundColor: "var(--alert-warning-backgroundColor)",
        border: "1px solid var(--alert-warning-borderColor)",
        color: "1px solid var(--alert-warning-color)"
    },
    error: {
        backgroundColor: "var(--alert-danger-backgroundColor)",
        border: "1px solid var(--alert-danger-borderColor)",
        color: "1px solid var(--alert-danger-color)"
    },
    slideDown: {
        display: "grid",
        animationName: '$slideDown',
        animationDuration: '0.5s',
        animationTimingFunction: 'ease-out',
        animationFillMode: "forwards",
    },
    slideUp: {
        display: "grid",
        animationName: '$slideUp',
        animationDuration: '0.5s',
        animationTimingFunction: 'ease-in',
        animationFillMode: "forwards",
    },

    '@keyframes slideDown':{
        from:{
            opacity: 0,
            transform: "translateY(-100%)",
        },
        to:{
            opacity: 1,
            transform: "translateY(0%)",
        },
    },

    '@keyframes slideUp':{
        from: {
            opacity: 1,
            transform: "translateY(0%)",
        },
        to: {
            opacity: 0,
            transform: "translateY(-100%)",
        },
    }
});

export const Flash = ({children,type=Notification, duration=0, onClose})=>{
    const classes = useFlashStyles();
    const [animation, setAnimation] = useState("");
    const [closed, setClosed] = useState(undefined);

    const handleClose = ()=>{
        setClosed(true)
    };

    useEffect(()=>{
        if(!closed) return;
        setTimeout(()=>{
            onClose && onClose()
        },500) // should match animation slideUp duration
    },[closed,onClose]);

    useEffect(() => {
        if(!children) return;
        setAnimation(closed ? classes.slideUp : classes.slideDown);
    }, [children,closed,classes]);

    useEffect(() => {
        if(!children) return;
        setClosed(false);
        if (duration > 0) {
            let handler;
            handler = setTimeout(() => {
                setClosed(true)
            }, duration);
            return () => clearTimeout(handler)
        }
    }, [duration,children]);

    return <div className={[classes.root,classes[type],animation].join(" ").trim()}>
        <div>{children}</div>
        <Icon style={{cursor: "pointer"}} onClick={handleClose}>close</Icon>
    </div>
};

export const FlashContainer = ({...props})=>{
    const flasher = useContext(FlashContext);
    const [flashes,setFlashes] = useState([]);

    useEffect(() => {
        flasher.subscribe(setFlashes)
    }, [flasher]);


    return (
        <div {...props}>
            {flashes.map(f=><div key={f.id}>{f.component}</div>)}
        </div>
    )
};

export const Notification = "notification";
export const Alert = "alert";
export const Error = "error";