import React,{useEffect,useRef} from 'react';


const QRPage = ({data,window}) => {
    const ref = useRef(null);

    const drawImage = (ctx,src,x,y)=>{
        const img = new Image();
        img.onload = (e)=>{
            ctx.drawImage(e.target,x,y)
        };
        img.src = src;
    };

    const drawTextCentered = (ctx,txt,y)=>{
        ctx.font = '900 40pt Courier New';
        ctx.fillStyle = "#000";
        ctx.textAlign = "center";
        const measured = ctx.measureText(txt);
        ctx.fillText(txt,ctx.canvas.width/2,measured.actualBoundingBoxAscent+y,ctx.canvas.width)
    };

    useEffect(() => {
        if(!ref.current){
            return;
        }
        const canvas = ref.current;
        const ctx = canvas.getContext("2d");

        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        drawImage(ctx,data.logo,0,0);
        drawImage(ctx,data.qr,0,120);
        drawTextCentered(ctx, data.name, 120+900+3);
        drawTextCentered(ctx, "SN:"+data.sn, 120+900+63);
        if(data.tag){
            drawTextCentered(ctx, "Tag:"+data.tag, 125+900+123);
        }
        canvas.toBlob(window.print)

    }, [window,data,ref]);

    return <>
        <style dangerouslySetInnerHTML={{__html:`
            canvas{
                width: 100%;
            }
            @media print {
                @page {
                    size: 3in 4in; /* not supported by browsers */ 
                }
            }
        `}} type={"text/css"}/>
        <canvas ref={ref} width={900} height={1200}/>
    </>

};

export default QRPage;
