import React from "react"
import withStyles from 'react-jss'

const styles = {
    root: {
        display: "grid",
        cursor: "pointer",
        alignItems: "center",
    },
    content:{
        position: "relative",
        "&:hover $popover":{
            animationDuration: "0.2s",
            animationName: "$fadeIn",
            animationPlayState: "running",
            animationDirection: "forward",
            animationFillMode: "forwards",
        },
    },
    popover: {
        animation: "none", 
        transform: "scale(0)",
        opacity: 0,
        animationPlayState: "pause",
        position: "absolute",
        backgroundColor: "var(--background-color)",
        boxShadow: "1px 1px 2px 2px rgba(0,0,0,0.15)",
        padding: "5px",
        zIndex:999,
        "&.left":{
            left: 0,
        },
        "&.right":{
            right: 0,
        }
    },
    '@keyframes fadeIn': {
        from: {
            transform: "scale(0)",
            opacity: 0,
        },
        to: {
            transform: "scale(1)",
            opacity: 1,
        }
    }
};

const Popover = ({classes,className, children,position = "left",...other})=>{
    return (<div className={[classes.root,className].filter(v=>v).join(" ")} {...other}>
        <div className={classes.content}>
            {children[0]}
            <div className={classes.popover +" "+ position}>{children[1]}</div>
        </div>
    </div>)
};

export default withStyles(styles)(Popover);