import React, {useState, useEffect, useCallback, useRef} from 'react';
import { renderToString } from 'react-dom/server'
import {createUseStyles} from 'react-jss'
import {withFormElementStyle} from "../Form/withFormElementStyle";
import {withValidation} from "../Form/withValidation";

const useSelectStyles = createUseStyles({
    root: {
        display: 'block',
        position: 'relative',
    },
    input: {
        width: "100%",
    },
    children: {
        position: 'absolute',
        left: 0,
        zIndex: 1000,
        maxHeight: "250px",
        overflow: "auto",
        backgroundColor: "var(--background-color)",
        "& > *:hover":{
            backgroundColor: "var(--fifth-color)"
        },
        border: "5px solid var(--fourth-color)",
        WebkitBoxShadow:
            "inset 0 0 8px  rgba(0,0,0,0.1), 0 0 16px rgba(0,0,0,0.1)",
        MozBoxShadow:
            "inset 0 0 8px  rgba(0,0,0,0.1), 0 0 16px rgba(0,0,0,0.1)",
        boxShadow:
            "inset 0 0 8px  rgba(0,0,0,0.1), 0 0 16px rgba(0,0,0,0.1)",
    },
    childrenAbove: {
        bottom: 0,
    }
});

const innerText = (component)=>{
    const el = document.createElement("div");
    el.innerHTML = renderToString(component);
    return el.innerText
};

const compare = (a,b,comparator = (a,b)=> a === b) => comparator(a,b);

const optionByValue = (value,children,comparator)=> {
    for (let child of React.Children.toArray(children)) {
        let childValue = child.props.value !== undefined ? child.props.value : child.props.children;
        if (compare(childValue,value,comparator)){
            return child
        }
    }
    return null;
};

const Select = React.forwardRef(({children,value,comparator,onChange,...other},ref)=>{
    const classes = useSelectStyles();
    const [selected, setSelected] = useState({value:value,label:""});
    const [filter, setFilter] = useState('');
    const [hasFocus, setHasFocus] = useState(false);
    const [showAbove, setShowAbove] = useState(false);
    const dropdownRef = useRef(null);

    const handleSelect = useCallback((value)=>{
        let child = optionByValue(value,children,comparator);
        if (!child){
            return;
        }
        let label = innerText(child);
        let selected = {value,label};
        setSelected(selected);
        ref.current.value = label;
        onChange && onChange(selected);
        setFilter('');
    },[ref,children,comparator,onChange]);

    useEffect(() => {
        // If component has focus, adjust dropdown positioning
        if (hasFocus) {
            const rect = ref.current.getBoundingClientRect();
            const dropdownHeight = dropdownRef.current.offsetHeight;
            const spaceBelow = window.innerHeight - rect.bottom;

            // If space below input is less than 250px (or dropdown's maxHeight), show dropdown above the input
            if (spaceBelow < dropdownHeight) {
                setShowAbove(true);
            } else {
                setShowAbove(false);
            }
        }
    }, [hasFocus, ref, dropdownRef]);

    useEffect(() => {
        handleSelect(value)
    }, [value,handleSelect]);


    let child = optionByValue(selected.value,children,comparator);
    if (child){
        selected.label = innerText(child);
    }

    const handleFocus = ()=>{
        setHasFocus(true);
        ref.current.value = filter;
    };

    const handleBlur = ()=>{
        setHasFocus(false);
    };

    const filteredChildren = React.Children.map(children,(child)=>{
        return innerText(child).toLowerCase().indexOf(filter.toLowerCase()) !== -1 && React.cloneElement(child,{onSelect: handleSelect, selected: compare(selected.value,child.props.value,comparator)})
    }).filter(c => !!c);


    const {required,disabled,title,...props} = other;
    return (
        <div className={classes.root} onFocus={handleFocus} onBlur={handleBlur}>
            <input
                className={classes.input}
                ref={ref}
                required={required}
                disabled={disabled}
                value={hasFocus ? filter : selected.label}
                onChange={(e)=>{setFilter(e.target.value)}}
                {...props}
            />
            {hasFocus && filteredChildren.length > 0 &&
                <div ref={dropdownRef} className={`${classes.children} ${showAbove ? classes.childrenAbove : ''}`}>
                    {filteredChildren}
                </div>
            }
        </div>
    );
});

export default withValidation(withFormElementStyle(Select));
