import React,{useEffect,useState} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import MainPage from "./MainPage";
import * as firebase from "firebase";
import Login from "../views/Login/Login";
import ProgressBar from "../components/ProgressBar/ProgressBar";
import {UserResource} from "../services/Firestore/Resources";
import ErrorBoundary from "../components/ErrorBoundary/ErrorBoundary";
import AuthContext, {User} from "../services/AuthContext/AuthContext";
import {LoadingContextConsumer} from "../services/LoadingContext/LoadingContext";
import Logout from "../views/Logout/Logout";
import QRReaderPage from "./QR/QRReaderPage";
import {FlashContainer} from "../components/Flash/Flash";

const App = ()=>{
    const [loggedIn, setLoggedIn] = useState(null);
    const [maintenance, setMaintenance] = useState(false);

    useEffect(() => {
        return firebase.auth().onAuthStateChanged(user=>{
            setLoggedIn({user})
        });
    }, []);

    useEffect(()=>{
        if(!(loggedIn && loggedIn.user)){
            return;
        }
        return firebase.firestore().doc("global/maintenance").onSnapshot(doc=>setMaintenance(doc.exists))
    },[loggedIn]);

    if (loggedIn === null) return <ProgressBar/>;
    if (!loggedIn.user || maintenance) return <Login/>;
    return(
        <UserResource id={loggedIn.user.uid}>{user => {
            return <AuthContext.Provider value={new User(user)}>
                <MainPage/>
            </AuthContext.Provider>
        }}</UserResource>

    )
};

export default () => {
    return (
        <ErrorBoundary>
            <Router>
                <LoadingContextConsumer>{ loading => {
                    return <ProgressBar style={{display: loading ? 'unset' : 'none', position: "fixed", zIndex: 10000}}/>
                }}
                </LoadingContextConsumer>
                <FlashContainer style={{position: "fixed", top: 0, left: 0, right: 0, zIndex: 10001}}/>
                <Switch>
                    <Route path="/logout">
                        <Logout/>
                    </Route>
                    <Route path="/manifests/:manifestId/scan">
                        <QRReaderPage/>
                    </Route>
                    <Route>
                        <App/>
                    </Route>
                </Switch>
            </Router>
        </ErrorBoundary>
    );
};
