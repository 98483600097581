import React from 'react';
import Option from "../../components/Select/Option/Option";
import CollectionSelect from "../../components/CollectionSelect/CollectionSelect";
import {StoragesResource} from "../../services/Firestore/Resources";
import {StorageTitle} from "../../components/Title/StorageTitle/StorageTitle";

const StorageSelect = ({filter,...props}) => {
    const sort = (a,b) => {
        return a.lineageNames.toString().toLowerCase() > b.lineageNames.toString().toLowerCase() ? 1 : -1;
    };
    const map = storage => <Option key={storage.id} value={storage.id}><StorageTitle full={true} {...storage}/></Option>;

    return (
        <StoragesResource>{storages => storages &&
            <CollectionSelect values={storages} filter={(s=>{
                if (s.deleted){
                    return false;
                }
                if (filter){
                    return filter(s)
                }
                return true;
            })} title={"Storage"} sort={sort} map={map} {...props}/>
        }</StoragesResource>
    )
};

export default StorageSelect;