import React from "react"
import withStyles from 'react-jss'

const styles = {
    cell:{
        borderBottom: "1px solid var(--first-color)",
        padding: "14px 40px 14px 16px",
    },
    data: {
        
    },
    header: {
        color: "var(--third-color)",
        textAlign: 'left',
        verticalAlign: "top",
    },
};

const Cell = ({classes,header,children,className,...other})=>{
    if (!!header){
        return <th className={[classes.cell,classes.header,className].filter(v=>v).join(" ")} {...other}>{children}</th>
    }
    return (
       <td className={[classes.cell,classes.data,className].filter(v=>v).join(" ")} {...other}>{children}</td>
    )
};

export default withStyles(styles)(Cell);