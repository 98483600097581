import React from 'react';
import AddressableTitle from "../../components/Title/AddressableTitle/AddressableTitle";
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import {Link} from "react-router-dom";
import withStyles from 'react-jss'
import AuthContext,{Admin} from "../../services/AuthContext/AuthContext";

const styles = {
    topBar: {
        gridColumn: "1/3",
        display: "grid",
        gridTemplateColumns: "auto 100px ",
    },
    buttons: {
        textAlign: "right",
        "& > *": {
            marginLeft: 5,
        }
    },
};

const Department = ({classes,department}) => {

    return (
        <div>
            <div className={classes.topBar}>
                <h2>Department: {department.name}</h2>
                <AuthContext.Consumer>{user => user &&
                    <div className={classes.buttons}>
                        {user.hasRole(Admin) && <>
                            <Link to={"/departments/" + department.id + "/edit"} className={classes.add}>
                                <Button circle title={"Edit " + department.name}>
                                    <Icon>edit</Icon>
                                </Button>
                            </Link>
                            {!department.deleted ?
                                <Link to={"/departments/" + department.id + "/delete"} className={classes.add}>
                                    <Button circle title={"Delete " + department.name}>
                                        <Icon>delete</Icon>
                                    </Button>
                                </Link> :
                                <Link to={"/departments/" + department.id + "/restore"} className={classes.add}>
                                    <Button circle title={"Restore " + department.name}>
                                        <Icon>restore</Icon>
                                    </Button>
                                </Link>
                            }
                        </>}
                    </div>
                }</AuthContext.Consumer>
                <div>This department belongs to <AddressableTitle id={department.parent} />  </div>
            </div>
        </div>
    );
};


export default withStyles(styles)(Department)