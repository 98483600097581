import React from 'react';
import {NavLink, Route, Switch, withRouter} from "react-router-dom";
import withStyles from 'react-jss'
import Input from "../components/Input/Input";
import Icon from "../components/Icon/Icon";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import AssetListPage from "./Asset/AssetListPage";
import ManifestListPage from "./Manifest/ManifestListPage";
import ExternalListPage from "./Externals/ExternalListPage";
import AdminPage from "./Admin/AdminPage";
import MyAccountPage from "./MyAccount/MyAccountPage";
import HomePage from "./Home/HomePage";
import EventsPage from "./Events/EventsPage";
import ProductListPage from "./Product/ProductListPage";
import StorageListPage from "./Storage/StorageListPage";
import OfficeListPage from "./Office/OfficeListPage";
import DepartmentListPage from "./Department/DepartmentListPage";
import ShipListPage from "./Ship/ShipListPage";
import AuthContext, {Admin} from "../services/AuthContext/AuthContext";
import AddressableTitle from "../components/Title/AddressableTitle/AddressableTitle";
import UserListPage from "./User/UserListPage";
import ImportExportPage from './ImportExport/ImportExportPage';
import TasksPage from "./Tasks/TasksPage";

const styles = {
    root: {
        minHeight: "100vh",
        maxWidth: "100vw",
        display: "grid",
        gridTemplateColumns: "80px auto",
        gridTemplateRows: "70px auto",
        gridTemplateAreas: '"top-bar top-bar" "menu main"',
    },
    menu: {
        gridArea: "menu",
        backgroundColor: "var(--menu-background1-color)",
        "& > nav > ul":{
            margin: 0,
            padding: 0,
          "& > li":{
              listStyleType:"none",
              "& > a":{
                  fontSize: "16px",
                  display: "block",
                  lineHeight: "2em",
                  padding: "0.5em",
                  textDecoration: "none",
                  textAlign: "center",
                  color: "var(--menu-text-color)"
              },
              "& > a.active":{
                  backgroundColor: "var(--menu-background2-color)",
                  color: "var(--menu-text2-color)"
              },
              "& > a:hover":{
                  //opacity: 0.5,
                  backgroundColor: "var(--menu-background2-color)",
                  color: "var(--menu-text2-color)"
              }
          }
        },
    },
    topBar: {
        display: "grid",
        gridTemplateColumns: "auto auto auto",
        gridTemplateRows: "auto",
        gridTemplateAreas: '"breadcrumb search account"',
        gridArea: "top-bar",
        backgroundColor: "var(--menu-background1-color)"
    },
    main: {
        gridArea: "main",
        padding: 20,
    },
    search: {
        gridArea: "search",
        alignSelf:"center",
        display: "none", //no search yet
    },
    breadcrumb: {
        gridArea: "breadcrumb",
        alignSelf:"center",
        justifySelf: "self-start",
        display: "none", //no breadcrumb either
    },
    account: {
        gridArea: "account",
        alignSelf: "center",
        justifySelf: "self-end",
        paddingRight: 10,
        color: "var(--menu-text-color)"
    },
    searchInput:{
        backgroundColor: "var(--background-color)"
    },
    menuIcon:{
        fontSize: "30px",
        verticalAlign: "bottom",
        margin: "0px 15px"
    },
    userTitle:{
        paddingRight: "15px",
    }
};

const MainPage = ({classes,location}) => {
    
    return (
        <AuthContext.Consumer>{user =>
        <div className={classes.root}>
            <div className={classes.menu}>
                <nav>
                    <ul>
                        <li>
                            <NavLink exact to="/" activeClassName='active'>
                                <Icon className={classes.menuIcon}>home</Icon>
                                Home
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/assets" activeClassName='active'>
                                <Icon className={classes.menuIcon}>web_asset</Icon>
                                Assets
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/manifests" activeClassName='active'>
                                <Icon className={classes.menuIcon}>description</Icon>
                                Manifests
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/products" activeClassName='active'>
                                <Icon className={classes.menuIcon}>build</Icon>
                                Models
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/externals" activeClassName='active'>
                                <Icon className={classes.menuIcon}>contacts</Icon>
                                Externals
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/storages" activeClassName='active'>
                                <Icon className={classes.menuIcon}>kitchen</Icon>
                                Storages
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/events" activeClassName='active'>
                                <Icon className={classes.menuIcon}>event_note</Icon>
                                Events
                            </NavLink>
                        </li>
                        {user.hasRole(Admin) &&
                            <li>
                                <NavLink to="/admin" activeClassName='active'>
                                    <Icon className={classes.menuIcon}>security</Icon>
                                    Admin
                                </NavLink>
                            </li>
                        }
                        <li>
                            <NavLink to="/my-account" activeClassName='active'>
                                <Icon className={classes.menuIcon}>account_circle</Icon>
                                Account
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/logout" activeClassName='active'>
                                <Icon className={classes.menuIcon}>exit_to_app</Icon>
                                Logout
                            </NavLink>
                        </li>
                    </ul>
                </nav>
            </div>
            <div className={classes.topBar}>
                <div className={classes.breadcrumb}><Breadcrumb path={location.pathname}/></div>
                <div className={classes.search}><Input placeholder="Search" className={classes.searchInput}/></div>
                <div className={classes.account}>
                    <span className={classes.userTitle}>
                        {user.name ? user.name : user.email} <br/> <AddressableTitle id={user.home}/>
                    </span>
                </div>
            </div>
            <div className={classes.main}>
                <Switch>
                    <Route path="/assets">
                        <AssetListPage/>
                    </Route>
                    <Route path="/manifests">
                        <ManifestListPage/>
                    </Route>
                    <Route path="/products">
                        <ProductListPage/>
                    </Route>
                    <Route path="/externals">
                        <ExternalListPage/>
                    </Route>
                    <Route path="/storages">
                        <StorageListPage/>
                    </Route>
                    <Route path="/events">
                        <EventsPage/>
                    </Route>
                    <Route path="/departments">
                        <DepartmentListPage/>
                    </Route>
                    <Route path="/my-account">
                        <MyAccountPage/>
                    </Route>
                    <Route path="/users">
                        <UserListPage/>
                    </Route>
                    <Route path="/ships">
                        <ShipListPage/>
                    </Route>
                    <Route path="/offices">
                        <OfficeListPage/>
                    </Route>
                    <Route path="/admin/importexport">
                        <ImportExportPage/>
                    </Route>
                    <Route path="/admin/tasks">
                        <TasksPage/>
                    </Route>
                    <Route path="/admin">
                        <AdminPage/>
                    </Route>
                    <Route path="/">
                        <HomePage/>
                    </Route>
                </Switch>
            </div>
        </div>
    }</AuthContext.Consumer>
    );
};

export default withStyles(styles)(withRouter(MainPage));
