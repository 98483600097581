import React,{useState} from 'react';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import AddressableSelect from "../Select/AddressableSelect";
import Textarea from '../../components/Textarea/Textarea';
import withStyles from 'react-jss'
import Api from "../../services/Api/Api";
import Form from "../../components/Form/Form";
import {useLoading} from "../../services/LoadingContext/LoadingContext";
import {useErrorHandler} from "../../services/Utils/useErrorHandler";
import {useAddressables} from "../../services/Firestore/Resources";
import {useCurrentUser} from "../../services/AuthContext/AuthContext";

const styles = {
    split: {
        display: "grid",
        gridTemplateColumns: "50% 50%",
    },
    doubleRow: {
        gridColumn: "1 / span 2",
    },
};

const ManifestAddForm = ({ classes, onSubmit }) => {
    const currentUser = useCurrentUser()
    const [addressables, loading] = useAddressables();
    const [name,setName] = useState(null);
    const [project,setProject] = useState(null);
    const [reference, setReference] = useState(currentUser && !loading && currentUser.name);
    const [recipientAddress,setRecipientAddress] = useState(null);
    const [senderId,setSenderId] = useState(currentUser && !loading && currentUser.home);
    const [senderAddress,setSenderAddress] = useState(currentUser && !loading && getAddress(currentUser.home));
    const [recipientId,setRecipientId] = useState(null);
    const [additionalNotes,setAdditionalNotes] = useState(null);
    const errorHandler = useErrorHandler();

    const handleSubmit = useLoading(async (e) => {
        e.preventDefault();
        try {
            const data = await Api.CreateManifest({
                name,
                project,
                reference,
                recipientAddress,
                senderAddress,
                recipientId,
                senderId,
                additionalNotes,
            });
            onSubmit && onSubmit(data);
        } catch (e) {
            await errorHandler(e)
        }
    });

    const handleSelectRecipient = ({value,label}) => {
        setRecipientId(value.id);
        setRecipientAddress(getAddress(value.id));
    };

    const handleSelectSender = ({value,label}) => {
        setSenderId(value.id);
        setSenderAddress(getAddress(value.id))
    };

    function getAddress(id) {
        if(id){
            let place = addressables[id]
            if (place.parent) {
                return (addressables[place.parent].address)
            } else {
                return (place.address)
            }
        }        
    }

    if (loading){
        return "Loading..."
    }

    return (
        <Form className={classes.split} onSubmit={handleSubmit}>
            <Input className={classes.doubleRow} title="Manifest title" required onChange={e => setName(e.target.value)} />
            <Input title="Project" onChange={e => setProject(e.target.value)} />
            <Input title="Our reference" value={reference} onChange={e => setReference(e.target.value)}/>
            <AddressableSelect title="Sender" required value={senderId} onChange={handleSelectSender} />
            <AddressableSelect title="Recipient" required value={recipientId} onChange={handleSelectRecipient} />
            <Textarea className={classes.doubleRow} required maxLength="200" rows="5" cols="15" title="Additional notes" onChange={e => setAdditionalNotes(e.target.value)} />
            <div><Button type="submit">Add</Button></div>
        </Form>
    );
};

export default withStyles(styles)(ManifestAddForm)
