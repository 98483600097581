import React,{useEffect,useState} from 'react';
import {Redirect} from "react-router-dom";

const DelayedRedirect = ({to,children,duration = 3000,step = 1000}) => {
    const [countdown,setCountdown] = useState(duration);

    useEffect(() => {
        let handler;
        if(typeof children === "function"){
            handler = setInterval(() => {
                setCountdown(value => value - step)
            },step);
            return ()=>clearInterval(handler);
        }

        handler = setTimeout(() => {
            setCountdown(0)
        },duration);
        return ()=>clearInterval(handler);
    },[children,duration,step]);

    if (countdown <= 0) return <Redirect to={to} />;
    if(typeof children === "function") return children(countdown);
    return children;
};

export default DelayedRedirect;
