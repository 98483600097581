import React,{useState} from 'react';
import {Link} from "react-router-dom";
import withStyles from 'react-jss'
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import DepartmentList from '../Department/DepartmentList';
import {ShipType} from "../../constants";
import {DepartmentsResource} from '../../services/Firestore/Resources';
import DepartmentAddForm from '../Department/DepartmentAddForm';
import Modal from "../../components/Modal/Modal";
import AuthContext,{Admin} from "../../services/AuthContext/AuthContext";

const styles = {
    topBar: {
        gridColumn: "1/3",
        display: "grid",
        gridTemplateColumns: "auto 100px ",
    },
    buttons: {
        textAlign: "right",
        "& > *": {
            marginLeft: 5,
        }
    },
    address: {
        whiteSpace: "pre-wrap",
    }
};

const Ship = ({classes,ship}) => {

    const [openDeparmentCreate,setOpenDeparmentCreate] = useState(false);

    const handleCloseDepartmentCreate = () => {
        setOpenDeparmentCreate(false)
    };

    const handleOpenDepartmentCreate = () => {
        setOpenDeparmentCreate(true)
    };

    return (
        <AuthContext.Consumer>{user => user &&
            <div>
                <div className={classes.topBar}>
                    {ship.deleted ?
                        <h1 style={{textDecoration: "line-through"}}>{ship.name} - this ship is deleted</h1>
                        : <h1>{ship.name}</h1>
                    }
                    <div className={classes.buttons}>
                        {user.hasRole(Admin) && <>
                            <Link to={"/ships/" + ship.id + "/edit"} className={classes.add}>
                                <Button circle title={"Edit " + ship.name}>
                                    <Icon>edit</Icon>
                                </Button>
                            </Link>
                            {!ship.deleted ?
                                <Link to={"/ships/" + ship.id + "/delete"} className={classes.add}>
                                    <Button circle title={"Delete " + ship.name}>
                                        <Icon>delete</Icon>
                                    </Button>
                                </Link> :
                                <Link to={"/ships/" + ship.id + "/restore"} className={classes.add}>
                                    <Button circle title={"Restore " + ship.name}>
                                        <Icon>restore</Icon>
                                    </Button>
                                </Link>}
                        </>}
                    </div>
                </div>
                <div className={classes.address}>{ship.address}</div>
                <Link to={"../storages/" + ship.id}><h3>Go to {ship.name} storage</h3></Link>
                <div className={classes.topBar}>
                    <h1>Departments:</h1>
                    <div className={classes.buttons}>
                        {user.hasRole(Admin) &&
                            <Button onClick={handleOpenDepartmentCreate} circle title="Create new department">
                                <Icon>add</Icon>
                            </Button>
                        }
                    </div>
                </div>
                <DepartmentsResource>{departments => departments &&
                    <DepartmentList departments={departments} parentId={ship.id} />
                }</DepartmentsResource>
                <Modal open={openDeparmentCreate} onClose={handleCloseDepartmentCreate}>
                    <DepartmentAddForm onSubmit={handleCloseDepartmentCreate} parent={ship.id} parentType={ShipType} />
                </Modal>
            </div>
        }</AuthContext.Consumer>
    );
};

export default withStyles(styles)(Ship)