import React,{useState} from 'react';
import Api from "../../services/Api/Api";
import {useLoading} from "../../services/LoadingContext/LoadingContext";
import Button from "../../components/Button/Button";
import Form from "../../components/Form/Form";
import Input from '../../components/Input/Input';
import {useHistory} from "react-router-dom"
import {useErrorHandler} from "../../services/Utils/useErrorHandler";

const Import = () => {

    const history = useHistory();
    const errorHandler = useErrorHandler();
    const [file,setFile] = useState(null);

    const handleSubmit = useLoading(async () => {
        if (!file) return;
        try {
            await Api.Import({file});
            history.push("/admin/tasks")
        } catch (e) {
            await errorHandler(e)
        }
    });

    return (
        <Form autoComplete="off" onSubmit={handleSubmit}>
            <Input type="file" onChange={(e) => setFile(e.target.files[0])} />
            <Button type="submit">Import</Button>
        </Form>
    );
};

export default Import;