const sizes = {
    B: 1,
    KiB: 1024,
    MiB: 1024*1024,
    GiB: 1024*1024*1024,
    TiB: 1024*1024*1024*1024,
};

export const fileSize = (size)=>{
    if(isNaN(Number(size))){
        return "N/A"
    }
    let unit;
    for (let u in sizes) {
        if (size < sizes[u]) {
            break
        }
        unit = u;
    }
    return (size / sizes[unit]).toFixed(2)+" "+unit
};

const FileSize = ({children})=>{
    return fileSize(children)
};

export default FileSize