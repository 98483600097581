import React from "react"
import Button from "../Button/Button";
import Icon from "../Icon/Icon";
import Select from "../Select/Select";
import Option from "../Select/Option/Option";
import {useCallback} from "react";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    thead__tr: {
        backgroundColor: 'var(--fifth-color)',
    },
    tfoot__tr: {
        backgroundColor: 'var(--fifth-color)',
    },
    tbody__tr: {
        '&:nth-child(odd)': {
            backgroundColor: 'var(--background-color)', // Color for odd rows
        },
        '&:nth-child(even)': {
            backgroundColor: 'var(--fifth-color)', // Color for odd rows
        },
        '&:hover': {
            backgroundColor: 'var(--alert-notification-backgroundColor)',
            cursor: 'pointer',
        },
    },
    th: {
        userSelect: "none",
        cursor: "pointer",
        whiteSpace: "nowrap",
        textAlign: "left",
        padding: 10
    },
    th__min: {
        width: "1px",
    },
    td: {
        marginRight: 5,
        padding: 5
    },
    td__min: {
        width: "1px",
    },
    td__loading: {
        width: "100%",
        height: "100%",
        textAlign: "center",
        verticalAlign: "center"
    },
});

export const DataTable = ({
                   data,
                   columns,
                   order,
                   loading,
                   onRowClick,
                   onSort,
                   onPaginate,
                   onLimitChange,
                   paginatePrevDisabled,
                   pageinateNextDisabled,
                   ...props
               }) => {
    const classes = useStyles();
    const handleSort = (key, dir) => {
        if (onSort && key) {
            onSort(key, dir);
        }
    };

    const handlePageChange = page => {
        if (onPaginate) {
            onPaginate({page});
        }
    };

    const handleLimitchange = limit => {
        if (onLimitChange) {
            onLimitChange({limit});
        }
    };

    return (
        <table border={0} cellPadding={0} cellSpacing={0} style={{width: "100%", height: "100%"}} {...props}>
            <thead>
            <tr className={classes.thead__tr}>
                {columns ? columns.map(col => {
                    const label = col.label ? col.label : renderLabel(col.key)
                    const style = {userSelect: "none", cursor: "pointer"}
                    return <th key={col.key} style={style}
                               className={`${classes.th} ${col.minWidth && classes.th__min}`}>
                        {col.sortable
                            ? <SortArrow id={col.key} onSort={handleSort} direction={order[col.key]}>
                                {label}
                            </SortArrow>
                            : label
                        }

                    </th>
                }) : Object.keys(data[0] ?? []).map((key) => (
                    <th key={key}>
                        <SortArrow id={key} onSort={handleSort}>
                            {renderLabel(key)}
                        </SortArrow>
                    </th>
                ))}
            </tr>
            </thead>
            <tbody>
            {loading
                ?
                <tr className={classes.tbody__tr}>
                    <td colSpan={columns.length} className={classes.td__loading}>Loading...</td>
                </tr>
                :
                data.map((row, rowIndex) => (
                    <tr key={rowIndex} className={classes.tbody__tr} onClick={()=>{
                        onRowClick && onRowClick(row,rowIndex)
                    }}>
                        {columns ? columns.map(col => {
                            const value = row[col.key];
                            return (
                                <td key={col.key} className={`${classes.td} ${col.minWidth ? classes.td__min : ''}`}>
                                    {col.render ? col.render(value, row) : defaultRenderer(value)}
                                </td>
                            );
                        }) : Object.keys(row).map((key) => (
                            <td key={key}>{row[key]}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
            <tfoot>
            <tr className={classes.tfoot__tr}>
                <td colSpan={columns.length} style={{padding: 5}}>
                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <Button onClick={() => handlePageChange(-1)} disabled={paginatePrevDisabled}
                                circle><Icon>navigate_before</Icon></Button>
                        <Select style={{display: "inline-block"}} placeholder={"Limit"}
                                onChange={e => handleLimitchange(e.value)}>
                            <Option value={25}>25</Option>
                            <Option value={50}>50</Option>
                            <Option value={100}>100</Option>
                            <Option value={500}>500</Option>
                        </Select>
                        <Button onClick={() => handlePageChange(1)} disabled={pageinateNextDisabled}
                                circle><Icon>navigate_next</Icon></Button>
                    </div>
                </td>
            </tr>
            </tfoot>
        </table>
    );
};

const defaultRenderer = value => `${value}`;

const  renderLabel = str => str.charAt(0).toUpperCase() + str.slice(1);

const SortArrow = ({id, direction = 'none', onSort, children, ...props}) => {
    const handleSort = useCallback(() => {
        let nextSort;
        if (direction === 'none') nextSort = 'asc';
        else if (direction === 'asc') nextSort = 'desc';
        else if (direction === 'desc') nextSort = 'none';

        //setSortDirection(nextSort);
        onSort && onSort(id, nextSort);
    }, [id, direction, onSort]);

    const classes = {
        sortNone: 'sort-none',
        sortAsc: 'sort-asc',
        sortDesc: 'sort-desc',
    };

    return (
        <div onClick={handleSort} style={{display: "flex", justifyContent: "space-between"}} {...props}>
            <span style={{display: "flex", alignItems: "center"}}>
            {children}
            </span>
            <span style={{display: "flex", alignItems: "center"}}>
            {direction === 'asc' && <i className={classes.sortAsc + " material-icons"}>arrow_drop_up</i>}
                {direction === 'desc' && <i className={classes.sortDesc + " material-icons"}>arrow_drop_down</i>}
                {direction === 'none' && <i className={classes.sortNone + " material-icons"}>sort</i>}
            </span>
        </div>
    );
};