import React from 'react';
import withStyles from "react-jss";

const styles = {
    root: {
        border: "5px solid var(--first-color)",
        WebkitBoxShadow:
            "inset 0 0 8px  rgba(0,0,0,0.1), 0 0 16px rgba(0,0,0,0.1)",
        MozBoxShadow:
            "inset 0 0 8px  rgba(0,0,0,0.1), 0 0 16px rgba(0,0,0,0.1)",
        boxShadow:
            "inset 0 0 8px  rgba(0,0,0,0.1), 0 0 16px rgba(0,0,0,0.1)",
    }
};

const Paper = ({classes,children, className}) => {
    return (
        <div className={[classes.root,className].filter(v=>v).join(" ")}>
            {children}
        </div>
    );
};

export default withStyles(styles)(Paper);
