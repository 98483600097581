import React from 'react';
import {Link,Route,Switch,withRouter} from "react-router-dom";
import ManifestPage from "./ManifestPage";
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import Paper from "../../components/Paper/Paper";
import Modal from "../../components/Modal/Modal";
import withStyles from "react-jss";
import ManifestAddForm from '../../views/Manifest/ManifestAddForm';
import ManifestList from "../../views/Manifest/ManifestList";


const styles = {
    listContainer: {
        display: "grid",
        gridTemplateColumns: "auto auto",
        gridTemplateRows: "min-content auto",
        gridTemplateAreas: '"title add" "list list"',
        padding: 10,
        height: "100%",
        overflow: "auto"
    },
    title : {

    },
    add: {
        gridArea: 'add',
        justifySelf: 'self-end',
        margin: 10,
    },
    list: {
        paddingTop: 10,
        paddingBottom: 10,
        gridArea: 'list',
    },
};

const ManifestListPage = ({history,classes}) => {

    const handleAdd = (manifestId)=>{
        history.push("/manifests/"+manifestId);
    };

    const handleAbort = () => {
        history.push("/manifests");
    };

    return (
                <Switch>
                    <Route exact path="/manifests(|/add)">
                        <Route path="/manifests/add">
                            <Modal open={true} onClose={handleAbort}>
                                <ManifestAddForm onSubmit={handleAdd}/>
                            </Modal>
                        </Route>
                        <Paper className={classes.listContainer}>
                            <h1 className={classes.title}>Manifests</h1>
                            <Link to="/manifests/add" className={classes.add}>
                                <Button circle title="add">
                                    <Icon>add</Icon>
                                </Button>
                            </Link>
                            <ManifestList className={classes.list}/>
                        </Paper>
                    </Route>
                    <Route path="/manifests/:id" render={({match}) => {
                        return <ManifestPage id={match.params.id}/>
                    }
                    } />
                </Switch>
    );
};

export default withRouter(withStyles(styles)(ManifestListPage));
