import React from "react"
import {createUseStyles} from 'react-jss'

const useStyles = createUseStyles({
    root: {
        width: "100%",
        color: "var(--first-text-color)",
    },
});

const Table = ({children})=>{
    const classes = useStyles()
    return (
        <table className={classes.root}>
            <tbody>
                {children}
            </tbody>
        </table>
    )
};

export default Table