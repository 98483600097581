import React,{useState} from 'react';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import Form from "../../components/Form/Form";
import Api from "../../services/Api/Api";
import {useErrorHandler} from "../../services/Utils/useErrorHandler";
import {useLoading} from "../../services/LoadingContext/LoadingContext";

const DepartmentEditForm = ({onUpdate,department}) => {

    const {id} = department;
    const [name, setName] = useState(department && department.name);
    const errorHandler = useErrorHandler();

    const handleSubmit = useLoading(async (e) => {
        e.preventDefault();
        try {
            await Api.UpdateDepartment({id, name})
        } catch (e) {
            await errorHandler(e);
        }
    });

    return (
        <Form onSubmit={handleSubmit}>
            <Input title="Name" required minLength="2" defaultValue={name} onChange={e => setName(e.target.value)}/>
            <Button type="submit">Save</Button>
        </Form>
    );
};

export default DepartmentEditForm