import React,{useState} from 'react';
import {createUseStyles} from "react-jss"
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import Api from "../../services/Api/Api";
import {Redirect} from "react-router-dom";
import Option from '../../components/Select/Option/Option';
import Select from '../../components/Select/Select';
import Form from "../../components/Form/Form";
import Tab from "../../components/Tab/Tab";
import Pane from "../../components/Tab/Pane/Pane";
import Checkbox from "../../components/Checkbox/Checkbox";
import {useFlash} from "../../components/Flash/Flash";
import {useErrorHandler} from "../../services/Utils/useErrorHandler";
import {useLoading} from "../../services/LoadingContext/LoadingContext";

const useMyAccountStyles = createUseStyles({
    root: {
        margin: 'auto',
        maxWidth: 500,
        maxHeight: 500
    },
    departmentAlert: {
        color: "red",
    }
});

const MyAccount = ({currentUser,departments,offices,ships}) => {
    const classes = useMyAccountStyles();
    const [password,setPassword] = useState("");
    const [name,setName] = useState(currentUser && currentUser.name);
    const [logout,setLogout] = useState(false);
    const [onlyDepartment,setOnlyDepartment] = useState(localStorage.getItem(currentUser.id + "onlyDepartment") === "true");
    const flash = useFlash();
    const errorHandler = useErrorHandler();

    const handleSubmitPassword = useLoading(async (e)=> {
        e.preventDefault();
        try {
            await Api.UpdatePassword({id: currentUser.id,password});
            setLogout(true);
        }catch (e) {
            await errorHandler(e)
        }
    });

    const handleSetOnlyDepartment = function (e) {
        //TODO refacor to useLocalStorage
        localStorage.setItem(currentUser.id + "onlyDepartment",e);
        setOnlyDepartment(e)
    };

    const handleSubmit = useLoading(async (e) => {
        e.preventDefault();
        try {
            await Api.UpdateUser({id: currentUser.id, name})
        }catch (e) {
            await errorHandler(e)
        }
    });

    const handleChangeHome = useLoading(async (data) => {
        try {
            await Api.ChangeUserHome({home: data.value});
            flash.notification(<p>Home department changed</p>)
        } catch (e) {
            await errorHandler(e)
        }
    });

    if (logout) {
        return (<Redirect to="/logout" />)
    }

    return (
        <div className={classes.root}>
            {currentUser && <div>
                <Tab name="asset-tab">
                    <Pane title="Home Department">
                        <h1>Change Home Department</h1>
                        <Select title="Home" onChange={handleChangeHome} value={currentUser.home}>
                            {departments.map(department => {
                                const parent = [...ships,...offices].find(v => v.id === department.parent);
                                return {...department,name: [parent.name,department.name].filter(v => v).join(" ")}
                            }).filter(dep=>!dep.deleted).sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map(d => <Option key={d.id} value={d.id}>
                                {d.name}
                            </Option>)}
                        </Select>
                        <Checkbox title="Show only department in home page on this computer" checked={onlyDepartment} onChange={e => handleSetOnlyDepartment(e.target.checked)} />
                        {onlyDepartment &&
                            <div className={classes.departmentAlert}>Warning: You will not see alerts for assets outside your department storage.</div>
                        }
                    </Pane>
                    <Pane title="Login credentials">
                        <h1>Login credentials</h1>
                        <Form onSubmit={handleSubmitPassword}>
                            <Input title="Email" disabled value={currentUser.email} />
                            <Input data-lpignore="true" required minLength="3" title="Password" type="password" onChange={e => setPassword(e.target.value)} />
                            <Button type="submit">Set new password</Button>
                        </Form>
                    </Pane>
                    <Pane title="User details">
                        <h1>User details</h1>
                        <Form onSubmit={handleSubmit}>
                            <Input title="Name" required minLength="3" defaultValue={name} onChange={e => setName(e.target.value)} />
                            <Button type="submit">Update</Button>
                        </Form>
                    </Pane>

                </Tab>
            </div>
            }
        </div>
    );
};

export default MyAccount;
