import React from 'react';
import withStyles from "react-jss";

const styles = {
    root:{
        cursor: "pointer",
        display: "inline-block",
        padding: "10px",
        userSelect: "none",
        border: "1px solid var(--first-color)",
        borderRight: "5px solid var(--first-color)",
        borderBottom: 0,
        WebkitBoxShadow:
            "inset 0 0 8px  rgba(0,0,0,0.1),0 0 16px rgba(0,0,0,0.1)",
        MozBoxShadow:
            "inset 0 0 8px  rgba(0,0,0,0.1), 0 0 16px rgba(0,0,0,0.1)",
        boxShadow:
            "inset 0 0 8px  rgba(0,0,0,0.1),0 0 16px rgba(0,0,0,0.1)",
        margin: 0,
        "&:hover": {
            borderColor: "var(--second-color)"
        },
        "&:not(:first-of-type)":{
            marginLeft: -1
        },
        "&:hover+&":{
            borderLeft: 0,
            marginLeft: 0,
        },
        "&:active":{
            borderColor: "var(--third-color)",
            backgroundColor: "var(--first-color)"
        },
    },
    selected: {
        backgroundColor: "var(--second-color)",
    },
};

const Pane = ({classes,className,title = '-',children, onClick, selected, ...other}) => {

    const handleSelect = () => {
        onClick && onClick()
    };

    return (
        <span className={[classes.root,selected && classes.selected,className].join(" ")} onClick={handleSelect} {...other}>{title}</span>
    );
};

export default withStyles(styles)(Pane);