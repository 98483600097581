import React,{useState,useEffect} from 'react';
import {DepartmentType,ExternalType,OfficeType,ShipType} from "../../constants";
import {
    ShipsResource,
    OfficesResource,
    DepartmentsResource,
    ExternalsResource,
    useResource, OfficesCollection, ShipsCollection, DepartmentsCollection, ExternalsCollection
} from '../Firestore/Resources';

const withType = (v,type) => Object.fromEntries(v.map(v => ({_type: type,...v})).map(v => [v.id,v]));

export const useAddressables = ()=>{
    const offices = useResource(OfficesCollection);
    const ships = useResource(ShipsCollection);
    const departments = useResource(DepartmentsCollection);
    const externals = useResource(ExternalsCollection);
    const [addressables,setAddressables] = useState(null);

    useEffect(()=>{
        if(offices && ships && departments && externals) {
            setAddressables({
                ...withType(ships, ShipType),
                ...withType(offices, OfficeType),
                ...withType(externals, ExternalType),
                ...withType(departments.map((department) => {
                    const parent = [...ships, ...offices].find(v => v.id === department.parent);
                    return {...department, name: [parent.name, department.name].filter(v => v).join(" ")}
                }), DepartmentType)
            })
        }
    },[offices,ships,departments,externals]);

    return addressables
};

export const AddressableConsumer = ({children}) => {
    return (
        <OfficesResource>{offices =>
            <ShipsResource>{ships =>
                <DepartmentsResource>{departments =>
                    <ExternalsResource>{externals =>
                            children({
                                ...withType(ships,ShipType),
                                ...withType(offices,OfficeType),
                                ...withType(externals,ExternalType),
                                ...withType(departments.map((department)=>{
                                    const parent = [...ships,...offices].find(v=>v.id === department.parent);
                                    return {...department,name:[parent.name, department.name].filter(v=>v).join(" ")}
                                }),DepartmentType)
                            })
                    }</ExternalsResource>
                }</DepartmentsResource>
            }</ShipsResource>
        }</OfficesResource>
    )
};

