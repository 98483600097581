import React from 'react';
import Option from "../../components/Select/Option/Option";
import CollectionSelect from "../../components/CollectionSelect/CollectionSelect";
import {useAddressables} from "../../services/Context/Addressable";

const AddressableSelect = ({value,...props}) => {

    const sort = (a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
    const map = d => <Option selected={d.id === value} key={d.id} value={d}>{d.name}</Option>;
    const addressables = useAddressables();

    if(!addressables){
        return <></>
    }

    return <CollectionSelect value={addressables[value]} values={Object.values(addressables).filter(a=>!a.deleted)} sort={sort} map={map} {...props} />;
};

export default AddressableSelect;