import React from 'react';
import ProductImage from "./ProductImage";
import {createUseStyles} from 'react-jss'

const useStyles = createUseStyles({
    productView: {
        display: "grid",
        gridTemplateColumns: "160px auto",
    },
    productValuesView: {
        display: "grid",
        gridTemplateColumns: "160px auto",
    },
    title: {
        fontWeight: "600",
    },
});

const EmbeddedProduct = ({product}) => {
    const classes = useStyles();
    if (!product) {
        return ""
    }
    return <div className={classes.productView}>
        <div>
            {<ProductImage
                fileId={product.image}
                editable={true}
                productId={product.id} />}
        </div>
        <div className={classes.productValuesView}>
            <div className={classes.title}>Model: </div><div>{product.model}</div>
            <div className={classes.title}>Category/ies: </div><div>{product.category}</div>
            <div className={classes.title}>Price: </div><div>{product.price}</div>
            <div className={classes.title}>Weight:</div><div> {product.weight+ " "}kg</div>
            <div className={classes.title}>Dimensions: </div><div>{product.dimensions}</div>
            <div className={classes.title}>Producer:</div><div> {product.producer}</div>
            <div className={classes.title}>Calibration interval:</div><div> {product.calibrationInterval !== "" ? product.calibrationInterval + " months" : "N/A"}</div>
            <div className={classes.title}>Description:</div><div> {product.description}</div>
        </div>
    </div>

};

export default EmbeddedProduct;