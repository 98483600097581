import React, {useState} from 'react';
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import Api from '../../services/Api/Api'
import {Redirect} from "react-router-dom";
import withStyles from "react-jss"
import Form from "../../components/Form/Form";
import {useErrorHandler} from "../../services/Utils/useErrorHandler";
import {useLoading} from "../../services/LoadingContext/LoadingContext";

const styles = {
    root:{
        margin: 'auto',
        maxWidth: 500,
        maxHeight: 500
    }
};

const ForgotAccount = ({classes}) => {
    const [email, setEmail] = useState("");
    const [redirect, setRedirect] = useState(false);
    const errorHandler = useErrorHandler();

    const handleSubmit = useLoading(async (e) => {
        e.preventDefault();
        try{
            await Api.ForgotPassword({email});
            setRedirect(true);
        }catch (e) {
            await errorHandler(e)
        }
    });

    if (redirect){
        return (<Redirect to="/"/>)
    }

    return (
        <div className={classes.root}>
            <h1>Forgot password.</h1>
            <Form onSubmit={handleSubmit}>
                <Input required title="Email" onChange={e => setEmail(e.target.value)}/>
                <Button type="submit">Send new password</Button>
            </Form>
        </div>
    );
};

export default withStyles(styles)(ForgotAccount);
