import React, {useState} from 'react';
import {Route, Switch} from "react-router-dom";
import withStyles from "react-jss";
import {StoragesResource} from "../../services/Firestore/Resources";
import Paper from "../../components/Paper/Paper";
import StoragePage from '../../views/Storage/StoragePage'
import StorageList from '../../views/Storage/StorageList'
import Input from "../../components/Input/Input";

const styles = {
    topBar: {
        display: "grid",
        gridTemplateColumns: "auto",
    },
    search: {
        justifySelf: 'self-end',
        margin: 10,
    },
    list: {
        gridArea: 'list',
    }
};

const StorageListPage = ({classes}) => {

    const [filter, setFilter] = useState("");

    const handleFilterChange = (e) => {
        setFilter(e.target.value)
    };
    return (
        <StoragesResource>{storages => storages && storages.length !== 0 &&
            <Switch>
                <Route exact path="/storages(|/add)">
                    <Paper>
                        <div className={classes.topBar}>
                            <Input className={classes.search} placeholder={"Filter"} onChange={handleFilterChange} />
                        </div>
                        <StorageList filter={filter}storages={storages} />
                    </Paper>
                </Route>
                <Route path="/storages/:id" render={({match}) =>
                    <StoragePage storage={storages.find(a => a.id === match.params.id)} storages={storages} />
                } />
            </Switch>
        }</StoragesResource>
    );
};

export default withStyles(styles)(StorageListPage);
