import React,{useState} from 'react';
import {Route,Switch,withRouter} from "react-router-dom";
import {UsersResource} from "../../services/Firestore/Resources";
import Paper from "../../components/Paper/Paper";
import withStyles from "react-jss";
import UserList from "../../views/User/UserList";
import UserPage from "./UserPage";
import Input from "../../components/Input/Input";
import AuthContext,{Admin} from '../../services/AuthContext/AuthContext';

const styles = {
    topBar: {
        display: "grid",
        gridTemplateColumns: "auto auto",
    },
    add: {
        justifySelf: 'self-start',
        margin: 10,
    },
    search: {
        justifySelf: 'self-end',
        margin: 10,
    },
    list: {
        gridArea: 'list',
    }
};

const UserListPage = ({history,classes}) => {

    const [filter,setFilter] = useState("");

    const handleFilterChange = (e) => {
        setFilter(e.target.value)
    };

    return (
        <UsersResource>{users => users &&
            <Switch>
                <Route path="/users/:id" render={({match}) =>
                    <UserPage user={users.get(match.params.id)} />
                } />
                <Route path="/users">
                    <AuthContext.Consumer>{currentUser => currentUser.hasRole(Admin) &&
                        <Paper>
                            <div className={classes.topBar}>
                                <Input className={classes.search} placeholder={"Filter"} onChange={handleFilterChange} />
                            </div>
                            <UserList className={classes.list} filter={filter} users={users} />
                        </Paper>
                    }</AuthContext.Consumer>
                </Route>

            </Switch>
        }</UsersResource>
    );
};

export default withRouter(withStyles(styles)(UserListPage));
