import React, {useRef} from 'react';
import {createUseStyles} from 'react-jss'

const useStyles = createUseStyles({
    button: {
        color: "var(--first-color)",
        cursor: "pointer",
        display: "inline-block",
        padding: "10px",
        font: "400 16px Arial",
        textAlign: "left",
        border: "5px solid var(--first-color)",
        WebkitBoxShadow:
            "inset 0 0 8px  rgba(0,0,0,0.1),0 0 16px rgba(0,0,0,0.1)",
        MozBoxShadow:
            "inset 0 0 8px  rgba(0,0,0,0.1), 0 0 16px rgba(0,0,0,0.1)",
        boxShadow:
            "inset 0 0 8px  rgba(0,0,0,0.1),0 0 16px rgba(0,0,0,0.1)",
        margin: 0,

        "&:hover": {
            cursor: "pointer",
            borderColor: "var(--second-color)"
        },
        "&:active":{
            cursor: "pointer",
            borderColor: "var(--third-color)",
            backgroundColor: "var(--first-color)"
        },
        "& button": {
            color: "inherit",
            cursor: "inherit",
            fontWeight: "bold",
            border: "0",
            outline: "0",
            background: "transparent"
        }
    },
    buttonCircle: {
        padding: 5,
        borderRadius: "50%",
        height: 44,
        "& button": {
            height: 24,
            padding: 0,
        }
    },
    buttonEl: {
        height: "100%",
        width: "100%",
        userSelect: "none"
    },
    disabled: {
        opacity: "0.6",
        "&:hover": {
            borderColor: "unset"
        },
        "&:active":{
            borderColor: "unset",
            backgroundColor: "unset"
        },
    }
});

const Button = ({className,style,circle,children,...other})=>{
    const buttonRef = useRef(null);
    const classes = useStyles();
    const {disabled} = other;

    const handleClick = (e)=>{
        if (e.target === buttonRef.current || buttonRef.current.contains(e.target)){
            return
        }
        buttonRef.current.click();
    };

    return (
        <div
            className={[classes.button,circle && classes.buttonCircle, disabled && classes.disabled, className].join(" ")}
            onClick={handleClick}
            style={style}
        >
            <button className={classes.buttonEl} ref={buttonRef} {...other}>{children}</button>
        </div>
    );
};

export default Button;
