import React from "react"
import withStyles from 'react-jss'
import Close from "../Icon/Close";

const styles = {
    root: {
        display: "none",
        position: "fixed",
        zIndex: 999,
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        backgroundColor: "rgba(0,0,0,0.4)",
    },
    content: {
        position: "relative",
        opacity:0,
        backgroundColor: "var(--background-color)",
        margin: "auto auto",
        padding: "30px",
        border: "1px solid var(--first-color)",
        width: "fit-content",
        height: "fit-content",
        minWidth: "40%",
        maxWidth: "100vw",
        maxHeight: "100vh",
        overflow: "auto",
    },
    open: {
        display: "inherit",
        animationName: '$openRoot',
        animationDuration: '0.2s',
        "& $content": {
            display: "inherit",
            animationDelay: '0.2s',
            animationName: '$openContent',
            animationDuration: '0.2s',
            animationFillMode: 'forwards',
        }
    },
    close: {
        cursor: "pointer",
        position: "absolute",
        right: 5,
        top: 5,
        userSelect: "none",
    },
    '@keyframes openRoot': {
        from: {
            opacity: 0,
        },
        to: {
            opacity: 1,
        }
    },
    '@keyframes openContent': {
        from: {
            transform: "scale(0)",
            opacity: 0,
        },
        to: {
            transform: "scale(1)",
            opacity: 1,
        }
    },
};


const Modal = ({classes,open, onClose, children})=>{
    return <div className={[classes.root, open && classes.open].join(' ')}>
        <div className={classes.content}>
            <Close onClick={onClose} className={classes.close}/>
            {children}
        </div>
    </div>
};


export default withStyles(styles)(Modal);