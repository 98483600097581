import React, {useState} from 'react';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import Textarea from '../../components/Textarea/Textarea';
import Api from '../../services/Api/Api';
import Form from "../../components/Form/Form";
import withStyles from "react-jss";
import {useLoading} from "../../services/LoadingContext/LoadingContext";
import {useErrorHandler} from "../../services/Utils/useErrorHandler";

const styles = {
    root: {
        display: "grid",
        gridTemplateColumns: "50% 50%"
    },
    fullWidth: {
        gridColumn: "1/3"
    },
    containerAlert: {
        color: "red",
    },
    link: {
        cursor: "pointer",
        fontStyle: "italic",
        color: "var(--third-color)",
        gridColumn: "1/3"
    }
};

const ProductAddForm = ({classes, onSubmit}) => {

    const [name, setName] = useState(null);
    const [category, setCategory] = useState(null);
    const [calibrationInterval, setCalibrationInterval] = useState(null);
    const [description, setDescription] = useState(null);
    const [weight, setWeight] = useState(0);
    const [dimensions, setDimensions] = useState(null);
    const [price, setPrice] = useState(0);
    const [producer, setProducer] = useState(null);
    const [model, setModel] = useState(null);
    const [container, setContainer] = useState(false);
    const [showAdvanced, setShowAdvanced] = useState(false);
    const errorHandler = useErrorHandler();

    const handleSubmit = useLoading(async (e) => {
        e.preventDefault();

        try {
            const data = await Api.CreateProduct({
                name,
                category,
                calibrationInterval,
                description,
                weight,
                dimensions,
                price,
                producer,
                model,
                container,
            });
            onSubmit && onSubmit(data);
        } catch (e) {
            await errorHandler(e)
        }
    });

    return (
        <Form onSubmit={handleSubmit} className={classes.root}>
            <Input title="Name" required onChange={e => setName(e.target.value)}/>
            <Input title="Category" onChange={e => setCategory(e.target.value)}/>
            <Textarea className={classes.fullWidth} title="Description" onChange={e => setDescription(e.target.value)}/>
            <Input title="Calibration interval (months), leave blank if N/A" type="number" onChange={e => setCalibrationInterval(e.target.valueAsNumber)}/>
            <Input title="Weight" type="number" min={0} onChange={e => setWeight(e.target.valueAsNumber)}/>
            <Input title="Dimensions" onChange={e => setDimensions(e.target.value)}/>
            <Input title="Price" type="number" min={0} onChange={e => setPrice(e.target.valueAsNumber)}/>
            <Input title="Producer" onChange={e => setProducer(e.target.value)}/>
            <Input title="Model" onChange={e => setModel(e.target.value)}/>
            <span className={classes.link} onClick ={() => setShowAdvanced(!showAdvanced)}>Advanced options</span>
            {showAdvanced && 
                <Input title="Is a container for other assets?" type="checkbox" onChange={e => setContainer(e.target.checked)} />
            }
            {container && 
                <div className={classes.containerAlert}>Caution: This will create a storage with all assets of this model, where you can place other assets within.</div>
            }
            <div className={classes.fullWidth}><Button type="submit">Add</Button></div>
        </Form>
    );
};

export default withStyles(styles)(ProductAddForm)