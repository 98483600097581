import React from 'react';
import Option from "../../components/Select/Option/Option";
import CollectionSelect from "../../components/CollectionSelect/CollectionSelect";
import {ProductsResource} from "../../services/Firestore/Resources";

const ProductSelect = ({filter,products,...props})=>{
    const sort = (a,b)=>a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
    const map = d => <Option key={d.id} value={d.id}>{d.name}</Option>;

    const component = products => <CollectionSelect values={products.filter(p=>!p.deleted)} filter={p=>{
        if(p.deleted){
            return false;
        }
        if (filter){
            return filter(p)
        }
        return true;
    }} title={"Model"} sort={sort} map={map} {...props}/>

    if (!products) {
        return <ProductsResource>{component}</ProductsResource>
    }
    return component(products)
};

export default ProductSelect;