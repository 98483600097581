import React,{useState} from 'react';
import {Link} from "react-router-dom";
import withStyles from 'react-jss'
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import DepartmentList from '../Department/DepartmentList';
import {OfficeType} from "../../constants";
import {DepartmentsResource} from '../../services/Firestore/Resources';
import DepartmentAddForm from '../Department/DepartmentAddForm';
import Modal from "../../components/Modal/Modal";
import AuthContext,{Admin} from "../../services/AuthContext/AuthContext";

const styles = {
    topBar: {
        gridColumn: "1/3",
        display: "grid",
        gridTemplateColumns: "auto 100px ",
    },
    buttons: {
        textAlign: "right",
        "& > *": {
            marginLeft: 5,
        }
    },
    address: {
        whiteSpace: "pre-wrap",
    }
};

const Office = ({classes,office}) => {

    const [openDeparmentCreate,setOpenDeparmentCreate] = useState(false);

    const handleCloseDepartmentCreate = () => {
        setOpenDeparmentCreate(false)
    };

    const handleOpenDepartmentCreate = () => {
        setOpenDeparmentCreate(true)
    };

    return (
        <AuthContext.Consumer>{user => user &&
            <div>
                <div className={classes.topBar}>
                    {office.deleted ?
                        <h1 style={{textDecoration: "line-through"}}>{office.name} - this office is deleted</h1>
                        : <h1>{office.name}</h1>
                    }
                    <div className={classes.buttons}>
                        {user.hasRole(Admin) && <>
                            <Link to={"/offices/" + office.id + "/edit"} className={classes.add}>
                                <Button circle title={"Edit " + office.name}>
                                    <Icon>edit</Icon>
                                </Button>
                            </Link>
                            {!office.deleted ?
                                <Link to={"/offices/" + office.id + "/delete"} className={classes.add}>
                                    <Button circle title={"Delete " + office.name}>
                                        <Icon>delete</Icon>
                                    </Button>
                                </Link> :
                                <Link to={"/offices/" + office.id + "/restore"} className={classes.add}>
                                    <Button circle title={"Restore " + office.name}>
                                        <Icon>restore</Icon>
                                    </Button>
                                </Link>
                            }
                        </>}
                    </div>
                </div>
                <div className={classes.address}>{office.address}</div>
                <Link to={"../storages/" + office.id}><h3>Go to {office.name} storage</h3></Link>
                <div className={classes.topBar}>
                    <h1>Departments:</h1>
                    <div className={classes.buttons}>
                        {user.hasRole(Admin) &&
                            <Button onClick={handleOpenDepartmentCreate} circle title="Create new department">
                                <Icon>add</Icon>
                            </Button>
                        }
                    </div>
                </div>
                <DepartmentsResource>{departments => departments &&
                    <DepartmentList departments={departments} parentId={office.id} />
                }</DepartmentsResource>
                <Modal open={openDeparmentCreate} onClose={handleCloseDepartmentCreate}>
                    <DepartmentAddForm onSubmit={handleCloseDepartmentCreate} parent={office.id} parentType={OfficeType} />
                </Modal>
            </div>
        }</AuthContext.Consumer>
    );
};

export default withStyles(styles)(Office)