import React from "react";
import {
    DepartmentsResource,
    ExternalsResource,
    OfficesResource,
    ShipsResource
} from "../../../services/Firestore/Resources";

const AddressableTitle = ({id,haystack = []})=>{
    const addressable = haystack.find(v=>v.id === id);
   return addressable ? addressable.name : 'N/A'
};

export default ({id})=>{
    return (
       <ShipsResource>{ships =>
            <DepartmentsResource>{departments =>
                <ExternalsResource>{externals =>
                    <OfficesResource>{offices =>
                        <AddressableTitle {...{id,haystack:[...ships,...externals,...offices,...departments.map((department)=>{
                                const parent = [...ships,...offices].find(v=>v.id === department.parent);
                                return {...department,name:[parent.name, department.name].filter(v=>v).join(" ")}
                            })]}}/>
                    }</OfficesResource>
                }</ExternalsResource>
            }</DepartmentsResource>
        }</ShipsResource>
    )
};