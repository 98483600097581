import React from 'react';
import withStyles from "react-jss"
import Table from '../../components/Table/Table';
import Row from '../../components/Table/Row/Row';
import Cell from '../../components/Table/Row/Cell/Cell';
import {Link,withRouter} from "react-router-dom";
import Entity from '../../components/Entity/Entity';
import {queryObject} from "../../services/Utils/Query";
import Api from '../../services/Api/Api';
import Button from '../../components/Button/Button';
import AddressableTitle from "../../components/Title/AddressableTitle/AddressableTitle";
import {Admin,ManifestEditor} from "../../services/AuthContext/AuthContext";
import {useLoading} from "../../services/LoadingContext/LoadingContext";
import Checkbox from "../../components/Checkbox/Checkbox";
import {useErrorHandler} from "../../services/Utils/useErrorHandler";

const styles = {
    root: {
        display: "grid",
        gridGap: "5px",
    },
    role: {
        position: "relative",
        padding: ".25rem 0.25rem",
        borderradius: ".25rem",
        border: "1px solid",
        textAlign: "center",
        borderRadius: ".5em",
        boxShadow: "1px 1px 3px #888",
    },
    adminRole: {
        background: "var(--alert-danger-backgroundColor)",
    },
    otherRole: {
        background: "var(--alert-notification-backgroundColor)",
    },
};

const UserList = ({classes,match,users,filter}) => {

    const errorHandler = useErrorHandler();

    const updateRoles =(id,roles) => {
       return Api.ChangeUsersRole({
            id,
            roles,
        })
    };

    const handleAddRoleToUser = useLoading(async (user,role) => {
        try {
            await updateRoles(user.id, user.roles.addRole(role))
        } catch (e) {
            await errorHandler(e)
        }
    });

    const handleRemoveRoleFromUser = useLoading(async (user,role) => {
        try {
            await updateRoles(user.id, user.roles.removeRole(role))
        } catch (e) {
            await errorHandler(e)
        }
    });

    const handleActivateUser = useLoading(async (id) => {
        try {
            await  Api.ActivateUser({
                id,
            })
        } catch (e) {
            await errorHandler(e)
        }
    });

    let listUsers = users.map(user => {
        user.activated = user.active ? "active" : "not";
        user.roleTitle = user.hasRole(Admin) ? "admin" : "";
        return user
    });

    return (
        <div className={classes.root}>
            <Table>
                <Row>
                    <Cell header>Name</Cell>
                    <Cell header>Email</Cell>
                    <Cell header>Activated</Cell>
                    <Cell header>Role</Cell>
                    <Cell header>Home</Cell>
                    <Cell header>Admin</Cell>
                    <Cell header>ManifestEditor</Cell>
                </Row>
                {listUsers && listUsers.length > 0
                    ? queryObject(users,filter).map((user) => (
                        <Row key={user.id} >
                            <Cell>
                                <Link to={match.url + "/" + user.id}>
                                    <Entity title={user.name} />
                                </Link>
                            </Cell>
                            <Cell>
                                <Entity title={user.email} />
                            </Cell>
                            <Cell>
                                {!user.active ?
                                    <Button onClick={() => handleActivateUser(user.id)}>Activate user</Button>
                                   :
                                    <Checkbox disabled checked={true}/> 
                                }
                            </Cell>
                            <Cell>
                                {user.hasRole(Admin) &&
                                    <div className={classes.adminRole + " " + classes.role}>Admin</div>}
                                    {user.hasRole(ManifestEditor) &&
                                        <div className={classes.ManifestEditor + " " + classes.role}>Manifest Editor</div>}
                            </Cell>
                            <Cell>
                                <AddressableTitle id={user.home} />
                            </Cell>
                            <Cell>
                                {!user.hasRole(Admin)?
                                    <Button onClick={() => handleAddRoleToUser(user,Admin)}>Make Admin</Button>
                                    :
                                    <Button onClick={() => handleRemoveRoleFromUser(user,Admin)}>Remove Admin</Button>
                                }
                            </Cell>
                            <Cell>
                                {!user.hasRole(ManifestEditor)?
                                    <Button onClick={() => handleAddRoleToUser(user,ManifestEditor)}>Make Manifest Admin</Button>
                                    :
                                    <Button onClick={() => handleRemoveRoleFromUser(user,ManifestEditor)}>Remove Manifest Admin</Button>
                                }
                            </Cell>
                        </Row>
                    ))
                    : <Row><Cell>No users</Cell></Row>
                }
            </Table>
        </div>
    );
};

export default withRouter(withStyles(styles)(UserList));
