import React from "react"
import withStyles from 'react-jss'

const styles = {
    root: {
      
    },
};

const Row = ({classes,children})=>{
    return (
       <tr>{children}</tr>
    )
};

export default withStyles(styles)(Row);