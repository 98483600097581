import React,{useState} from 'react';
import Button from '../../components/Button/Button';
import Api from '../../services/Api/Api';
import withStyles from 'react-jss'
import Form from "../../components/Form/Form";
import Icon from "../../components/Icon/Icon";
import TextArea from "../../components/Textarea/Textarea";
import {useLoading} from "../../services/LoadingContext/LoadingContext";
import {useErrorHandler} from "../../services/Utils/useErrorHandler";

const styles = {
    root: {
        display: "grid",
        gridGap: "5px",
        gridTemplateColumns: "auto fit-content(50px)",
    },
};
const AssetAddCommentForm = ({classes,assetId}) => {
    const [comment,setComment] = useState("");
    const errorHandler = useErrorHandler();

    const handleSubmit = useLoading( async (e)=> {
        e.preventDefault();
        try {
            await Api.AddAssetComment({
                id: assetId,
                comment: comment,
            });
            setComment("");
        } catch (e) {
            await errorHandler(e)
        }
    });

    return (
        <div>
            <Form className={classes.root} autoComplete="off" onSubmit={handleSubmit}>
                <TextArea style={{margin: 0}} required minLength="3" title="Comment" value={comment} onChange={e => setComment(e.target.value)} />
                <Button circle type="submit" style={{alignSelf: "center", fontColor: "var(--first-color)"}}>
                    <Icon>send</Icon>
                </Button>
            </Form>
        </div>
    )
};



export default withStyles(styles)(AssetAddCommentForm);