import React,{useState} from 'react';
import AssetCommentList from "./AssetCommentList";
import AssetAddCommentForm from "./AssetAddCommentForm";
import withStyles from "react-jss";
import AuthContext from "../../services/AuthContext/AuthContext";
import {useFileUrl, useObjectURL} from '../Product/ProductImage';
import {Link} from "react-router-dom";
import {StorageLink} from "../../components/Link/StorageLink/StorageLink"
import {calibrationTextWithExpire} from '../../services/Utils/Calibration'
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import {StoragesResource} from '../../services/Firestore/Resources';
import {ConditionOK,ConditionDefect} from '../../constants';
import ReactQr from "react-awesome-qr";
import dologoblack from "../../assets/images/do-logo-black.png"
import Window from "../../components/Window/Window";
import QRPage from "../../pages/Asset/QR/QRPage";
import Popover from "../../components/Popover/Popover";


const styles = {
    root: {
        display: "grid",
        gridTemplateColumns: "200px auto auto",
        gridTemplateAreas: '' +
            '"header header actions"' +
            '"image info qr"' +
            '"comments comments comments"' +
            '',
        gridGap: "10px"
    },
    header: {
        gridArea: "header",
    },
    actions: {
        gridArea: "actions",
        justifySelf: "right",
        "& > *":{
            margin: 3,
        }
    },
    title: {
        fontWeight: "600",
    },
    image: {
        gridArea: "image",
        justifySelf: "center",
        maxHeight: 200,
        maxWidth: 200,
    },
    assetInfo: {
        gridArea: "info",
        display: "grid",
        gridTemplateColumns: "fit-content(130px) auto",
        gridGap: 10,
        marginLeft: 5,
    },
    qr:{
        gridArea:"qr",
        justifySelf:"center",
        cursor: "pointer",
        "& img": {
            width: 200,
        }
    },
    comments: {
        gridArea: "comments"
    },
    danger: {
        backgroundColor: "var(--alert-danger-backgroundColor)",
    },
    productLink: {
        gridColumn: "1 / span 2"
    },
};

const Asset = ({classes,asset,product}) => {
    const [QR,setQR] = useState(null);
    const [openQR,setOpenQR] = useState(false);
    const qrLogo = useObjectURL(dologoblack);
    const productImage = useFileUrl(product.image);


    const handleQrClick = ()=>{
        setOpenQR(true)
    };

    const returnClass = (s) =>{
        if (s.includes("d")){
            return classes.danger
        }
        return "";
    }

    if (!asset || !product) {
        throw Error("missing asset and/or product parameter")
    }
   const calText = calibrationTextWithExpire(asset.calibrationDate,product.calibrationInterval)
    return (
        <>
        <div className={classes.root}>
            <div className={classes.header}>
                {asset.deleted ?
                    <h1 style={{textDecoration: "line-through"}}>{product.name} - this asset is deleted</h1>
                : <h1 >{product.name}{asset.condition === ConditionDefect && <Icon title="Need Service">warning</Icon>}</h1>
                }
            </div>
            <div className={classes.actions}>
                <Link to={"/assets/" + asset.id + "/edit"}>
                    <Button circle title="edit">
                        <Icon>edit</Icon>
                    </Button>
                </Link>
                {!asset.deleted ?
                    <Link to={"/assets/" + asset.id + "/delete"}>
                        <Button circle title="delete">
                            <Icon>delete</Icon>
                        </Button>
                    </Link> :
                    <Link to={"/assets/" + asset.id + "/restore"}>
                        <Button circle title="Restore">
                            <Icon>restore</Icon>
                        </Button>
                    </Link>
                }
            </div>
            <img alt="product" className={classes.image} src={productImage ? productImage : 'https://via.placeholder.com/150'}/>
            <div className={classes.assetInfo}>
                <div className={classes.title}>Serial: </div><div>{asset.serialNumber}</div>
                <div className={classes.title}>TagNumber: </div><div>{asset.tagNumber}</div>
                <div className={classes.title}>Calibration date: </div><div className={returnClass(calText)}>{calText}</div>
                <div className={classes.title}>Owner: </div><div>{asset.owner}</div>
                <div className={classes.title}>Rental: </div><div>{asset.rental ? "Yes" : "No"}</div>
                <div className={classes.title}>Condition: </div><div>{asset.condition === ConditionOK ? "Operational" : "Needs Service" }</div>
                <div className={classes.title}>Storage:</div><div>
                    {asset.deleted ? "" :
                        <StoragesResource>{storages =>
                            <StorageLink full {...storages.get(asset.storageId)} />
                        }</StoragesResource>
                    }
                </div>
                {product.container &&
                <>
                    <div className={classes.title}>Asset storage:</div>
                    <div><StoragesResource>{storages =>
                        <StorageLink full {...storages.get(asset.id)} />
                    }</StoragesResource>
                    </div>
                </>
                }
                <div className={classes.productLink}>
                    <Link to={"/products/" + product.id}>
                        More info on model <em>{product.name}</em>
                    </Link>
                </div>
            </div>
            <div className={classes.qr} onClick={handleQrClick}>
                <Popover>
                    <ReactQr
                        size={900}
                        text={`urn:asset:${asset.id}`}
                        callback={setQR}
                    />
                    <Icon>print</Icon>
                </Popover>
            </div>
            <div className={classes.comments}>
                <h2>Comments:</h2>
                <AuthContext.Consumer>{user =>
                    <AssetCommentList assetId={asset.id} user={user} />
                }</AuthContext.Consumer>
                <AssetAddCommentForm assetId={asset.id} />
            </div>
        </div>
        {openQR && <Window onClose={()=>setOpenQR(false)} features={'menubar=no,location=no,resizable=no,scrollbars=no,status=no,width=900,height=1200'}>
            <QRPage data={{
                qr: QR,
                logo: qrLogo,
                name: product.name,
                sn: asset.serialNumber,
                tag: asset.tagNumber
            }} />
        </Window>}
        </>

    )
};

export default withStyles(styles)(Asset);