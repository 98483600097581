import React,{useState} from 'react';
import withStyles from "react-jss"
import Table from '../../components/Table/Table';
import Row from '../../components/Table/Row/Row';
import Cell from '../../components/Table/Row/Cell/Cell';
import {Link,withRouter} from "react-router-dom";
import {queryObject,queryColumns} from "../../services/Utils/Query";
import Entity from '../../components/Entity/Entity';
import SortArrows from '../../components/SortArrows/SortArrows';
import Button from '../../components/Button/Button';
import Icon from '../../components/Icon/Icon';
import DownloadCSV from "../../components/DownloadCSV/DownloadCSV";
import Input from "../../components/Input/Input";

const styles = {
    root: {
        display: "grid",
        gridGap: "5px",
    },
    filterRow: {
        padding: "0px",
    },
    topRow: {
        borderBottom: "0px",
    },
};

const ProductList = ({classes,className,match,products,filter,columnFilter}) => {

    const [sort,setSort] = useState(() => (a,b) => (a.date < b.date) ? 1 : -1);
    const [selectedTag,setSelectedTag] = useState("");

    function handleSort(dynamicSort,tag) {
        setSort(() => dynamicSort)
        setSelectedTag(tag)
    };

    const toCSV = (products) =>{
        products = products.sort(sort).map(row => [
            row.name,
            row.category,
            row.calibrationInterval,
            row.weight,
            row.dimensions,
            row.price,
            row.producer,
            row.model,
        ])
        products.unshift(['Model name','Category','Calibration interval','Weight','Dimensions','Price','Producer','Producer model'])
        return products;
    }

    function isSelected(tag) {
        return (selectedTag === tag)
    }

    const [filterName,setFilterName] = useState("");
    const [filterCategory,setFilterCategory] = useState("");
    const [filterCalibrationInterval,setFilterCalibrationInterval] = useState("");
    const [filterWeight,setFilterWeight] = useState("");
    const [filterDimensions,setFilterDimensions] = useState("");
    const [filterPrice,setFilterPrice] = useState("");
    const [filterProducer,setFilterProducer] = useState("");
    const [filterModel,setFilterModel] = useState("");
    
    const handleFilterNameChange = (e) => {
        setFilterName(e.target.value)
    };
    const handleFilterCategoryChange = (e) => {
        setFilterCategory(e.target.value)
    };
    const handleFilterCalibrationIntervalChange = (e) => {
        setFilterCalibrationInterval(e.target.value)
    };
    const handleFilterWeightChange = (e) => {
        setFilterWeight(e.target.value)
    };
    const handleFilterDimensionsChange = (e) => {
        setFilterDimensions(e.target.value)
    };
    const handleFilterPriceChange = (e) => {
        setFilterPrice(e.target.value)
    };
    const handleFilterProducerChange = (e) => {
        setFilterProducer(e.target.value)
    };
    const handleFilterModelChange = (e) => {
        setFilterModel(e.target.value)
    };

    var filterList = {};
    if (filterName !== "") {
        filterList["name"] = filterName
    }
    if (filterCategory !== "") {
        filterList["category"] = filterCategory
    }
    if (filterCalibrationInterval !== "") {
        filterList["calibrationInterval"] = filterCalibrationInterval
    }
    if (filterWeight !== "") {
        filterList["weight"] = filterWeight
    }
    if (filterDimensions!== "") {
        filterList["dimensions"] = filterDimensions
    }
    if (filterPrice !== "") {
        filterList["price"] = filterPrice
    }
    if (filterProducer !== "") {
        filterList["producer"] = filterProducer    
    }
    if (filterModel !== "") {
        filterList["model"] = filterModel    
    }

    products = (queryObject(products,filter)).sort(sort)
    if(columnFilter){
        products = queryColumns(products,filterList)
    }
    return (
        <div className={[classes.root,className].filter(v => v).join(" ")}>
            <Table>
                <Row>
                    <Cell header className={classes.topRow}>Name<SortArrows handleSort={handleSort} tag="name" selected={isSelected("name")} /></Cell>
                    <Cell header className={classes.topRow}>Category<SortArrows handleSort={handleSort} tag="category" selected={isSelected("category")} /></Cell>
                    <Cell header className={classes.topRow}>Calibration interval<SortArrows handleSort={handleSort} tag="calibrationInterval" selected={isSelected("calibrationInterval")} /></Cell>
                    <Cell header className={classes.topRow}>Weight<SortArrows handleSort={handleSort} tag="weight" selected={isSelected("weight")} /></Cell>
                    <Cell header className={classes.topRow}>Dimensions<SortArrows handleSort={handleSort} tag="dimensions" selected={isSelected("dimensions")} /></Cell>
                    <Cell header className={classes.topRow}>Price<SortArrows handleSort={handleSort} tag="price" selected={isSelected("price")} /></Cell>
                    <Cell header className={classes.topRow}>Producer<SortArrows handleSort={handleSort} tag="producer" selected={isSelected("producer")} /></Cell>
                    <Cell header className={classes.topRow}>Model<SortArrows handleSort={handleSort} tag="model" selected={isSelected("model")} /></Cell>
                </Row>
                {columnFilter &&
                <Row>
                    <Cell className={classes.filterRow}><Input placeholder={"Filter"} onChange={handleFilterNameChange} value={filterName} /></Cell>
                    <Cell className={classes.filterRow}><Input placeholder={"Filter"} onChange={handleFilterCategoryChange} value={filterCategory} /></Cell>
                    <Cell className={classes.filterRow}><Input placeholder={"Filter"} onChange={handleFilterCalibrationIntervalChange} value={filterCalibrationInterval} size="2"/></Cell>
                    <Cell className={classes.filterRow}><Input placeholder={"Filter"} onChange={handleFilterWeightChange} value={filterWeight} size="3" /></Cell>
                    <Cell className={classes.filterRow}><Input placeholder={"Filter"} onChange={handleFilterDimensionsChange} value={filterDimensions} /></Cell>
                    <Cell className={classes.filterRow}><Input placeholder={"Filter"} onChange={handleFilterPriceChange} value={filterPrice} size="3"/></Cell>
                    <Cell className={classes.filterRow}><Input placeholder={"Filter"} onChange={handleFilterProducerChange} value={filterProducer} /></Cell>
                    <Cell className={classes.filterRow}><Input placeholder={"Filter"} onChange={handleFilterModelChange} value={filterModel} /></Cell>
                </Row>
                }
                {products && products.length > 0
                    ? products.slice(0,50).map((row) => (
                        <Row key={row.id}>
                            <Cell>
                                <Link to={match.url + "/" + row.id}>
                                    <Entity title={row.name} deleted={row.deleted} />
                                </Link>
                            </Cell>
                            <Cell>{row.category}</Cell>
                            <Cell>{row.calibrationInterval}</Cell>
                            <Cell>{row.weight}</Cell>
                            <Cell>{row.dimensions}</Cell>
                            <Cell>{row.price}</Cell>
                            <Cell>{row.producer}</Cell>
                            <Cell>{row.model}</Cell>
                        </Row>
                    ))
                    : <Row><Cell colSpan="8">No rows</Cell></Row>
                }{products.length > 50 &&
                    <Row><Cell align="center" colSpan="8">Showing 50 models of {products.length}, please use filter to limit result</Cell></Row>
                }{products.length > 0 &&
                    <Row>
                        <Cell align="center" colSpan="8">
                            <DownloadCSV data={toCSV(products)} fileName={"models-"+filter+".csv"}>
                                <Button circle title="Download result as file">
                                    <Icon>save_alt</Icon>
                                </Button>
                            </DownloadCSV>
                        </Cell>
                    </Row>
                }
            </Table>
        </div>
    );
};

export default withStyles(styles)(withRouter(ProductList));