import React,{useState} from 'react';
import withStyles from "react-jss"
import {Link,withRouter} from "react-router-dom";
import {queryObject,queryColumns} from "../../services/Utils/Query";
import {StorageLink} from '../../components/Link/StorageLink/StorageLink';
import {calibrationText,calibrationTextWithExpire} from '../../services/Utils/Calibration'
import SortArrows from '../../components/SortArrows/SortArrows';
import Button from '../../components/Button/Button';
import Input from "../../components/Input/Input";
import Icon from "../../components/Icon/Icon";

const styles = {
    list: {
        display: "grid",
        gridTemplateColumns: "400px 300px 200px 120px 150px min-content",
    },
    heading: {
        fontWeight: "bold",
        padding: "10px",
        position: "relative",
    },
    rowTight: {
        borderBottom: "1px solid black",
    },
    row: {
        padding: "10px",
        borderTop: "1px solid black",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    filterRow: {
        padding: "1px",
        position: "relative",
        gridGap: "1px",
    },
    filterIcon: {
        cursor: "pointer",
        padding: "10px",
        position: "relative",
    },
    listEmpty: {
        padding: "10px",
        gridColumn: "1/7",
        justifySelf: "center"
    },
    lineDeleted: {
        textDecoration: "line-through",
    },
    rowWrapper: {
        display: "contents",
        position: "relative",
        "&:hover div": {
            backgroundColor: "var(--alert-notification-backgroundColor)",
        },
    },
    iconRow: {
        borderBottom: "1px solid black",
        padding: "5px",
    },
};

const AssetListSelect = ({classes,assets,products,storages,filter,columnFilter,addAssetToManifest}) => {
    const [sort,setSort] = useState(() => (a,b) => (a.date < b.date) ? 1 : -1);
    const [selectedTag,setSelectedTag] = useState("");
    const [added,setAdded] = useState({});

    function handleSort(dynamicSort,tag) {
        setSort(() => dynamicSort)
        setSelectedTag(tag)
    }

    function isSelected(tag) {
        return (selectedTag === tag)
    }

    const [filterProductName,setFilterProductName] = useState("");
    const [filterStorageName,setFilterStorageName] = useState("");
    const [filterSerialNumber,setFilterSerialNumber] = useState("");
    const [filterTagNumber,setFilterTagNumber] = useState("");
    const [filterOwner,setFilterOwner] = useState("");

    const handleFilterProductNameChange = (e) => {
        setFilterProductName(e.target.value)
    };
    const handleFilterStorageNameChange = (e) => {
        setFilterStorageName(e.target.value)
    };
    const handleFilterSerialNumberChange = (e) => {
        setFilterSerialNumber(e.target.value)
    };
    const handleFilterTagNumberChange = (e) => {
        setFilterTagNumber(e.target.value)
    };
    const handleFilterOwnerChange = (e) => {
        setFilterOwner(e.target.value)
    };


    var filterList = {};
    if (filterProductName !== "") {
        filterList["productName"] = filterProductName
    }
    if (filterStorageName !== "") {
        filterList["storageName"] = filterStorageName
    }
    if (filterSerialNumber !== "") {
        filterList["serialNumber"] = filterSerialNumber
    }
    if (filterTagNumber !== "") {
        filterList["tagNumber"] = filterTagNumber
    }
    if (filterOwner !== "") {
        filterList["owner"] = filterOwner
    }


    assets = assets.map(asset => {
        //Add Searchables
        asset.productName = products.get(asset.productId).name;
        asset.storageName = asset.storageId ? storages.get(asset.storageId).name : "In transit";
        asset.categoryText = products.get(asset.productId).category;
        asset.rentalText = asset.rental ? "Rental" : "";
        asset.calibrationText = calibrationText(asset.calibrationDate,products.get(asset.productId).calibrationInterval)
        asset.calibrationTextWithExpire = calibrationTextWithExpire(asset.calibrationDate,products.get(asset.productId).calibrationInterval)
        return asset;
    });

    let assetList = queryObject(assets,filter)
    if (columnFilter) {
        assetList = queryColumns(assetList,filterList)
    }
    return (
        <div className={classes.list}>
            <div className={classes.heading}>Asset<SortArrows handleSort={handleSort} tag="productName" selected={isSelected("productName")} /></div>
            <div className={classes.heading}>Storage<SortArrows handleSort={handleSort} tag="storageName" selected={isSelected("storageName")} /></div>
            <div className={classes.heading}>S/N<SortArrows handleSort={handleSort} tag="serialNumber" selected={isSelected("serialNumber")} /></div>
            <div className={classes.heading}>T/N<SortArrows handleSort={handleSort} tag="tagNumber" selected={isSelected("tagNumber")} /></div>
            <div className={classes.heading}>Owner<SortArrows handleSort={handleSort} tag="owner" selected={isSelected("owner")} /></div>
            <div className={classes.heading}/>
            {columnFilter &&
                <>
                    <div className={classes.filterRow}><Input className={classes.search} placeholder={"Filter"} onChange={handleFilterProductNameChange} value={filterProductName} /></div>
                    <div className={classes.filterRow}><Input className={classes.search} placeholder={"Filter"} onChange={handleFilterStorageNameChange} value={filterStorageName} /></div>
                    <div className={classes.filterRow}><Input className={classes.search} placeholder={"Filter"} onChange={handleFilterSerialNumberChange} value={filterSerialNumber} /></div>
                    <div className={classes.filterRow}><Input className={classes.search} placeholder={"Filter"} onChange={handleFilterTagNumberChange} value={filterTagNumber} /></div>
                    <div className={classes.filterRow}><Input className={classes.search} placeholder={"Filter"} onChange={handleFilterOwnerChange} value={filterOwner} size="4" /></div>
                    <div className={classes.filterIcon}/>
                </>
            }
            {assetList.sort(sort).slice(0,100).map((row,i) => (
                <div key={row.id} className={classes.rowWrapper
                    + " " + (row.deleted && classes.lineDeleted)}>
                    <div className={classes.row}><Link to={"../assets/" + row.id}>{row.productName}</Link></div>
                    <div className={classes.row}>{row.deleted ? "" : <StorageLink {...storages.get(row.storageId)} />}</div>
                    <div className={classes.row}>{row.serialNumber}</div>
                    <div className={classes.row}>{row.tagNumber}</div>
                    <div className={classes.row}>{row.owner}</div>
                    <Button
                        type="button"
                        circle
                        style={{
                            zoom:0.8,
                            placeSelf: "center",
                            marginLeft:10
                        }}
                        disabled={added[row.id] || !row.storageId}
                        onClick={() => {
                            const newAdded = {...added}
                            newAdded[row.id] = true;
                            setAdded(newAdded)
                            addAssetToManifest(products.get(row.productId),assets.get(row.id))
                        }}
                        title={"Add to manifest"}
                    >
                        <Icon>add</Icon>
                    </Button>
                </div>
            ))
            }{assetList.length > 100 &&
                <div className={classes.listEmpty}>Showing 100 of {assetList.length} assets, please use filter to limit result</div>
            }{assetList.length === 0 &&
                <div className={classes.listEmpty}>No assets found</div>
            }
        </div>
    );
};

export default withRouter(withStyles(styles)(AssetListSelect));