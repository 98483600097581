import React, { useState, useContext, useEffect } from 'react'
import Observable from "../Observable/Observable";

class LoadingIndicator extends Observable {
    promises = 0;
    add(...p){
        this.notify(true);
        this.promises += p.length;
        Promise.all(p).finally(()=>{
            this.promises -= p.length;
            if(this.promises === 0) this.notify(false)
        });
    }
    setLoading(){
        let resolver,promise;
        promise = new Promise(resolve => resolver = resolve);
        this.add(promise);
        return ()=>{
            resolver()
        };
    }
}

export const Loading = new LoadingIndicator();

export const LoadingContext = React.createContext(Loading);

export const setLoading = Loading.setLoading.bind(Loading);

export const useLoading = (fn) => {
    const loading = useContext(LoadingContext);

    return (...args)=>{
        const promise = fn(...args);
        if (!promise){
            return;
        }
        loading.add(promise)
    }
};

export const useIsLoading = ()=>{
    const [loading, setLoading] = useState(false);

    const ctx = useContext(LoadingContext);
    useEffect(() => {
        return ctx.subscribe(setLoading)
    }, [ctx]);

    return loading
};

export const LoadingContextConsumer = ({children})=>{
    const isLoading = useIsLoading();
    return children(isLoading)
};

export default LoadingContext
