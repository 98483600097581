import React from 'react';
import {Link} from "react-router-dom";
import withStyles from 'react-jss'
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";

const styles = {
    topBar: {
        gridColumn: "1/3",
        display: "grid",
        gridTemplateColumns: "auto 100px ",
    },
    buttons: {
        textAlign: "right",
        "& > *": {
            marginLeft: 5,
        }
    },
    address: {
        whiteSpace: "pre-wrap",
    }
};

const External = ({classes,external}) => {
    return (
        <div>
            <div className={classes.topBar}>
                {external.deleted ?
                    <h1 style={{textDecoration: "line-through"}}>{external.name} - this external is deleted</h1>
                    : <h1>{external.name}</h1>
                }
                <div className={classes.buttons}>
                    <Link to={"/externals/" + external.id + "/edit"} className={classes.add}>
                        <Button circle title={"Edit " + external.name}>
                            <Icon>edit</Icon>
                        </Button>
                    </Link>
                    {!external.deleted ?
                        <Link to={"/externals/" + external.id + "/delete"} className={classes.add}>
                            <Button circle title={"Delete " + external.name}>
                                <Icon>delete</Icon>
                            </Button>
                        </Link> :
                        <Link to={"/externals/" + external.id + "/restore"} className={classes.add}>
                            <Button circle title={"Restore " + external.name}>
                                <Icon>restore</Icon>
                            </Button>
                        </Link>
                    }
                </div>
            </div>
            <div className={classes.address}>{external.address}</div>
            <Link to={"../storages/" + external.id}><h3>Go to {external.name} storage</h3></Link>
        </div>
    );
};

export default withStyles(styles)(External)