import React from "react";
import Icon from "./Icon";

export default ({children})=>{
    let icon = "insert_drive_file";
    if(children && typeof children === "string") {
        if (children.startsWith("image")) {
            icon = "photo"
        } else if (children.startsWith("video")) {
            icon = "movie"
        } else if (children.startsWith("audio")) {
            icon = "music_note"
        } else if (children.startsWith("text") || children === "application/pdf") {
            icon = "menu_book"
        }
    }

    return <Icon title={children}>{icon}</Icon>
}