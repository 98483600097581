import React,{Component} from "react"

export const withValidation = WrappedElement => {
    return class extends Component {

        constructor(props) {
            super(props);
            this.state = {
                validation: {valid: true, message: ""}
            };
            this.ref = React.createRef()
        }

        handleOnChange = (e)=>{
            this.setState({validation: {valid: this.ref.current.checkValidity(), message: this.ref.current.validationMessage}});
            this.props.onChange && this.props.onChange(e);
        };

        render(){
               return <WrappedElement ref={this.ref} {...this.state} {...this.props} onChange={this.handleOnChange}/>
        }
    }
};
