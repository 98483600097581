import React,{useState} from 'react';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import Textarea from '../../components/Textarea/Textarea';
import Form from "../../components/Form/Form";
import Api from "../../services/Api/Api";
import {useErrorHandler} from "../../services/Utils/useErrorHandler";
import {useLoading} from "../../services/LoadingContext/LoadingContext";

const ExternalEditForm = ({onUpdate,external}) => {

    const {id} = external;
    const [name, setName] = useState(external && external.name);
    const [address, setAddress] = useState(external && external.address);
    const errorHandler = useErrorHandler();

    const handleSubmit = useLoading(async (e) => {
        e.preventDefault();
        try {
            await Api.UpdateExternal({id, name, address});
            onUpdate && onUpdate();
        } catch (e) {
            await errorHandler(e)
        }
    });

    return (
        <Form onSubmit={handleSubmit}>
            <Input title="Name" required minLength="3" defaultValue={name} onChange={e => setName(e.target.value)}/>
            <Textarea title="Address" rows="5" required defaultValue={address} onChange={e => setAddress(e.target.value)}/>
            <Button type="submit">Save</Button>
        </Form>
    );
};

export default ExternalEditForm