import React from 'react';
import Table from '../../components/Table/Table';
import Row from '../../components/Table/Row/Row';
import Cell from '../../components/Table/Row/Cell/Cell';
import Event from "./Event";
import {UserLink} from "../../components/Link/UserLink/UserLink";
import {UsersResource,StoragesResource} from "../../services/Firestore/Resources";
import {ProductsResource} from "../../services/Firestore/Resources";
import {AssetsResource} from "../../services/Firestore/Resources";
import {ManifestsResource} from "../../services/Firestore/Resources";
import {FairDate} from '../../services/Utils/Date';

const EventList = ({events,...other}) => {

    let ignoreList = [
        "manifest.ManifestAssetUpdatedEvent",
        "storage.StorageCreatedEvent",
        "user.UserHomeChangedEvent",
        "manifest.ManifestAssetAddedEvent",
        "manifest.ManifestAssetRemovedEvent",
        "manifest.ManifestUpdatedEvent",
        "user.UserUpdatedEvent",
        "user.UserActivatedEvent",
        "user.UserCreatedEvent",
        "department.DepartmentCreatedEvent",
        "external.ExternalCreatedEvent",
        "ship.ShipCreatedEvent",
        "office.OfficeCreatedEvent",
        "department.DepartmentUpdatedEvent",
        "ship.ShipUpdatedEvent",
        "office.OfficeUpdatedEvent",
        "external.ExternalUpdatedEvent",
    ]

    return (
        <div {...other}>
            <ProductsResource>{products =>
                <AssetsResource>{assets =>
                    <ManifestsResource>{manifests =>
                        <StoragesResource>{storages =>
                            <UsersResource>{users =>
                                <Table>
                                    <Row>
                                        <Cell header>Time</Cell>
                                        <Cell header>User</Cell>
                                        <Cell header>Data</Cell>
                                    </Row>
                                    {events && events.length > 0
                                        ? events.sort((a,b) => (a.Timestamp < b.Timestamp) ? 1 : -1)
                                            .map(row => (
                                                !ignoreList.includes(row.MessageType) ?
                                                    <Row key={row.ID}>
                                                        <Cell>{FairDate(row.Timestamp.toDate(),true)}</Cell>
                                                        <Cell>
                                                            <UserLink {...users.get(row.Data.data && row.Data.data.user)} />
                                                        </Cell>
                                                        <Cell>
                                                            <Event event={row} products={products} assets={assets} manifests={manifests} users={users} storages={storages} />
                                                        </Cell>
                                                    </Row> : null
                                            ))
                                        : <Row><Cell colSpan="3">No rows</Cell></Row>
                                    }
                                </Table>
                            }</UsersResource>
                        }</StoragesResource>
                    }</ManifestsResource>
                }</AssetsResource>
            }</ProductsResource>
        </div>
    );
};

export default EventList