import React from "react";
import {DepartmentsResource} from "../../../services/Firestore/Resources";

export const DepartmentTitle = ({id,name})=>{
    if(!name){
        return <DepartmentsResource>{departments =>
            <DepartmentTitle {...departments.get(id)}/>
        }</DepartmentsResource>
    }

    return name || "Unknown department"
};