import React, {useState} from 'react';
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import Api from '../../services/Api/Api'
import {Link, Redirect, Route, Switch} from "react-router-dom";
import withStyles from "react-jss"
import CreateAccount from "../CreateAccount/CreateAccount";
import ForgotAccount from "../ForgotAccount/ForgotAccount";
import Form from "../../components/Form/Form";
import DelayedRedirect from "../DelayedRedirect/DelayedRedirect"
import {useErrorHandler} from "../../services/Utils/useErrorHandler";
import {useLoading} from "../../services/LoadingContext/LoadingContext";

const styles = {
    root:{
        margin: 'auto',
        maxWidth: 500,
        maxHeight: 500
    },
    altContainer:{
        display: "grid",
        gridGap: "5px",
        alignItems: "center",
        justifyItems: "center",
        gridTemplateColumns: "auto auto",

    }
};

const Login = (props)=>{

    const {classes} = props;
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loggedIn, setLoggedIn] = useState(false);
    const errorHandler = useErrorHandler();

    const handleSubmit = useLoading(async (e) => {
        e.preventDefault();
        try{
            await Api.Auth({email,password});
            setLoggedIn(true)
        }catch(e){
            await errorHandler(e)
        }
    });

    if(loggedIn){
        return <Redirect to="/"/>
    }

    return (
        <div className={classes.root}>
            <Switch>
                <Route path="/login/create">
                    <CreateAccount/>
                </Route>
                <Route path="/login/forgot">
                    <ForgotAccount/>
                </Route>
                <Route path="/login/activated">
                    <DelayedRedirect to='/' duration={3000} step={1000}>{millis =>
                        <p>Your account is activated.. Redirecting you to login page in {millis/1000} second(s).</p>
                    }</DelayedRedirect>
                </Route>
                <Route>
                    <h1>Please login to continue.</h1>
                    <Form onSubmit={handleSubmit}>
                        <Input required title="Email" onChange={e => setEmail(e.target.value)}/>
                        <Input required title="Password" type="password" onChange={e => setPassword(e.target.value)}/>
                        <Button type="submit">Log in</Button>
                    </Form>
                    <hr/>
                    <h1>Alternatively...</h1>
                    <div className={classes.altContainer}>
                        <Link to="/login/create">
                            <Button>I don't have an account</Button>
                        </Link>
                        <Link to="/login/forgot">
                            <Button>Forgot password</Button>
                        </Link>
                    </div>
                </Route>
            </Switch>
        </div>
    );
};

export default withStyles(styles)(Login);