import React,{useState,useEffect} from 'react';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import Form from "../../components/Form/Form";
import Api from '../../services/Api/Api';
import {StoragesResource} from "../../services/Firestore/Resources";
import {StorageTitle} from "../../components/Title/StorageTitle/StorageTitle";
import {useErrorHandler} from "../../services/Utils/useErrorHandler";
import {useLoading} from "../../services/LoadingContext/LoadingContext";

const StorageEditForm = ({onSubmit,storage}) => {

    const {id} = storage;
    const [name,setName] = useState();
    const errorHandler = useErrorHandler();

    const handleUpdate = useLoading(async (e) => {
        e.preventDefault();
        try {
            await Api.UpdateStorage({id, name});
            onSubmit && onSubmit()
        } catch (e) {
            await errorHandler(e)
        }
    });

    useEffect(() => {
        if(storage){
            setName(storage.name)
        }
    }, [storage]);


    return (
        <StoragesResource>{storages =>
            <Form onSubmit={handleUpdate}>
                <div>Update storage in <StorageTitle full={true} {...storages.get(storage.parent)}/></div>
                <Input title="Name" defaultValue={name} onChange={e => setName(e.target.value)}/>
                <Button type="submit">Save</Button>
            </Form>
        }</StoragesResource>
    );
};

export default StorageEditForm