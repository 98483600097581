import React from 'react';
import Select from "../../components/Select/Select";
import Option from "../../components/Select/Option/Option";

const CollectionSelect = ({
                              values,
                              sort = ()=>0,
                              map = (v)=><Option key={v} value={v}>{v}</Option>,
                              filter = ()=>true,
                              onChange,
                              ...other
})=>{
    const handleChange = (v)=>{
        onChange && onChange(v)
    };

    if(!values){
        throw new Error("values required")
    }

    return (
        <Select onChange={handleChange} {...other}>
            {values.filter(filter).sort(sort).map(map)}
        </Select>
    )
};

export default CollectionSelect;