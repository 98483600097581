import React,{useState} from 'react';
import withStyles from "react-jss"
import {Link,withRouter} from "react-router-dom";
import {queryObject,queryColumns} from "../../services/Utils/Query";
import {StorageLink} from '../../components/Link/StorageLink/StorageLink';
import {calibrationText,calibrationTextWithExpire} from '../../services/Utils/Calibration'
import SortArrows from '../../components/SortArrows/SortArrows';
import Icon from '../../components/Icon/Icon';
import {ConditionDefect} from '../../constants';
import Button from '../../components/Button/Button';
import DownloadCSV from "../../components/DownloadCSV/DownloadCSV";
import Input from "../../components/Input/Input";

const styles = {
    list: {
        display: "grid",
        gridTemplateColumns: "repeat(7, auto) 80px",
    },
    heading: {
        fontWeight: "bold",
        padding: "10px",
        position: "relative",
    },
    rowTight: {
        borderBottom: "1px solid black",
    },
    row: {
        padding: "10px",
        borderBottom: "1px solid black",
    },
    filterRow: {
        padding: "1px",
        position: "relative",
        gridGap: "1px",
    },
    filterIcon: {
        cursor: "pointer",
        padding: "10px",
        position: "relative",
    },
    listEmpty: {
        padding: "10px",
        gridColumn: "1/9",
        justifySelf: "center"
    },
    lineDeleted: {
        textDecoration: "line-through",
    },
    rowWrapper: {
        display: "contents",
        position: "relative",
        "&:hover div": {
            backgroundColor: "var(--alert-notification-backgroundColor)",
        },

    },
    danger: {
        "& div": {
            backgroundColor: "var(--alert-danger-backgroundColor)",
        },
    },
    warning: {
        "& div": {
            backgroundColor: "var(--alert-warning-backgroundColor)",
        },
    },
    iconRow: {
        borderBottom: "1px solid black",
        padding: "5px",
    },
    alert: {
        backgroundColor: "var(--alert-danger-backgroundColor)",
    }
};

const AssetList = ({classes,assets,products,storages,filter,columnFilter}) => {
    const [sort,setSort] = useState(() => (a,b) => (a.date < b.date) ? 1 : -1);
    const [selectedTag,setSelectedTag] = useState("");

    function handleSort(dynamicSort,tag) {
        setSort(() => dynamicSort)
        setSelectedTag(tag)
    }

    const toCSV = (assets) =>{
        assets = assets.sort(sort).map(row => [
            products.get(row.productId).name,
            row.storageId ? storages.get(row.storageId).lineageNames.reduce((a, b) => {
                return a + " / " + b
            }) : "In transit",
            row.serialNumber,
            row.tagNumber,
            row.rentalText,
            row.owner,
            row.calibrationText,
            row.condition === ConditionDefect ? "Need Service": "",
        ])
        assets.unshift(['Asset model name','Storage','Serial','Tagnumber','Rental','Owner','Calibration Date','Status'])
        return assets;
    }

    const returnClass = (s) =>{
        if (s.includes("expired")){
            return classes.danger
        }else if(s.includes("Not calibrated")){
            return classes.danger
        }else if(s.includes("days")){
            return classes.warning
        }
        return "";
    }
    
    function isSelected(tag) {
        return (selectedTag === tag)
    }

    const [filterProductName,setFilterProductName] = useState("");
    const [filterStorageName,setFilterStorageName] = useState("");
    const [filterSerialNumber,setFilterSerialNumber] = useState("");
    const [filterTagNumber,setFilterTagNumber] = useState("");
    const [filterRentalText,setFilterRentalText] = useState("");
    const [filterOwner,setFilterOwner] = useState("");
    const [filterCalibrationText,setFilterCalibrationText] = useState("");
    const [filterCondition,setFilterCondition] = useState(4);
    const [filterConditionIcon,setFilterConditionIcon] = useState(<Icon onClick={() => handleFilterConditionChange(4)} title="Click to filter">all_inclusive</Icon>);
    
    const handleFilterProductNameChange = (e) => {
        setFilterProductName(e.target.value)
    };
    const handleFilterStorageNameChange = (e) => {
        setFilterStorageName(e.target.value)
    };
    const handleFilterSerialNumberChange = (e) => {
        setFilterSerialNumber(e.target.value)
    };
    const handleFilterTagNumberChange = (e) => {
        setFilterTagNumber(e.target.value)
    };
    const handleFilterRentalTextChange = (e) => {
        setFilterRentalText(e.target.value)
    };
    const handleFilterOwnerChange = (e) => {
        setFilterOwner(e.target.value)
    };
    const handleFilterCalibrationTextChange = (e) => {
        setFilterCalibrationText(e.target.value)
    };
    
    function handleFilterConditionChange (newState) {
        if (newState === 0) {
            setFilterCondition(4);
            setFilterConditionIcon(<Icon onClick={() => handleFilterConditionChange(4)} title="Click to filter">all_inclusive</Icon>);
        }
        else if (newState === 1) {
            setFilterCondition(0);
            setFilterConditionIcon(<Icon onClick={() => handleFilterConditionChange(0)} title="Showing working assets">check</Icon>);
        }
        else if (newState === 4) {
            setFilterCondition(1);
            setFilterConditionIcon(<Icon onClick={() => handleFilterConditionChange(1)} title="Showing faulty">warning</Icon>);
        }
    };

    var filterList = {};
    if (filterProductName !== "") {
        filterList["productName"] = filterProductName
    }
    if (filterStorageName !== "") {
        filterList["storageName"] = filterStorageName
    }
    if (filterSerialNumber !== "") {
        filterList["serialNumber"] = filterSerialNumber
    }
    if (filterTagNumber !== "") {
        filterList["tagNumber"] = filterTagNumber
    }
    if (filterRentalText !== "") {
        filterList["rentalText"] = filterRentalText
    }
    if (filterOwner !== "") {
        filterList["owner"] = filterOwner
    }
    if (filterCalibrationText !== "") {
        filterList["calibrationText"] = filterCalibrationText    
    }
    if (filterCondition !== 4) {
        filterList["condition"] = filterCondition    
    }
      
   

    assets = assets.map(asset => {
        //Add Searchables
        asset.productName = products.get(asset.productId).name;
        asset.storageName = asset.storageId ? storages.get(asset.storageId).name : "In transit";
        asset.categoryText = products.get(asset.productId).category;
        asset.rentalText = asset.rental ? "Rental" : "";
        asset.calibrationText = calibrationText(asset.calibrationDate,products.get(asset.productId).calibrationInterval)
        asset.calibrationTextWithExpire = calibrationTextWithExpire(asset.calibrationDate,products.get(asset.productId).calibrationInterval)
        return asset;
    });

    let assetList = queryObject(assets,filter)
    if(columnFilter){
        assetList = queryColumns(assetList,filterList)
    }
    return (
        <div className={classes.list}>
            <div className={classes.heading}>Asset<SortArrows handleSort={handleSort} tag="productName" selected={isSelected("productName")} /></div>
            <div className={classes.heading}>Storage<SortArrows handleSort={handleSort} tag="storageName" selected={isSelected("storageName")} /></div>
            <div className={classes.heading}>S/N<SortArrows handleSort={handleSort} tag="serialNumber" selected={isSelected("serialNumber")} /></div>
            <div className={classes.heading}>T/N<SortArrows handleSort={handleSort} tag="tagNumber" selected={isSelected("tagNumber")} /></div>
            <div className={classes.heading}>Rental<SortArrows handleSort={handleSort} tag="rental" selected={isSelected("rental")} /></div>
            <div className={classes.heading}>Owner<SortArrows handleSort={handleSort} tag="owner" selected={isSelected("owner")} /></div>
            <div className={classes.heading}>Calibration Date<SortArrows handleSort={handleSort} tag="calibrationDate" selected={isSelected("calibrationDate")} /></div>
            <div className={classes.heading}>Status<SortArrows handleSort={handleSort} tag="condition" selected={isSelected("condition")} /></div>
            {columnFilter &&
                <>
                <div className={classes.filterRow}><Input className={classes.search} placeholder={"Filter"} onChange={handleFilterProductNameChange} value={filterProductName} /></div>
                <div className={classes.filterRow}><Input className={classes.search} placeholder={"Filter"} onChange={handleFilterStorageNameChange} value={filterStorageName} /></div>
                <div className={classes.filterRow}><Input className={classes.search} placeholder={"Filter"} onChange={handleFilterSerialNumberChange} value={filterSerialNumber} /></div>
                <div className={classes.filterRow}><Input className={classes.search} placeholder={"Filter"} onChange={handleFilterTagNumberChange} value={filterTagNumber} /></div>
                <div className={classes.filterRow}><Input className={classes.search} placeholder={"Filter"} onChange={handleFilterRentalTextChange} value={filterRentalText} size="4"/></div>
                <div className={classes.filterRow}><Input className={classes.search} placeholder={"Filter"} onChange={handleFilterOwnerChange} value={filterOwner} size="4"/></div>
                <div className={classes.filterRow}><Input className={classes.search} placeholder={"Filter"} onChange={handleFilterCalibrationTextChange} value={filterCalibrationText} size="4"/></div>
                <div className={classes.filterIcon}>{filterConditionIcon}</div>
                </>
            }        
            {assetList.sort(sort).slice(0,100).map(row => (
                <div key={row.id} className={classes.rowWrapper 
                + " " + (row.deleted && classes.lineDeleted) 
                + " " + (row.condition === ConditionDefect && classes.danger) 
                + " " + returnClass(row.calibrationTextWithExpire) }>
                <div className={classes.row}> <Link to={"../assets/" + row.id}>{row.productName}</Link></div>
                    <div className={classes.row}>{row.deleted ? "" : <StorageLink {...storages.get(row.storageId)}/>}</div>
                    <div className={classes.row}>{row.serialNumber}</div>
                    <div className={classes.row}>{row.tagNumber}</div>
                    <div className={classes.row}>{row.rentalText}</div>
                    <div className={classes.row}>{row.owner}</div>
            <div className={classes.iconRow}>{
            row.calibrationTextWithExpire.includes("d") && 
            <Icon title={row.calibrationTextWithExpire}>warning</Icon>}
            {row.calibrationText}
            </div>
                    <div className={classes.iconRow}>{row.condition === ConditionDefect && <Icon title="Need Service">warning</Icon>}</div>
                </div>
            ))
            }{assetList.length > 100 &&
            <div className={classes.listEmpty}>Showing 100 of {assetList.length} assets, please use filter to limit result</div>
            }{assetList.length === 0 &&
                <div className={classes.listEmpty}>No assets found</div>
            }{assetList.length > 0 &&
                <div className={classes.listEmpty}>
                    <DownloadCSV data={toCSV(assetList)} fileName={"assetlist-"+filter+".csv"}>
                        <Button circle title="Download result as file">
                            <Icon>save_alt</Icon>
                        </Button>
                    </DownloadCSV>
                </div>
            }
        </div>
    );
};

export default withRouter(withStyles(styles)(AssetList));