import React,{useState} from 'react';
import withStyles from "react-jss";
import {Link} from "react-router-dom";
import {ManifestDraft,ManifestReceived,ManifestSent} from "../../constants";
import {AssetLink} from "../../components/Link/AssetLink/AssetLink";
import {FairDate} from "../../services/Utils/Date";
import {getExpiringDate} from "../../services/Utils/Calibration"
import {AnimatedNumber, EasingFunctions} from "../../components/AnimatedNumber/AnimatedNumber"

const styles = {
    root: {},
    hud: {
        display: "grid",
        gridTemplateRows: "2fr auto auto auto",
    },
    header: {
        display: "grid",
        gridTemplateColumns: "auto auto auto",
    },
    alertNotification: {
        color: "var(--alert-notification-color)",
        backgroundColor: "var(--alert-notification-backgroundColor)",
        borderColor: "var(--alert-notification-borderColor)",
    },
    alertWarning: {
        color: "var(--alert-warning-color)",
        backgroundColor: "var(--alert-warning-backgroundColor)",
        borderColor: "var(--alert-warning-borderColor)",
    },
    alertDanger: {
        color: "var(--alert-danger-color)",
        backgroundColor: "var(--alert-danger-backgroundColor)",
        borderColor: "var(--alert-danger-borderColor)",

    },
    alertButtons: {
        position: "relative",
        padding: ".75rem 1.25rem",
        marginBottom: "5px",
        border: "1px solid",
        textAlign: "center",
        boxShadow: "1px 1px 3px #888",
        cursor: "pointer",
    },
    alertView: {
        position: "relative",
        padding: ".75rem 1.25rem",
        marginBottom: "5px",
        border: "1px solid",
        textAlign: "left",
        boxShadow: "1px 1px 3px #888",
        display: "grid",
        gridTemplateColumns: "auto auto",
        columnGap: "9px",
    },
    notificationPanel: {
        display: "none",
    },
    newTable: {
        display: "grid",
        gridTemplateColumns: "[col-start] auto [col-end]",
        gridTemplateRows: "[row-start] auto [row-end]",
        gridGap: "0px",

        border: "1px solid",
        textAlign: "center",
        boxShadow: "1px 1px 3px #888",
        marginBottom: "10px",
    },
    heading: {
        fontWeight: "bold",
        padding: "10px",
        position: "relative",
    },
    title: {
        gridColumn: "1/8",
        fontWeight: "bold",
        padding: "10px",
        textAlign: "center",
        font: "400 20px sans-serif",
    },
    emptyRow: {
        gridColumn: "1/8",
        fontStyle: "italic",
        padding: "10px",
        textAlign: "center",
    },
    row: {
        padding: "10px",
        position: "relative",
        zIndex: 2,
    },
    rowWrapper: {
        "&:hover div": {
            backgroundColor: "var(--alert-notification-backgroundColor)",
            cursor: "pointer",
        },
        display: "contents",
    },
    menu: {
        justifyItems: "center",
        display: "grid",
        gridTemplateColumns: "150px 200px auto",
        textAlign: "center",
    },
    storageLink: {
        cursor: "pointer",
    },
    counter: {
        fontSize: "90px",
    },
    counterText: {
        fontSize: "40px",
        display: "inline-block"
    },
    alertShortInfo: {
        paddingBottom: "20px",
    },
};

const renderState = (state,stateUpdated) => {
    switch (state) {
        case ManifestDraft:
            return <i className="material-icons" title={"Draft created " + FairDate(stateUpdated.toDate())}>create</i>;
        case ManifestSent:
            return <i className="material-icons" title={"Sent " + FairDate(stateUpdated.toDate())}>local_shipping</i>;
        case ManifestReceived:
            return <i className="material-icons" title={"Received " + FairDate(stateUpdated.toDate())}>done</i>;
        default:
            return <i className="material-icons">error</i>;
    }
}

const Home = ({classes,user,storages,manifests,assets,products,addressables}) => {
    if (!(user && storages && manifests && assets && products && addressables)) {
        throw Error("invalid properties")
    }

    const assetFilter = [["AggregateID","==","atLeastOneFilter"]]// Hvis du ikke har assets eller manifest så viser den alt...denne linjen gir da ingeting.

    const [show,setShow] = useState("header");
    const onlyDepartment = localStorage.getItem(user.id + "onlyDepartment") === "true"
    let totalAssets = 0;
    let homeStorages = [];
    let warningList = [];
    let dangerList = [];
    let notificationList = [];
    let placesToWatch = [];
    let manifestIds = [];
    const timeNow = new Date();
    const day = 86400000;

    function containsDuplicate(array1,array2) {
        for (var i = 0; i < array1.length; i++) {
            if (array2.includes(array1[i])) {
                return true
            }
        }
        return false;
    }

    const prepareWarnings = () => {
        warningList = [];
        dangerList = [];
        notificationList = [];
        let countAssets = 0;
        for (let key in assets) {
            if (assets.hasOwnProperty(key)) {
                if (homeStorages.includes(assets[key].storageId)) {
                    let product = products.get(assets[key].productId)
                    //also add them to eventlog          
                    assetFilter.push(["AggregateID","==",assets[key].id]);
                    countAssets++;
                    var calibrationInterval = product.calibrationInterval;
                    if (calibrationInterval > 0) {
                        if(assets[key].calibrationDate){
                            var expiringDate = getExpiringDate(assets[key].calibrationDate.toDate(),calibrationInterval)
                            if (expiringDate.getTime() < timeNow.getTime()) {
                                dangerList.push(
                                    <React.Fragment key={key}>
                                        <AssetLink product={product} id={assets[key].id} serialNumber={assets[key].serialNumber} />
                                        <div>{" "} expired {FairDate(expiringDate)}</div>
                                    </React.Fragment>
                                );
                            } else if (expiringDate.getTime() < timeNow.getTime() + (day * 90)) {
                                var days = Math.trunc((expiringDate.getTime() - timeNow.getTime()) / day);
                                warningList.push(
                                    <React.Fragment key={key}>
                                        <AssetLink product={product} id={assets[key].id} serialNumber={assets[key].serialNumber} />
                                        <div> will expire in {days} days at {FairDate(expiringDate)}</div>
                                    </React.Fragment>
                                );
                            }
                        }else{
                            dangerList.push(
                                <React.Fragment key={key}>
                                    <AssetLink product={product} id={assets[key].id} serialNumber={assets[key].serialNumber} />
                                    <div>{" "} Not calibrated</div>
                                </React.Fragment>
                            );
                        }
                    }
                    if (assets[key].rental) {
                        notificationList.push(
                            <React.Fragment key={key}>
                                <AssetLink product={product} id={assets[key].id} serialNumber={assets[key].serialNumber} />
                                <div>rented by {assets[key].owner}</div>
                            </React.Fragment>
                        );
                    }
                }
            }
        }
        totalAssets = countAssets;
        return true
    }

    function toggleInfo(colour) {
        if (show === colour) {
            setShow("header")
        } else {
            setShow(colour)
        }
    }

    function addToEventFilter(id) {
        if (!manifestIds.includes(id)) {
            manifestIds.push(id)
            assetFilter.push(["AggregateID","==",id])
        }
        return true
    }

    if (user.home) {
        let parentId = addressables[user.home].parent;
        placesToWatch.push(user.home);
        if (!onlyDepartment) {
            placesToWatch.push(parentId);
            Object.values(addressables)
                .filter(v => v.parent === parentId)
                .map((row) => (placesToWatch.push(row.id)))
        }
        storages
            .filter(v => containsDuplicate(v.lineage,placesToWatch))
            .map((v) => (homeStorages.push(v.id)))
    }

    function getHomeName() {
        const userHome = addressables[user.home];
        if (!userHome){
            return "N/A"
        }
        if (onlyDepartment) {
            return userHome.name
        }
        return addressables[userHome.parent].name
    }

    function goToHomeStorage(){
        const userHome = addressables[user.home];
        if (onlyDepartment) {
            //history.push('/storages/'+userHome.id)  //hvorfor får jeg ikke history til å fungere i home?
            window.location ="storages/"+userHome.id
        }else{
            //history.push('/storages/'+userHome.parent)
            window.location ="storages/"+userHome.parent
        }
    }

    function checkIfMoreThanTwoWeeksOld(time) {
        return time.toDate().getTime() < timeNow.getTime() - (day * 14)
    }
    const outgoingManifest = Object.values(manifests)
        .filter(v => placesToWatch.includes(v.senderId) && !(v.state === ManifestReceived && checkIfMoreThanTwoWeeksOld(v.stateUpdated)) && addToEventFilter(v.id))
        .sort((a,b) => (a.date < b.date) ? 1 : -1)

    const incomingManifest = Object.values(manifests)
        .filter(v => placesToWatch.includes(v.recipientId) && v.state !== ManifestDraft && !(v.state === ManifestReceived && checkIfMoreThanTwoWeeksOld(v.stateUpdated)) && addToEventFilter(v.id))
        .sort((a,b) => (a.date < b.date) ? 1 : -1)

    if (user.home !== "") {
        return (
            <div>
                <div>
                    {prepareWarnings() &&
                        <>
                            <div className={classes.menu}>
                                <div className={classes.storageLink} onClick={goToHomeStorage}>
                                    <div className={classes.counter}>
                                        <AnimatedNumber value={totalAssets} easingFn={EasingFunctions.easeOutQuad} duration={3000}/>
                                    </div>
                                    <div className={classes.counterText}>assets</div>
                                </div>
                                <div className={classes.alertShortInfo}>
                                    {Object.keys(warningList).length > 0 &&
                                        <div className={classes.alertButtons + " " + classes.alertWarning} onClick={() => {toggleInfo("warning")}}>
                                            {Object.keys(warningList).length} up for calibration</div>
                                    }
                                    {Object.keys(dangerList).length > 0 &&
                                        <div className={classes.alertButtons + " " + classes.alertDanger} onClick={() => {toggleInfo("alert")}}>
                                            {Object.keys(dangerList).length} out of calibration</div>
                                    }
                                    {Object.keys(notificationList).length > 0 &&
                                        <div className={classes.alertButtons + " " + classes.alertNotification} onClick={() => {toggleInfo("notification")}}>
                                            {Object.keys(notificationList).length}  rentals</div>
                                    }
                                </div>
                                <div>

                                    <> {show === "header" && <div className={classes.counterText}>Home of {getHomeName()}</div>}
                                        <div className={show === "warning" ? classes.alertView + " " + classes.alertWarning : classes.notificationPanel}>{warningList}</div>
                                        <div className={show === "alert" ? classes.alertView + " " + classes.alertDanger : classes.notificationPanel}>{dangerList}</div>
                                        <div className={show === "notification" ? classes.alertView + " " + classes.alertNotification : classes.notificationPanel}>{notificationList}</div>
                                    </>
                                </div>
                            </div>
                        </>}
                </div>
                <div className={classes.newTable}>
                    <div className={classes.title}>Incoming Manifests</div>
                    {incomingManifest.length > 0 ?
                        <>
                            <div className={classes.heading}>Date</div>
                            <div className={classes.heading}>Name</div>
                            <div className={classes.heading}>Project</div>
                            <div className={classes.heading}>Reference</div>
                            <div className={classes.heading}>Sender</div>
                            <div className={classes.heading}>Recipient</div>
                            <div className={classes.heading}>Status</div>
                            {incomingManifest.map((row) => (
                                <Link to={"manifests/" + row.id} key={row.id} className={classes.rowWrapper} >
                                    <div className={classes.row}>{FairDate(row.date.toDate())}</div>
                                    <div className={classes.row}>{row.name}</div>
                                    <div className={classes.row}>{row.project}</div>
                                    <div className={classes.row}>{row.reference}</div>
                                    <div className={classes.row}>{addressables[row.senderId].name}</div>
                                    <div className={classes.row}>{addressables[row.recipientId].name}</div>
                                    <div className={classes.row}>{renderState(row.state,row.stateUpdated)}</div>
                                </Link>
                            ))}
                        </>
                        : <div className={classes.emptyRow}>No incoming. None received last two weeks</div>
                    }
                </div>
                <div className={classes.newTable}>
                    <div className={classes.title}>Outgoing Manifests</div>
                    {outgoingManifest.length > 0 ?
                        <>
                            <div className={classes.heading}>Date</div>
                            <div className={classes.heading}>Name</div>
                            <div className={classes.heading}>Project</div>
                            <div className={classes.heading}>Reference</div>
                            <div className={classes.heading}>Sender</div>
                            <div className={classes.heading}>Recipient</div>
                            <div className={classes.heading}>Status</div>
                            {outgoingManifest.map((row) => (
                                <Link to={"manifests/" + row.id} key={row.id} className={classes.rowWrapper} >
                                    <div className={classes.row}>{FairDate(row.date.toDate())}</div>
                                    <div className={classes.row}>{row.name}</div>
                                    <div className={classes.row}>{row.project}</div>
                                    <div className={classes.row}>{row.reference}</div>
                                    <div className={classes.row}>{addressables[row.senderId].name}</div>
                                    <div className={classes.row}>{addressables[row.recipientId].name}</div>
                                    <div className={classes.row}>{renderState(row.state,row.stateUpdated)}</div>
                                </Link>
                            ))}
                        </>
                        : <div className={classes.emptyRow}>None in transit, none arrived recipient last two weeks..</div>
                    }
                </div>
                <div className={classes.newTable}>
                    <div>
                        <h3>Events</h3>
                        <div>......</div>
                    </div>
                </div>
            </div>
        );
    }
    else {
        return (<h3>Welcome {user.name}<br /><Link to="my-account">Click here to select a home department</Link></h3>);
    }
};
export default withStyles(styles)(Home);
