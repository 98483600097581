import React from "react";
import {ManifestResource} from "../../../services/Firestore/Resources";

export const ManifestTitle = ({id,name,manifestNumber})=>{
    if(!name || !manifestNumber){
        return <ManifestResource id={id}>{manifest =>
            <ManifestTitle {...manifest}/>
        }</ManifestResource>
    }

    return `[${manifestNumber}] ${name}`
};

