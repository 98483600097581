import React,{useState,useEffect} from "react"
import ReactDOM from "react-dom"

const Window = ({children, onClose, features=''})=>{
    const [externalWindow, setExternalWindow] = useState(null);
    const [externalEl, setExternalEl] = useState(null);

    useEffect(() => {
        const w = window.open('','',features);
        if(!w){
             console.log("Failed to open new window, client should enable popups!");
             return;
        }
        w.onbeforeunload = onClose;
        const el = window.document.createElement("div");
        w.document.body.appendChild(el);

        setExternalWindow(w);
        setExternalEl(el);

        return ()=>{
            w.close()
        }
    }, [features,onClose]);

    return externalWindow && externalEl && ReactDOM.createPortal(
        React.cloneElement(children,{window:externalWindow}),
        externalEl
    );
};

export default Window