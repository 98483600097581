import React,{useState,useEffect,useRef} from 'react';
import {createUseStyles} from 'react-jss'
import Button from '../../components/Button/Button';
import ProductSelect from "../Select/ProductSelect";
import ProductAddForm from '../Product/ProductAddForm';
import AssetAddForm from '../Asset/AssetAddForm';
import AssetCommentList from '../Asset/AssetCommentList'
import AssetAddCommentForm from '../Asset/AssetAddCommentForm';
import Product from '../Product/EmbeddedProduct';
import {
    FirestoreResource,
    FirestoreQuery,
    ProductResource,
    AssetResource
} from '../../services/Firestore/Resources';
import FilesAddForm from '../Files/FilesAddForm';
import FilesList from '../Files/FilesList';
import Pane from "../../components/Tab/Pane/Pane";
import Tab from "../../components/Tab/Tab";
import {useFlash} from "../../components/Flash/Flash";
import * as firebase from "firebase";
import {useCurrentUser} from "../../services/AuthContext/AuthContext";

const useStyles = createUseStyles({
    root: {
        minHeight: 600,
        minWidth: 800
    },
});

const ManifestCreateNewAssetForm = ({products, assets, onClose,onAddToManifest}) => {
    const classes = useStyles();
    const [productSelected,setProductSelected] = useState(null);
    const [assetCreatedId,setAssetCreatedId] = useState(null);
    const [showHeaders,setShowHeaders] = useState(true);
    const [productId,setProductId] = useState(null);

    const user = useCurrentUser();

    const flash = useFlash();
    const tab = useRef(null);


    useEffect(() => {
        if(!products || !productId) return;
        //HACK (for some reason listening on products is not working as intended)
        let interval;
        interval = setInterval(()=>{
            const product = products.get(productId);
            if(!product){
                return;
            }
            setProductSelected(product);
            clearInterval(interval);
        },250);
        return ()=>clearInterval(interval)
    }, [products, productId]);


    const handleCreatedProduct = (productId) => {
        setProductId(productId);
        flash.notification(`Model has been created`);
        tab.current.setSelected(0);
    };

    const handleSubmitAsset = function (newAssetId) {
        setShowHeaders(false);
        setAssetCreatedId(newAssetId);
    };

    const addAssetToManifest = function (asset) {
        setShowHeaders(true);
        setAssetCreatedId(null);
        setProductId(null);
        onAddToManifest(productSelected,asset);
        onClose && onClose();
    };

    return <div className={classes.root}>
        {showHeaders && <>
            <h1>Creating a new asset</h1>
            <Tab stretchTabs ref={tab}>
                <Pane title={<h2>Make asset from existing model</h2>}>
                    <ProductSelect products={products} value={productId} required
                                   onChange={({value}) => setProductId(value)}/>
                    {/*https://trello.com/c/PThB9sbp/312-productselect-returnerer-id-by-default-la-den-returnere-hele-produktet*/}
                    {productId &&
                    <>
                        <ProductResource id={productId}>{product =>
                            <Product product={product}/>
                        }</ProductResource>
                        <div>
                            <h3>Please provide asset information</h3>
                            <AssetAddForm productId={productId} storageId={user.home} onSubmit={handleSubmitAsset}/>
                        </div>
                    </>
                    }
                </Pane>
                <Pane title={<h2>Create a new model</h2>}>
                    <ProductAddForm onSubmit={handleCreatedProduct}/>
                </Pane>
            </Tab>
        </>
        }
        {
            assetCreatedId &&
            productId &&
            <ProductResource id={productId}>{product =>
                <AssetResource id={assetCreatedId}>{() =>
                    <>
                        <h1>Asset {product.name} now created</h1>
                        <p>Do you want to add comments or files?</p>
                        <div>
                            <div>
                                <p><strong>Comments:</strong></p>
                                <AssetCommentList assetId={assetCreatedId} user={user} />
                                <AssetAddCommentForm assetId={assetCreatedId} />
                            </div>
                            <div>
                                <p><strong>Upload files:</strong></p>
                                <FirestoreResource query={FirestoreQuery.get(
                                    firebase.firestore()
                                        .collection("files")
                                        .where("parent","==",assetCreatedId)
                                )}>{files => files && files.length > 0 &&
                                    <FilesList files={files.filter(f => f.parent === assetCreatedId)} />
                                }</FirestoreResource>
                                <FilesAddForm parent={assetCreatedId} />
                            </div>
                        </div>
                        <hr/>
                        <Button type="button" onClick={() => addAssetToManifest(assets.get(assetCreatedId))}>Add asset to manifest</Button>
                    </>
                }</AssetResource>
            }</ProductResource>

        }

    </div>
};

export default ManifestCreateNewAssetForm;
