import {useState,useEffect} from 'react';

export const EasingFunctions = {
    // no easing, no acceleration
    linear: function (t) { return t },
    // accelerating from zero velocity
    easeInQuad: function (t) { return t*t },
    // decelerating to zero velocity
    easeOutQuad: function (t) { return t*(2-t) },
    // acceleration until halfway, then deceleration
    easeInOutQuad: function (t) { return t<.5 ? 2*t*t : -1+(4-2*t)*t },
    // accelerating from zero velocity
    easeInCubic: function (t) { return t*t*t },
    // decelerating to zero velocity
    easeOutCubic: function (t) { return (--t)*t*t+1 },
    // acceleration until halfway, then deceleration
    easeInOutCubic: function (t) { return t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1 },
    // accelerating from zero velocity
    easeInQuart: function (t) { return t*t*t*t },
    // decelerating to zero velocity
    easeOutQuart: function (t) { return 1-(--t)*t*t*t },
    // acceleration until halfway, then deceleration
    easeInOutQuart: function (t) { return t<.5 ? 8*t*t*t*t : 1-8*(--t)*t*t*t },
    // accelerating from zero velocity
    easeInQuint: function (t) { return t*t*t*t*t },
    // decelerating to zero velocity
    easeOutQuint: function (t) { return 1+(--t)*t*t*t*t },
    // acceleration until halfway, then deceleration
    easeInOutQuint: function (t) { return t<.5 ? 16*t*t*t*t*t : 1+16*(--t)*t*t*t*t }
};

export const AnimatedNumber = ({value, duration = 1000, refreshRate = 50, easingFn = EasingFunctions.easeOutQuad })=>{
    const [previous,setPrevious] = useState(0);
    const [current,setCurrent] = useState(0);
    const [final,setFinal] = useState(0);
    const [next,setNext] = useState(0);

    useEffect(() => {
        const timestamp = Date.now();
        let handler;

        handler = setInterval(()=>{
            const elapsed = Date.now()-timestamp;
            const percent = Math.min(1.0,elapsed/duration);

            const nextCurrent = previous+Math.ceil((next-previous)*easingFn(percent));
            setCurrent(nextCurrent);
            if(percent === 1.0){
                clearInterval(handler);
                setFinal(nextCurrent);
            }
        },refreshRate);
        return ()=>{
            clearInterval(handler);
        }
    }, [easingFn,refreshRate,duration,previous,next]);

    useEffect(()=>{
        if (final === value) {
            return
        }
        setPrevious(final);
        setNext(value)
    },[value,final]);

    return current;
};