import React, {useState} from 'react';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import Textarea from '../../components/Textarea/Textarea';
import Form from "../../components/Form/Form";
import Api from '../../services/Api/Api';
import withStyles from "react-jss";
import {useLoading} from "../../services/LoadingContext/LoadingContext";
import {useErrorHandler} from "../../services/Utils/useErrorHandler";

const styles = {
    root: {
        display: "grid",
        gridTemplateColumns: "auto auto"
    },
    fullWidth: {
        gridColumn: "1/3"
    }
};

const ProductEditForm = ({classes,onUpdate, product}) => {

    const {id} = product;
    const [name, setName] = useState(product && product.name);
    const [category, setCategory] = useState(product && product.category);
    const [calibrationInterval, setCalibrationInterval] = useState(product && product.calibrationInterval);
    const [description, setDescription] = useState(product && product.description);
    const [weight, setWeight] = useState(product && product.weight);
    const [dimensions, setDimensions] = useState(product && product.dimensions);
    const [price, setPrice] = useState(product && product.price);
    const [producer, setProducer] = useState(product && product.producer);
    const [model, setModel] = useState(product && product.model);
    const errorHandler = useErrorHandler();

    const handleSubmit = useLoading( async (e) => {
        e.preventDefault();
        try {
            await Api.UpdateProduct({
                id,
                name,
                category,
                calibrationInterval,
                description,
                weight,
                dimensions,
                price,
                producer,
                model,
            });
            onUpdate && onUpdate(id);
        } catch (e) {
            await errorHandler(e)
        }
    });

    return (
        <div>
            <h3>Edit {name}</h3>
            <Form onSubmit={handleSubmit} className={classes.root}>
                <Input title="Name" required defaultValue={name} onChange={e => setName(e.target.value)} />
                <Input title="Category" defaultValue={category} onChange={e => setCategory(e.target.value)} />
                <Textarea className={classes.fullWidth} title="Description" defaultValue={description} onChange={e => setDescription(e.target.value)} />
                <Input title="Calibration interval (months)" type="number" defaultValue={calibrationInterval} onChange={e => setCalibrationInterval(e.target.valueAsNumber)} />
                <Input title="Weight (kg)" defaultValue={weight} min={0} type="number" onChange={e => setWeight(e.target.valueAsNumber)} />
                <Input title="Dimensions" defaultValue={dimensions} onChange={e => setDimensions(e.target.value)} />
                <Input title="Price" defaultValue={price} min={0} type="number" onChange={e => setPrice(e.target.valueAsNumber)} />
                <Input title="Producer" defaultValue={producer} onChange={e => setProducer(e.target.value)} />
                <Input title="Model" defaultValue={model} onChange={e => setModel(e.target.value)} />
                <div className={classes.fullWidth} style={{justifySelf:"center"}}><Button type="submit">Save</Button></div>
            </Form>
        </div>
    );
};

export default withStyles(styles)(ProductEditForm)