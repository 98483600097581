import React from "react"
import Button from "../Button/Button";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo) {

            // Error path
            return (
                <div style={{padding: 10}}>
                    <h2>Ups, Something went wrong.</h2>
                    <p>Unfortunately errors like these do happen sometime.</p>
                    <p>If the problem persists. please contact a system administrator and provide the following information:</p>
                    <details style={{ whiteSpace: 'pre-wrap' }}>
                        {this.state.error && this.state.error.toString()}
                        <br />
                        {this.state.errorInfo.componentStack}
                    </details>
                    <p>Additionally please provide a details surrounding the circumstances leading up to the error.</p>
                    <p>For now, try to reload the page or try again later</p>
                    <Button onClick={()=>{
                        window.location.reload();
                        return false;
                    }}>Reload</Button>
                </div>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}


export default ErrorBoundary;