import firebase from 'firebase/app';

function Api(){
    const toJson = response => {
        const cl = response.headers.get("Content-Length");
        if (cl != null && parseInt(cl) === 0){
            return null
        }
        return response.json().then(data => {
            return data
        });
    };
    const fetch = async (input, init) => {
        if (!init){
            init = {}
        }

        if (!init.headers){
            init.headers = new Headers()
        }

        if (firebase.auth().currentUser !== null){
            const token = await firebase.auth().currentUser.getIdToken();
            init.headers.set("Authorization", "Bearer "+token);
        }

        return await window.fetch(input,init).then((response)=>{
            if(!response.ok){
                return Promise.reject(response)
            }

            if (response.headers){
                if (response.headers.get("Content-Type") === "application/json"){
                    return toJson(response);
                }
            }
            return response
        })
    };

    const req = (path,method,body)=>{
        const headers = new Headers();
        if (body){
            headers.append("Content-Type","application/json")
        }
        return fetch(path,{
            method: method,
            body: body ? JSON.stringify(body) : null,
            headers: headers,
        });
    };

    const GET = (path)=>{
        return req(path,"GET");
    };

    const POST = (path, body)=>{
        return req(path,"POST",body);
    };

    const PUT = (path, body)=>{
        return req(path,"PUT",body);
    };

    const DELETE = (path)=>{
        return req(path,"DELETE");
    };

    return {
        Auth({email, password}){
            return POST("/api/v1/auth",{
                email: email,
                password: password
            }).then(data => {
                return firebase.auth()
                    .signInWithCustomToken(data.token)
                    .then(user => firebase.auth().updateCurrentUser(user.user))
            });
        },
        CreateUser({email, password,name}) {
            return POST("/api/v1/user",{email,password,name});
        },
        UpdateUser({id, name}) {
            return PUT("/api/v1/user/"+id,{name})
        },
        UpdatePassword({id, password}) {
            return PUT("/api/v1/user/"+id+"/update-pwd",{password})
        },
        ForgotPassword({email}) {
            return POST("/api/v1/user/reset-pwd",{email});
        },
        ChangeUserHome({home}){
            return PUT("/api/v1/user/home",{home})    
        },
        ChangeUsersRole({id, roles}){
            return PUT("/api/v1/user/"+id+"/roles",{roles})
        },
        ActivateUser({id}){
            return GET("/api/v1/user/"+id+"/activate")
        },
        CreateShip({name,address}){
            return POST("/api/v1/ship",{name,address})    
        },
        UpdateShip({id,name,address}){
            return PUT("/api/v1/ship/"+id,{name,address})    
        },
        DeleteShip({id}){
            return DELETE("/api/v1/ship/"+id)    
        },
        RestoreShip({id}){
            return POST("/api/v1/ship/"+id)    
        },
        CreateDepartment({name,parent,parentType}){
            return POST("/api/v1/department",{name,parent,parentType})    
        },
        UpdateDepartment({id,name}){
            return PUT("/api/v1/department/"+id,{name})    
        },
        DeleteDepartment({id}){
            return DELETE("/api/v1/department/"+id)    
        },
        RestoreDepartment({id}){
            return POST("/api/v1/department/"+id)    
        },
        CreateOffice({name,address}){
            return POST("/api/v1/office",{name,address})    
        },
        UpdateOffice({id,name,address}){
            return PUT("/api/v1/office/"+id,{name,address})    
        },
        DeleteOffice({id}){
            return DELETE("/api/v1/office/"+id)    
        },
        RestoreOffice({id}){
            return POST("/api/v1/office/"+id)    
        },
        CreateExternal({name,address}){
            return POST("/api/v1/external",{name,address})    
        },
        UpdateExternal({id,name,address}){
            return PUT("/api/v1/external/"+id,{name,address})    
        },
        DeleteExternal({id}){
            return DELETE("/api/v1/external/"+id)    
        },
        RestoreExternal({id}){
            return POST("/api/v1/external/"+id)    
        },
        CreateStorage({name,parent}){
            return POST("/api/v1/storage",{name,parent})
        },
        UpdateStorage({id,name}){
            return PUT("/api/v1/storage/"+id,{name})    
        },
        DeleteStorage({id}){
            return DELETE("/api/v1/storage/"+id)    
        },
        //TODO RestoreStorage is not used, remove it from backend and frontend?
        RestoreStorage({id}){
            return POST("/api/v1/storage/"+id)    
        },
        CreateProduct(
            {
                name,              
                category,          
                calibrationInterval,
                description,       
                weight,            
                dimensions,        
                price,             
                producer,          
                model,
                container,
            }
        ){
            return POST("/api/v1/product",{
                name,              
                category,          
                calibrationInterval,
                description,       
                weight,            
                dimensions,        
                price,             
                producer,          
                model,
                container,
            })    
        },
        UpdateProduct(
            {
                id,
                name,              
                category,          
                calibrationInterval,
                description,       
                weight,            
                dimensions,        
                price,             
                producer,          
                model,             
            }
        ){
            return PUT("/api/v1/product/"+id,{
                name,              
                category,          
                calibrationInterval,
                description,       
                weight,            
                dimensions,        
                price,             
                producer,          
                model,             
            })    
        },
        DeleteProduct({id}){
            return DELETE("/api/v1/product/"+id)    
        },
        RestoreProduct({id}){
            return POST("/api/v1/product/"+id)    
        },
        AddProductFile({id,files}){
            const data = new FormData();
            for (let i = 0; i < files.length; i++){
                data.append("file",files[i]);
            }

            return fetch("/api/v1/product/"+id+"/file",{
                method: "POST",
                body: data,
            });
        },
        UpdateProductImage({id,file}){
            const data = new FormData();
            data.append("file",file);

            return fetch("/api/v1/product/"+id+"/image",{
                method: "PUT",
                body: data,
            });
        },
        CreateAsset(
            {
                productId,
                storageId,
                serialNumber,
                tagNumber,
                rental,
                owner,
                calibrationDate,
            }
        ){
            return POST("/api/v1/asset",{
                productId,
                storageId,
                serialNumber,
                tagNumber,
                rental,
                owner,
                calibrationDate,
            })
        },
        UpdateAsset(
            {
                id,
                productId,
                serialNumber,
                tagNumber,
                rental,
                owner,
                calibrationDate,
            }
        ){
            return PUT("/api/v1/asset/"+id,{
                productId,
                serialNumber,
                tagNumber,
                rental,
                owner,
                calibrationDate,
            })    
        },
        DeleteAsset({id}){
            return DELETE("/api/v1/asset/"+id)    
        },
        RestoreAsset({id}){
            return POST("/api/v1/asset/"+id)    
        },
        AddAssetComment({id,comment}){
            return POST("/api/v1/asset/"+id+"/comment",{comment})    
        },
        DeleteAssetComment({id,commentId}){
            return DELETE("/api/v1/asset/"+id+"/comment/"+commentId)     
        },
        AddAssetFile({id,file}){
            const data = new FormData();
            data.append("file",file);

            return fetch("/api/v1/asset/"+id+"/file",{
                method: "POST",
                body: data,
            });
        },
        InlineFile({fileId}){
            return fetch("/api/v1/file/"+fileId,{
                method: "GET",
                redirect: "follow",
                mode: "cors"
            })
        },
        DownloadFile({fileId}){
            const headers = new Headers();
            headers.set("X-No-Redirect","1");

            return fetch("/api/v1/file/"+fileId,{
                method: "GET",
                headers,
            }).then((data)=>{
                const link = document.createElement('a');
                link.href = typeof data === "string" ? data : data.url;
                setTimeout(function(){
                    link.click();
                },100)
            })
        },
        DeleteFile({id}){
            return DELETE("/api/v1/file/"+id)
        },
        MoveAsset({id, storageId}) {
            return POST("/api/v1/asset/"+id+"/move",{storageId})
        },
        CalibrateAsset({id,calibrationDate}){
            return PUT("/api/v1/asset/"+id+"/calibrate",{calibrationDate})    
        },
        SetAssetCondition({id,condition}){
            return PUT("/api/v1/asset/"+id+"/condition",{condition})    
        },
        CreateManifest(
            {
                project,
                reference,
                name,
                recipientAddress,
                recipientId,
                senderAddress,
                senderId,
                additionalNotes,
            }
        ){
            return POST("/api/v1/manifest",{
                project,
                reference,
                name,
                recipientAddress,
                recipientId,
                senderAddress,
                senderId,
                additionalNotes,
            })
        },
        DuplicateManifest({id}){
            return POST("/api/v1/manifest/"+id)
        },
        UpdateManifest(
            {
                id,
                project,
                reference,
                name,
                recipientAddress,
                recipientId,
                senderAddress,
                senderId,
                additionalNotes,
                currency,
            }
        ){
            return PUT("/api/v1/manifest/"+id,{
                project,
                reference,
                name,
                recipientAddress,
                recipientId,
                senderAddress,
                senderId,
                additionalNotes,
                currency,
            })
        },
        AddManifestItem(
            {
                manifestId,
                assetId,
                serialNumber,
                boxNo,
                quantity,
                description,
                owner,
                rental,
                weight,
                dimensions,
                value,
            }
        ){
            return POST("/api/v1/manifest/"+manifestId+"/item",{
                assetId,
                serialNumber,
                boxNo,
                quantity,
                description,
                owner,
                rental,
                weight,
                dimensions,
                value,
            })
        },
        UpdateManifestItem(
            {
                id,
                manifestId,
                assetId,
                serialNumber,
                boxNo,
                quantity,
                description,
                owner,
                rental,
                weight,
                dimensions,
                value,
            }
        ){
            return PUT("/api/v1/manifest/"+manifestId+"/item/"+id,{
                assetId,
                serialNumber,
                boxNo,
                quantity,
                description,
                owner,
                rental,
                weight,
                dimensions,
                value,
            })
        },
        RemoveManifestItem(
            {
                id,
                manifestId,
            }
        ){
            return DELETE("/api/v1/manifest/"+manifestId+"/item/"+id)
        },
        SendManifest({id}){
            return POST("/api/v1/manifest/"+id+"/send")
        },
        ReceiveManifest({id}){
            return POST("/api/v1/manifest/"+id+"/receive")
        },
        Import({file}){
            const data = new FormData();
            data.append("file",file);

            return fetch("/api/v1/import/products+assets.csv",{
                method: "POST",
                body: data,
            });
        },
        Export(){
            return POST("/api/v1/export")
        },
    }
}

export default new Api();
