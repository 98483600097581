import React,{useContext} from 'react';

export class Role extends Number {

    addRole(role) {
        return this | role;
    };

    removeRole(role) {
        if (this & role) {
            return this ^ role;
        }
        return this;
    };
}


export class User {
    roles;

    constructor(user) {
        Object.assign(this,user);
        this.roles = new Role(user.roles);
    }

    hasRole(role) {
        return !!(this.roles & role);
    };

}

export const Editor = new Role(1);
export const Admin = new Role(1 << 1);
export const ManifestEditor = new Role(1 << 2);

const AuthContext = React.createContext(null);

export const useCurrentUser = ()=>{
    return useContext(AuthContext);
};

export default AuthContext;