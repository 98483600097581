import React, {useLayoutEffect, useState} from 'react';
import {
    AssetsCollection,
    ProductsCollection,
    StoragesCollection, useDecoratedManifest,
    useResource
} from "../../services/Firestore/Resources";
import {createUseStyles} from "react-jss";
import Window from "../../components/Window/Window";
import {ManifestPrintPage} from "./ManifestPrintPage";
import {useObjectURL} from "../Product/ProductImage";
import manifestLogo from "../../assets/images/manifest-logo.png"
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import {ManifestEditor, useCurrentUser} from "../../services/AuthContext/AuthContext";
import {useHistory, Prompt} from "react-router-dom";
import {FairDate} from "../../services/Utils/Date";
import {AssetOwnerType} from "../../constants";
import Confirmation from "../../components/Confirmation/Confirmation";
import Modal from "../../components/Modal/Modal";
import ManifestCreateNewAssetForm from "./ManifestCreateNewAssetForm";
import ManifestFindAssetForm from "./ManifestFindAssetForm";
import {useFlash} from "../../components/Flash/Flash";
import Api from "../../services/Api/Api";
import {useLoading} from "../../services/LoadingContext/LoadingContext";

const useManifestStyles = createUseStyles({
        gridContainer: {
            display: "grid",
            gridTemplateColumns: "auto",
            gridTemplateRows: "min-content auto",
            gridTemplateAreas: '"header" "body" "footer"',
            "& > * > *":{
                padding: 4,
            },
            fontSize: "12px",
        },
        header: {
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr min-content",
            gridTemplateRows: "auto auto",
            gridTemplateAreas:
                '"title title buttons buttons" "recipient additionalNotes sender details"',
            gridArea: "header",
        },
        title: {
            gridArea: "title",
            fontSize: "27px",
            textTransform: "uppercase",
            fontWeight: "bold",
        },
        buttons: {
            gridArea: "buttons",
            justifySelf: "right",
            alignSelf: "top",
            "& > *": {
                marginLeft: 3,
            }
        },
        recipient: {
            gridArea: "recipient",
        },
        additionalNotes: {
            gridArea: "additionalNotes",
        },
        sender: {
            gridArea: "sender",
        },
        details: {
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridAutoRows: "auto",
            gridTemplateAreas: '". ."',
            gridArea: "details",
            "& > *":{
                borderBottom: "1px solid lightgray",
                minHeight: 15,
                padding: 2,
                boxSizing: "content-box"
            },
            "& > *:nth-child(2n+1)":{
                fontWeight: "bold"
            },
            "& > *:nth-child(2n)":{
                textAlign: "right"
            },
            alignItems: "center",
            whiteSpace: "nowrap"
        },
        preformatted: {
            whiteSpace: "pre-line",
        },
        body: {
            display: "grid",
            gridAutoRows: "min-content",
            gridTemplateColumns: "repeat(4,min-content) auto auto auto min-content repeat(3,auto)",
            gridArea: "body",
            alignItems: "center",
            gridGap: 5,
        },
        footer: {
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr",
            gridTemplateRows: "1fr",
            gridTemplateAreas: '". . . ."',
            gridArea: "footer",
        },
        itemHeader: {
            fontWeight: "bold",
        },
        totalHeader: {
            fontWeight: "bold",
            textAlign: "right",
        },
        totalValue: {
            fontWeight: "bold",
        },
        infoBox: {
            gridColumn: "1/12",
            margin: "0 auto",
            padding:" 20px",
            fontSize:"20px",
            fontStyle: "italic",
        },
        actions: {
            gridColumn: "2/4",
            padding: 20,
            justifySelf: "center"
        }
});

const Draft = 0;
const Sent = 1;
const Received = 2;

const Proforma = "PROFORMA INVOICE";
const Shipping = "SHIPPING MANIFEST";

export const Manifest = ({manifestId, onRemoveItem, onUpdateItem, onSend, onReceive, onAddItem})=>{
    const classes = useManifestStyles();
    const logo = useObjectURL(manifestLogo);
    const history = useHistory();
    const flash = useFlash();

    const [print,setPrint] = useState(false);
    const [pending, setPending] = useState({});
    const [showAdd, setShowAdd] = useState(false);
    const [showFind, setShowFind] = useState(false);
    const [proforma, setProforma] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [showConfirmSendManifest, setShowConfirmSendManifest] = useState(false);
    const [showConfirmReceiveManifest, setShowConfirmReceiveManifest] = useState(false);
    const [showConfirmDuplicateManifest, setShowConfirmDuplicateManifest] = useState(false);

    const products = useResource(ProductsCollection);
    const storages = useResource(StoragesCollection);
    const assets = useResource(AssetsCollection);
    const [manifest, loading] = useDecoratedManifest(manifestId);
    const user = useCurrentUser();

    const totalValue = ()=>{
        return Object.values(manifest.items)
            .reduce((tot, item) => tot + item.value,0);
    };

    const totalWeight = ()=>{
        return Object.values(manifest.items)
            .reduce((tot, item) => tot + item.weight,0);
    };

    const totalBoxes = ()=>{
        return Object.values(manifest.items)
            .map(item => item.boxNo)
            .filter((value, index, self) => self.indexOf(value) === index)
            .length
    };

    const handleChange = (item)=>{
        onUpdateItem && onUpdateItem({manifestId: manifest.id, ...item});
    };

    const handleRemoveItem = (id)=>{
        onRemoveItem && onRemoveItem({manifestId: manifest.id, id});
    };

    const handlePending = (itemId,pending)=>{
        setPending(v => {
            const obj = {};
            obj[itemId] = pending;
            return {...v,...obj}
        });
    };

    const handleTryRemoveItem = (id)=>{
        const item = manifest.items.find(i => i.id === id);
        if (item && item.assetId && parentStorageIsContainerAndWithinManifest(item.assetId) ){
            setItemToDelete(id)
        }else{
            handleRemoveItem(id)
        }
    };

    const handleSendManifest = ()=>{
        setShowConfirmSendManifest(false);
        setPrint(true);
        onSend && onSend({id: manifest.id});
    };

    const handleReceiveManifest = ()=>{
        setShowConfirmReceiveManifest(false);
        onReceive && onReceive({id: manifest.id})
    };

    const confirmRemoveItem  = ()=>{
        handleRemoveItem(itemToDelete);
        setItemToDelete(null);
    };

    const confirmSendManifest = ()=>{
        if(checkValidManifest()){
            setShowConfirmSendManifest(true);
        }else{
            flash.alert(<p>Please provide description and value on all items!</p>)
        }
    };

    const checkValidManifest = () =>{
        return manifest.items.every(itemIsValid)
    };

    const itemIsValid = (i) => {
        if(i.value < 1) return false;
        if(!i.description || i.description.length === 0) return false;
        return true
    };

    const confirmReceiveManifest = ()=>{
        setShowConfirmReceiveManifest(true);
    };

    const parentStorageIsContainerAndWithinManifest = (assetId)=>{
        const asset = assets.find(a => a.id === assetId);
        if (asset.storageId === null){
            return false;
        }
        const storage = storages.find(s => s.id === asset.storageId);
        const isContainer = storage.ownerType === AssetOwnerType;
        if (!isContainer){
            return false
        }

        for (let x = 0; x < manifest.items.length; x++) {
            const item = manifest.items[x];
            if (item.assetId === asset.storageId){
                return true
            }
        }
    };

    const handleAddItem = ()=>{
        onAddItem && onAddItem({
            manifestId: manifest.id,
            quantity: 1,
            boxNo: 1,
        });
    };

    const handleAddAsset = (product,asset)=>{
        const {id,rental,serialNumber,tagNumber,owner} = asset;
        const {weight, dimensions, name, price} = product;
        onAddItem && onAddItem({
            assetId: id,
            manifestId: manifest.id,
            quantity: 1,
            boxNo: 1,
            rental,
            serialNumber: (tagNumber ? serialNumber ? "SN:"+serialNumber+" Tag:"+tagNumber:tagNumber : serialNumber),
            owner,
            weight,
            dimensions,
            description: name,
            value: price,
        });
        //setShowFind(false)   closes the pane after asset is added,   maybe keep it open 
    };

    const handleAddNewAsset = (product,asset)=>{
        const {id,rental,serialNumber,tagNumber,owner} = asset;
        const {weight, dimensions, name, price} = product;
        onAddItem && onAddItem({
            assetId: id,
            manifestId: manifest.id,
            quantity: 1,
            boxNo: 1,
            rental,
            serialNumber: (tagNumber ? serialNumber ? "SN:"+serialNumber+" Tag:"+tagNumber:tagNumber : serialNumber),
            owner,
            weight,
            dimensions,
            description: name,
            value: price,
        });
    };

    const handleDuplicateManifest = useLoading(async(id)=>{
        setShowConfirmDuplicateManifest(false);
        try {
            var duplicatedManifestId = await Api.DuplicateManifest({id})
            history.push("/manifests/" + duplicatedManifestId + "/edit")
            flash.notification("Manifest duplicated.")
        } catch (e) {
            flash.alert(`Failed to duplicate manifest.`)
        }
    });

    const hasPendingChanges = ()=>{
        return Object.values(pending).some(v=>v);
    };

    if (loading){
        return "Loading..."
    }

    return <>
        <div className={classes.gridContainer}>
            <div className={classes.header}>
                <h1 className={classes.title}>{proforma ? 'Proforma invoice' : 'Shipping manifest'}</h1>
                <div className={classes.buttons}>
                    <Button
                        disabled={manifest.state === Received && !user.hasRole(ManifestEditor)}
                        circle
                        title={"Edit " + manifest.name}
                        onClick={()=>history.push("/manifests/" + manifest.id + "/edit")}
                    >
                        <Icon>edit</Icon>
                    </Button>
                    <Button
                        circle
                        title={"Duplicate "+ manifest.name}
                        onClick={()=>setShowConfirmDuplicateManifest(true)}

                    >
                        <Icon>content_copy</Icon>
                    </Button>
                    <Button onClick={()=>setProforma(v => !v)} className={classes.add} circle title={"Change to "+(!proforma ? Proforma : Shipping)}>
                        <Icon>360</Icon>
                    </Button>
                    <Button
                        disabled={hasPendingChanges()}
                        onClick={()=>setPrint(true)}
                        className={classes.add}
                        circle
                        title={"Print " + manifest.name}
                    >
                        <Icon>print</Icon>
                    </Button>


                </div>
                <div className={classes.recipient}>
                    <h2>Recipient</h2>
                    <div className={classes.preformatted}>{
`${manifest.recipient.name}
${manifest.recipientAddress}`
                    }</div>
                </div>
                <div className={classes.additionalNotes}>
                    <h2>Additional notes</h2>
                    <div className={classes.preformatted}>{manifest.additionalNotes}</div>
                </div>
                <div className={classes.sender}>
                    <h2>Sender</h2>
                    <div className={classes.preformatted}>{
`${manifest.sender.name}
${manifest.senderAddress}`
                    }</div>
                </div>
                <div className={classes.details}>
                    <span className={classes.detailsHeader}>Manifest number:</span>
                    <span>DO-MF-{manifest.manifestNumber}</span>
                    <span className={classes.detailsHeader}>Date:</span>
                    <span>{FairDate(manifest.date.toDate())}</span>
                    <span className={classes.detailsHeader}>Project number:</span>
                    <span>{manifest.project}</span>
                    <span className={classes.detailsHeader}>Our reference:</span>
                    <span>{manifest.reference}</span>
                </div>
            </div>
            <div className={classes.body}>
                {manifest.items.length > 0 ?
                <>
                    <div className={classes.itemHeader} style={{gridColumnStart: 2}}>Item</div>
                    <div className={classes.itemHeader}>Quantity</div>
                    <div className={classes.itemHeader}>BoxNo</div>
                    <div className={classes.itemHeader}>Description</div>
                    <div className={classes.itemHeader}>Serial/Tag no.</div>
                    <div className={classes.itemHeader}>Owner</div>
                    <div className={classes.itemHeader}>Rental</div>
                    <div className={classes.itemHeader}>Gross weight</div>
                    <div className={classes.itemHeader}>Dimensions</div>
                    <div className={classes.itemHeader}>Value {manifest.currency}</div>
                    {manifest.items.map((i,n)=>
                        <ManifestItem key={i.id}
                                      item={i}
                                      itemNo={n+1}
                                      onRemove={handleTryRemoveItem}
                                      onChange={handleChange}
                                      onPending={handlePending}
                                      disabled={manifest.state === Received && !user.hasRole(ManifestEditor)}
                        />
                    )}
                    <div className={classes.totalHeader} style={{gridColumn: "2 / 4"}}>Total boxes:</div>
                    <div className={classes.totalValue}>{totalBoxes()}</div>
                    <div className={classes.totalHeader} style={{gridColumn: "5 / 9"}}>Total weight:</div>
                    <div className={classes.totalValue}>{totalWeight()} kg</div>
                    <div className={classes.totalHeader}>Total value:</div>
                    <div className={classes.totalValue}>{totalValue()} {manifest.currency}</div>
                </> :
                <div className={classes.infoBox} >
                    <div>Manifest is currently empty. Add items to this manifest by using the buttons below.</div>
                </div>
                }
            </div>
            {manifest.state !== Received &&
            <div className={classes.addLine}>

            </div>}
            <div className={classes.footer}>
                <div>
                    <Button
                        style={{display: "block", height: "100%"}}
                        onClick={handleAddItem}
                        disabled={manifest.state === Received && !user.hasRole(ManifestEditor)}
                    >
                        Add line
                    </Button>
                </div>
                <div>
                    <Button
                        style={{display: "block", height: "100%"}}
                        onClick={() => {setShowFind(true)}}
                        disabled={manifest.state === Received && !user.hasRole(ManifestEditor)}
                    > Add existing asset
                    </Button>
                </div>
                <div>
                    <Button
                        style={{display: "block", height: "100%"}}
                        onClick={()=>history.push("/manifests/"+manifest.id+"/scan")}
                        disabled={manifest.state === Received && !user.hasRole(ManifestEditor)}
                    >
                        Add with QR
                    </Button>
                </div>
                <div>
                    <Button
                        style={{display: "block", height: "100%"}}
                        onClick={() => {setShowAdd(true)}}
                        disabled={manifest.state === Received && !user.hasRole(ManifestEditor)}
                    > Create new asset
                    </Button>
                </div>
                <div className={classes.actions}>
                    {manifest.items.length > 0 && manifest.state === Draft &&
                        <Button
                            disabled={manifest.state !== Draft || hasPendingChanges()}
                            onClick={confirmSendManifest}
                        >
                            Send manifest
                        </Button>
                    }
                    {manifest.state === Sent &&
                        <Button
                            disabled={manifest.state !== Sent || hasPendingChanges()}
                            onClick={confirmReceiveManifest}
                        >
                            Receive manifest
                        </Button>
                    }
                    {manifest.state === Received &&
                        <p>
                            Manifest Recieved {FairDate(manifest.stateUpdated.toDate())}
                        </p>
                    }
                </div>
            </div>
        </div>
        <Modal open={showAdd} onClose={()=>setShowAdd(false)}>
            <ManifestCreateNewAssetForm
                assets={assets}
                products={products}
                user={user}
                onAddToManifest={handleAddNewAsset}
                onClose={()=>setShowAdd(false)}
            />
        </Modal>
        <Modal open={showFind} onClose={()=>setShowFind(false)}>
            <ManifestFindAssetForm
                assets={assets}
                products={products}
                user={user}
                onAddToManifest={handleAddAsset}
                //onClose={()=>setShowFind(false)}
            />
        </Modal>
        <Confirmation open={showConfirmSendManifest}
                      onConfirm={handleSendManifest}
                      onCancel={()=>setShowConfirmSendManifest(false)}
        >
            <p>Are you certain you are ready to send this manifest?</p>
        </Confirmation>
        <Confirmation open={showConfirmReceiveManifest}
                      onConfirm={handleReceiveManifest}
                      onCancel={()=>setShowConfirmReceiveManifest(false)}
        />
        <Confirmation open={showConfirmDuplicateManifest}
                      onConfirm={()=>handleDuplicateManifest(manifestId)}
                      onCancel={()=>setShowConfirmDuplicateManifest(false)}
        >
            <p>Are you sure you wish to duplicate this manifest?</p>
        </Confirmation>
        <Confirmation open={!!itemToDelete}
                      onConfirm={confirmRemoveItem}
                      onCancel={()=>setItemToDelete(null)}>
            <>
                <p>
                    This item is a part of a container, by removing it, when this manifest is shipped the asset will be moved to the parent storage of the root container asset.
                </p>
                <p>Do you wish to proceed?</p>
            </>
        </Confirmation>
        {print && <Window features={'menubar=no,location=no,resizable=no,scrollbar=no,status=no,width=1123,height=794'} onClose={()=>setPrint(false)}>
            <ManifestPrintPage {...{manifest,logo,proforma}}/>
        </Window>}
    </>
};

const useManifestItemStyles = createUseStyles({
    removeLine: {
        justifySelf: "center",
        alignSelf: "center",
        cursor: "pointer",
    },
    input: {
        margin: 0,
        padding: 5,
        height: 31,
        boxSizing: "border-box",
        width: "100%",
        border: 0,
        resize: "none",
        borderStyle: "none",
    },
    pending: {
        animation: "pending 0.6s ease-in-out infinite",
        animationDirection: "alternate",
        justifySelf: "center",
        alignSelf: "center",
    },
    '@keyframes pending': {
        "0%": {
            transform: "scale(0.7)",
        },
        "100%": {
            transform: "scale(1)",
        }
    },
    "@media print":{
        input: {
            overflow: "visible",
        },
        removeLine: {
            display: "none"
        },
    },
});

const ManifestItem = ({item, itemNo, disabled, onChange, onRemove, onPending, pendingTimerMillis = 3000})=>{
    const classes = useManifestItemStyles();
    const [firstUpdate,setFirstUpdate] = useState(true);
    const [quantity,setQuantity] = useState(item && item.quantity);
    const [boxNo,setBoxNo] = useState(item && item.boxNo);
    const [description,setDescription] = useState(item && item.description);
    const [serialNumber,setSerialNumber] = useState(item && item.serialNumber);
    const [owner,setOwner] = useState(item && item.owner);
    const [rental,setRental] = useState(item && item.rental);
    const [weight,setWeight] = useState(item && item.weight);
    const [dimensions,setDimensions] = useState(item && item.dimensions);
    const [value,setValue] = useState(item && item.value);
    const [pendingChanges,setPendingChanges] = useState(null);

    useLayoutEffect(() => {
        if (firstUpdate) {
            setFirstUpdate(false);
            return;
        }

        if (pendingChanges && pendingChanges.key) {
            clearTimeout(pendingChanges.key);
        }

        let future = () => {
            onChange && onChange({
                id: item.id,
                assetId: item.assetId,
                quantity,
                boxNo,
                description,
                serialNumber,
                owner,
                rental,
                weight,
                dimensions,
                value,
            });
        };

        onPending && onPending(item.id,true);
        setPendingChanges({
            key: setTimeout(() => {
                future();
                onPending && onPending(item.id,false);
                setPendingChanges(null);
            }, pendingTimerMillis), fn: future
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[quantity,boxNo,description,serialNumber,owner,rental,weight,dimensions,value]);

    const handleRemoveItem = (id)=>{
        onRemove && onRemove(id)
    };

    return <>
        {!disabled &&
        <>
            {pendingChanges
                ?
                <span className={classes.pending}>
                    <i className="material-icons">backup</i>
                </span>
                :
                <span className={classes.removeLine} onClick={()=> handleRemoveItem(item.id)}>
                    <i className="material-icons">delete_outline</i>
                </span>
            }
        </>}
        <input
            style={{gridColumnStart: 2}}
            className={classes.input}
            disabled
            value={itemNo}
        />
        <input
            type="number"
            disabled={item.assetId || disabled}
            value={quantity}
            className={classes.input}
            onChange={(e)=>setQuantity(Number(e.target.value))}
        />
        <input
            type="number"
            disabled = {disabled}
            value={boxNo}
            className={classes.input}
            onChange={(e)=>setBoxNo(Number(e.target.value))}
        />
        <input
            type="text"
            disabled = {disabled}
            value={description}
            className={classes.input}
            onChange={(e)=>setDescription(e.target.value)}
        />
        <input
            type="text"
            disabled={item.assetId || disabled}
            value={serialNumber}
            className={classes.input}
            onChange={(e)=>setSerialNumber(e.target.value)}
        />
        <input
            type="text"
            disabled = {disabled}
            value={owner}
            className={classes.input}
            onChange={(e)=>setOwner(e.target.value)}
        />
        <input
            type="checkbox"
            disabled={item.assetId || disabled}
            checked={rental}
            className={classes.input}
            onChange={(e)=>setRental(e.target.checked)}
        />
        <input
            type="number"
            disabled = {disabled}
            value={weight}
            className={classes.input}
            onChange={(e)=>setWeight(Number(e.target.value))}
        />
        <input
            type="text"
            disabled = {disabled}
            value={dimensions}
            className={classes.input}
            onChange={(e)=>setDimensions(e.target.value)}
        />
        <input
            type="number"
            disabled = {disabled}
            value={value}
            className={classes.input}
            onChange={(e)=>setValue(Number(e.target.value))}
        />

        <Prompt
            when={!!pendingChanges}
            message={() =>
                `You have unsaved changes, are you sure you wish to leave?`
            }
        />
    </>
};