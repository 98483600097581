import React, {useState} from 'react';
import {Link, Route, Switch, withRouter} from "react-router-dom";
import {ProductsResource} from "../../services/Firestore/Resources";
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import Paper from "../../components/Paper/Paper";
import Input from "../../components/Input/Input";
import Modal from "../../components/Modal/Modal";
import withStyles from "react-jss";
import ProductList from "../../views/Product/ProductList";
import ProductAddForm from "../../views/Product/ProductAddForm";
import ProductPage from "./ProductPage";

const styles = {
    listContainer: {
        display: "grid",
        gridTemplateColumns: "auto auto",
        gridTemplateRows: "auto auto",
        gridTemplateAreas: '"add search" "list list"',
    },
    add: {
        gridArea: 'add',
        justifySelf: 'self-start',
        margin: 10,
    },
    search: {
        gridArea: 'search',
        justifySelf: 'self-end',
        marginTop: 10,
        marginLeft: 10,
        display: "grid",
        gridTemplateColumns: "auto auto",
    },
    list: {
        gridArea: 'list',
    },
    columnFilter: {
        cursor: 'pointer',
        margin: 10,
    }
};

const ProductListPage = ({history, classes}) => {
    const [filter, setFilter] = useState("");
    const [columnFilter,setColumnFilter] = useState(false);

    const handleFilterChange = (e) => {
        setFilter(e.target.value)
    };
    const navigateToMain = () => {
        history.push("/products");
    };
    const navigateToProduct = (productId) => {
        history.push("/products/" + productId);
    };
    return (
        <ProductsResource>{products => products &&
            <Switch>
                <Route exact path="/products(|/add)">
                    <Route path="/products/add">
                        <Modal open={true} onClose={navigateToMain}>
                            <ProductAddForm onSubmit={navigateToProduct} />
                        </Modal>
                    </Route>
                    <Paper className={classes.listContainer}>
                        <Link to="/products/add" className={classes.add}>
                            <Button circle title="Create new model">
                                <Icon>add</Icon>
                            </Button>
                        </Link>
                        <div className={classes.search}>
                        <Input className={classes.searchInput} placeholder={"Search all models"} onChange={handleFilterChange} />
                        <div>{
                            !columnFilter ?
                                <Icon className={classes.columnFilter} title="Show custom filtering" onClick={() => setColumnFilter(true)}>search</Icon> :
                                <Icon className={classes.columnFilter} title="Hide custom filtering" onClick={() => setColumnFilter(false)}>search_off</Icon>
                        }
                        </div>
                    </div>
                        <ProductList filter={filter} columnFilter={columnFilter} className={classes.list} {...{products}} />
                    </Paper>
                </Route>
                <Route path="/products/:id" render={({match}) =>
                    <ProductPage product={products.get(match.params.id)} />
                } />
            </Switch>
        }</ProductsResource>
    );
};

export default withRouter(withStyles(styles)(ProductListPage));
