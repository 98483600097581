import React from 'react';
import AuthContext from "../../services/AuthContext/AuthContext";
import {StoragesResource,AssetsResource,ProductsResource,ManifestsResource} from '../../services/Firestore/Resources';
import {AddressableConsumer} from "../../services/Context/Addressable";
import Home from "../../views/Home/Home";

const HomePage = () => {
    return (
        <div>
            <AuthContext.Consumer>{user=>
                <AddressableConsumer>{addressables =>
                    <StoragesResource>{storages =>
                            <AssetsResource>{assets =>
                                <ProductsResource>{products =>
                                    <ManifestsResource>{manifests =>
                                        <Home {...{storages, user, assets, manifests, products, addressables}} />
                                    }</ManifestsResource>
                                }</ProductsResource>
                            }</AssetsResource>
                    }</StoragesResource>
                }</AddressableConsumer>
            }</AuthContext.Consumer>
        </div>
    );
};

export default HomePage;
